import { useGenericMutation } from '../useGenericMutation'
import { EventApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useEditEvent = (onSuccess?: () => void) => {
  const {
    mutate: editEvent,
    isSuccess: isEditEventSuccess,
    isLoading: isEditEventLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.EVENTS,
    mutationFn: EventApi.updateEvent,
    errorMessage: 'No fue posible modificar el evento',
    successMessage: 'Evento modificado exitosamente',
    onSuccess,
  })

  return {
    editEvent,
    isEditEventSuccess,
    isEditEventLoading,
  }
}

export { useEditEvent }
