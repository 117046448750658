import { useGenericMutation } from '../useGenericMutation'
import { CategoryApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useCreateCategory = (onSuccess?: () => void) => {
  const {
    mutate: createCategory,
    isSuccess: isCreateCategorySuccess,
    isLoading: isCreateCategoryLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.CATEGORY,
    mutationFn: CategoryApi.setCategory,
    errorMessage: 'No fue posible crear la categoria',
    successMessage: 'Categoria creada exitosamente',
    onSuccess,
  })

  return {
    createCategory,
    isCreateCategorySuccess,
    isCreateCategoryLoading,
  }
}

export { useCreateCategory }
