import { Col, DatePicker, Pagination, Row, Table } from 'antd'
import moment from 'moment'
// import { RangeValue } from 'rc-picker/lib/interface'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { CloseCircleFilled } from '@ant-design/icons'

// import { NeighborEventsApi } from '../../api'
import { EraserIcon, SearchIcon, ImportIcon, EditIcon, NewspaperIcon } from '../../assets/icons'
import {
  ActionsContainer,
  // Checkbox,
  FilterButton,
  FilterInput,
  FilterSelect,
  InputContainer,
  // InputNum,
  Modal,
  PrimaryButton,
  RequiredText,
  SecundaryButton,
  StyledInput,
  StyledLabel,
  StyledTitle,
} from '../../components'
import ImportModal from '../../components/Modal/ImportModal'
import SendQrModal from '../../components/Modal/SendQrModal'
import { QUERY_KEYS, PERMISSION_KEY } from '../../constants'
import { useGetEventsCombo } from '../../hooks'
import {
  OrderBy,
  NeighborEventsFiltersProps,
  DefaultNeighborEventsFiltersProps,
  NeighborEvent,
  NeighborEventFormProps,
} from '../../types'
import { StringUtils } from '../../utils'
import { useUserSession } from '../../hooks/useUserSession'
import { useGetNeighborEvents } from '../../hooks/useGetNeighborEvents'
import { useGetMedicalClearanceStatusCombo } from '../../hooks/useGetMedicalClearanceStatusCombo'
import { useEditNeighborEvent } from '../../hooks/useEditNeighborEvent'
import { useSendQrNeighborEvents } from '../../hooks/useSendQrNeighborEvents'

const RegisteredNeighbors = () => {
  const defaultFilters: DefaultNeighborEventsFiltersProps = {
    event: undefined,
    cuil: undefined,
    name: undefined,
    lastName: undefined,
    medicalClearanceStatus: undefined,
  }

  const formDefaults: NeighborEventFormProps = {
    cuil: undefined,
    name: undefined,
    lastName: undefined,
    phoneNumber: undefined,
    email: undefined,
    hasQr: undefined,
    qrSent: undefined,
    medicalClearanceStatusId: undefined,
    medicalClearanceStatusDueDate: undefined,
    requiresMedicalClearance: undefined,
  }

  const [page, setPage] = useState(1)

  // Filtering
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState<
    NeighborEventsFiltersProps | DefaultNeighborEventsFiltersProps
  >(defaultFilters)
  const [maxResult, setMaxResult] = useState<number | null>(null)
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>(undefined)
  const [errors, setErrors] = useState(true)

  // Component States
  const [cuil, setCuil] = useState<string | undefined>(undefined)
  const [name, setName] = useState<string | undefined>(undefined)
  const [lastName, setLastName] = useState<string | undefined>(undefined)
  const [eventEvent, setEventEvent] = useState<number | undefined>(undefined)
  const [medicalClearanceStatus, setMedicalClearanceStatus] = useState<number | undefined>(
    undefined,
  )

  // Create | Edit States
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editing, setEditing] = useState(false)
  const [showEditNeighborEventModal, setShowEditNeighborEventModal] = useState(false)
  const [selectedNeighborEventId, setSelectedNeighborEventId] = useState<number | undefined>(
    undefined,
  )
  const [neighborEventFormData, setNeigborEventFormData] =
    useState<NeighborEventFormProps>(formDefaults)

  const { events } = useGetEventsCombo()
  const { medicalClearanceStatusesCombo } = useGetMedicalClearanceStatusCombo()

  const queryClient = useQueryClient()

  const { neighborEvents, neighborEventsIsLoading } = useGetNeighborEvents(
    page,
    filters,
    maxResult,
    orderBy,
  )

  const { editNeighborEvent, isEditNeighborEventLoading } = useEditNeighborEvent(() => {
    setShowEditNeighborEventModal(false)
    resetValues()
    handleOnClose()
  })

  const [importModalIsOpen, setImportModaIsOpen] = useState(false)
  const [sendQrModalIsOpen, setSendQrModalIsOpen] = useState(false)

  const [paginator, setPaginator] = useState<number | undefined>(1)

  const { userSession } = useUserSession()

  const { sendQrNeighborEvents } = useSendQrNeighborEvents(() => {
    setSendQrModalIsOpen(false)
  })

  const eventsCombo = events.map((e: { id: number; value: string }) => ({
    label: e.value,
    value: e.id,
  }))

  const columns = [
    {
      title: 'Evento',
      dataIndex: 'event',
      key: 'event',
    },
    {
      title: 'CUIL',
      key: 'cuil',
      render: ({ cuil }: NeighborEvent) => <>{`${cuil ? StringUtils.formatCUIL(cuil) : '-'}`}</>,
    },
    {
      title: 'Vecino',
      dataIndex: 'neighbor',
      key: 'neighbor',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: () => (
        <>
          Tiene <br /> QR
        </>
      ),
      key: 'hasQr',
      render: ({ hasQr }: NeighborEvent) => {
        return <>{`${hasQr ? 'Si' : 'No'}`}</>
      },
    },
    {
      title: () => (
        <>
          Requiere <br /> apto médico
        </>
      ),
      key: 'requiresMedicalClearance',
      render: ({ requiresMedicalClearance }: NeighborEvent) => {
        return <>{`${requiresMedicalClearance ? 'Si' : 'No'}`}</>
      },
    },
    {
      title: () => (
        <>
          Estado <br /> apto médico
        </>
      ),
      key: 'medicalClearanceStatus',
      render: ({ requiresMedicalClearance, medicalClearanceStatus }: NeighborEvent) => {
        if (!requiresMedicalClearance || medicalClearanceStatus == null) {
          return '-'
        }

        return medicalClearanceStatus.description
      },
    },
    {
      title: () => (
        <>
          Vencimiento <br /> apto médico
        </>
      ),
      key: 'medicalClearanceDueDate',
      render: ({ requiresMedicalClearance, medicalClearanceStatusDueDate }: NeighborEvent) => {
        if (!requiresMedicalClearance || !medicalClearanceStatusDueDate) {
          return '-'
        }

        return moment(medicalClearanceStatusDueDate).format('DD-MM-YYYY')
      },
    },
    {
      title: 'Acciones',
      key: 'action',
      render: ({ id }: NeighborEvent) => (
        <ActionsContainer>
          <EditIcon
            onClick={() => {
              setSelectedNeighborEventId(id)
              setShowEditNeighborEventModal(true)
            }}
          />
        </ActionsContainer>
      ),
    },
  ]

  const handleQuery = () => {
    const valores = {
      cuil: cuil,
      event: eventEvent,
      name: name,
      lastName: lastName,
      medicalClearanceStatus: medicalClearanceStatus,
    }
    queryClient.resetQueries(QUERY_KEYS.NEIGHBOR_EVENTS)
    setPage(1)
    if (paginator === undefined) {
      setFilters(defaultFilters)
    } else {
      setFilters(valores)
    }
  }

  const handleCleanFilters = () => {
    setPaginator(undefined)
    setCuil(undefined)
    setName(undefined)
    setLastName(undefined)
    setEventEvent(undefined)
    setMedicalClearanceStatus(undefined)
    setOrderBy(undefined)
    setPaginator(1)

    queryClient.cancelQueries(QUERY_KEYS.NEIGHBOR_EVENTS)
    setPage(1)
    setFilters(defaultFilters)
    queryClient.resetQueries(QUERY_KEYS.NEIGHBOR_EVENTS)
    //valores = defaultFilters
  }

  const resetValues = () => {
    setNeigborEventFormData(formDefaults)
    setSelectedNeighborEventId(undefined)
    setErrors(true)
  }

  const body = document.querySelector('body') || null
  const handleOnClose = () => {
    setShowEditNeighborEventModal(false)
    resetValues()

    if (body) body.style.overflowY = 'auto'
  }

  // const handleExport = () => {
  //   VisitApi.exportVisits(filters, null, null).then(res => {
  //     const link = document.createElement('a')
  //     const url = window.URL.createObjectURL(res)
  //     link.href = url
  //     link.setAttribute(
  //       'download',
  //       `registro_visitas_${moment().format(`${dateFormat}[_]HH[-]mm[-]ss`)}`,
  //     )
  //     document.body.appendChild(link)

  //     link.click()
  //     link.parentNode?.removeChild(link)
  //   })
  // }

  // const onRangePickerChange = (value: RangeValue<moment.Moment>) => {
  //   setAttendance_dates(value)
  // }

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  const keyEnter = () => {
    const valores = {
      cuil: cuil,
      event: eventEvent,
      name: name,
      lastName: lastName,
      medicalClearanceStatus: medicalClearanceStatus,
      orderBy: orderBy,
    }
    setPaginator(0)
    setPage(1)
    setFilters(valores)
  }

  const handlePageSize = (page: number, pageSize: number) => {
    setMaxResult(pageSize)
    setPage(1)
    queryClient.resetQueries(QUERY_KEYS.NEIGHBOR_EVENTS)
  }

  const handleFormUpdate = (key: string, value: any) => {
    setNeigborEventFormData({ ...neighborEventFormData, [key]: value })
  }
  const modalInputContainer = (
    <InputContainer>
      <RequiredText>(*)Datos Obligatorios</RequiredText>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <StyledLabel>CUIL</StyledLabel>
          <StyledInput value={neighborEventFormData.cuil} disabled />
        </Col>
        <Col span={8}>
          <StyledLabel>Nombre</StyledLabel>
          <StyledInput value={neighborEventFormData.name} disabled />
        </Col>
        <Col span={8}>
          <StyledLabel>Apellido</StyledLabel>
          <StyledInput value={neighborEventFormData.lastName} disabled />
        </Col>
        <Col span={8}>
          <StyledLabel>Teléfono</StyledLabel>
          <StyledInput value={neighborEventFormData.phoneNumber} disabled />
        </Col>
        <Col span={24}>
          <StyledLabel>Email</StyledLabel>
          <StyledInput value={neighborEventFormData.email} disabled />
        </Col>
        <Col span={!neighborEventFormData.hasQr ? 12 : 24}>
          <StyledLabel>Tiene QR</StyledLabel>
          <StyledInput value={neighborEventFormData.hasQr ? 'Si' : 'No'} disabled />
        </Col>

        {!neighborEventFormData.hasQr && (
          <Col span={12}>
            <StyledLabel>QR enviado</StyledLabel>
            <StyledInput
              value={
                neighborEventFormData.qrSent
                  ? moment(neighborEventFormData.qrSent).format('DD-MM-YYYY')
                  : '-'
              }
              disabled
            />
          </Col>
        )}

        <StyledTitle>Apto médico</StyledTitle>
        {neighborEventFormData.requiresMedicalClearance ? (
          <Col span={24}>
            <FilterSelect
              name="medicalClearanceStatus"
              label="ESTADO DEL APTO MÉDICO"
              allowClear={false}
              options={medicalClearanceStatusesCombo}
              value={neighborEventFormData.medicalClearanceStatusId}
              onChange={selectedMedicalClearanceStatus =>
                handleFormUpdate('medicalClearanceStatusId', selectedMedicalClearanceStatus)
              }
            />
          </Col>
        ) : (
          <Col span={24}>El evento no requiere apto médico.</Col>
        )}

        {neighborEventFormData.requiresMedicalClearance &&
        neighborEventFormData.medicalClearanceStatusId === 2 ? (
          <Col span={24}>
            <StyledLabel>vencimiento del apto médico</StyledLabel>
            <p>&nbsp;</p>
            <Col span={24}>
              <DatePicker
                name="expiresAt"
                format="DD-MM-YYYY"
                allowClear
                disabledDate={currentDate => {
                  const today = moment().startOf('day')
                  return currentDate < today
                }}
                onChange={data => {
                  handleFormUpdate('medicalClearanceStatusDueDate', data?.format())
                }}
              />
            </Col>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </InputContainer>
  )

  useEffect(() => {
    if (selectedNeighborEventId !== 0 && neighborEvents) {
      const neighborEvent: NeighborEvent = neighborEvents.data.find(
        (neighborEvent: NeighborEvent) => neighborEvent.id === selectedNeighborEventId,
      )

      if (neighborEvent) {
        setErrors(false)

        setNeigborEventFormData({
          cuil: neighborEvent.cuil,
          name: neighborEvent.name,
          lastName: neighborEvent.lastName,
          phoneNumber: neighborEvent.phoneNumber,
          email: neighborEvent.email,
          hasQr: neighborEvent.hasQr,
          qrSent: neighborEvent.qrSent,
          medicalClearanceStatusId: neighborEvent.medicalClearanceStatus?.id,
          medicalClearanceStatusDueDate: neighborEvent.medicalClearanceStatusDueDate,
          requiresMedicalClearance: neighborEvent.requiresMedicalClearance,
        })
      }
    }
  }, [selectedNeighborEventId, neighborEvents])

  useEffect(() => {
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.resetQueries(QUERY_KEYS.NEIGHBOR_EVENTS)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, paginator])

  useEffect(() => {
    if (maxResult) {
      queryClient.resetQueries(QUERY_KEYS.NEIGHBOR_EVENTS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxResult])

  useEffect(() => {
    if (!showEditNeighborEventModal) {
      queryClient.refetchQueries(QUERY_KEYS.NEIGHBOR_EVENTS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditNeighborEventModal])

  useEffect(() => {
    handleQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy])

  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <h1>Lista de vecinos registrados a eventos</h1>
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Row>
              {userSession?.permissions?.includes(
                PERMISSION_KEY.SEARCH_EVENT_ATTENDANCE_IMPORT_NEIGHBOR_EVENT,
              ) && (
                <Row justify="end" align="middle" gutter={[12, 0]}>
                  <Col>
                    <SecundaryButton
                      label="Importar XLS"
                      onClick={() => setImportModaIsOpen(true)}
                      icon={<ImportIcon />}
                    />
                  </Col>
                  <Col>
                    <PrimaryButton
                      label="Enviar QR"
                      icon={<NewspaperIcon />}
                      onClick={() => setSendQrModalIsOpen(true)}
                    />
                  </Col>
                </Row>
              )}
            </Row>
            <Col span={1}>
              <FilterButton onClick={() => setShowFilters(prev => !prev)} />
            </Col>
          </Row>
        </Col>
        {showFilters && (
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Row gutter={[24, 0]}>
                  <Col span={12}>
                    <FilterSelect
                      options={eventsCombo}
                      value={eventEvent}
                      name="eventEvent"
                      label="Evento"
                      onChange={(value: unknown) => setEventEvent(value as number)}
                      keyEnter={keyEnter}
                    />
                  </Col>
                  <Col span={12}>
                    <FilterSelect
                      options={medicalClearanceStatusesCombo}
                      value={medicalClearanceStatus}
                      name="medicalClearanceStatus"
                      label="Apto médico"
                      onChange={(value: unknown) => setMedicalClearanceStatus(value as number)}
                      keyEnter={keyEnter}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[24, 0]}>
                  <Col span={8}>
                    <FilterInput
                      value={name}
                      name="nombre"
                      placeholder="Ingrese Nombre"
                      label="Nombre"
                      onChange={e => {
                        setName(e.target.value)
                      }}
                      keyEnter={keyEnter}
                      allowClear={{
                        clearIcon: <CloseCircleFilled onClick={() => setName(undefined)} />,
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <FilterInput
                      value={lastName}
                      name="apellido"
                      placeholder="Ingrese Apellido"
                      label="Apellido"
                      onChange={e => {
                        setLastName(e.target.value)
                      }}
                      keyEnter={keyEnter}
                      allowClear={{
                        clearIcon: <CloseCircleFilled onClick={() => setLastName(undefined)} />,
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <FilterInput
                      value={cuil}
                      name="cuil"
                      placeholder="Solo números"
                      label="Cuil"
                      onChange={e => {
                        setCuil(e.target.value)
                      }}
                      keyEnter={keyEnter}
                      allowClear={{
                        clearIcon: <CloseCircleFilled onClick={() => setCuil(undefined)} />,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end" gutter={[12, 0]}>
                  <Col>
                    <SecundaryButton
                      label="Limpiar"
                      icon={<EraserIcon />}
                      onClick={handleCleanFilters}
                    />
                  </Col>
                  <Col>
                    <PrimaryButton label="Buscar" icon={<SearchIcon />} onClick={handleQuery} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Table
            //sortDirections={['descend']}
            dataSource={neighborEvents?.data || []}
            columns={columns}
            pagination={false}
            rowKey="id"
            loading={neighborEventsIsLoading}
          />
          <Pagination
            onChange={e => {
              handlePage(e)
            }}
            current={page}
            total={neighborEvents?.paginate?.total}
            pageSize={maxResult || 10}
            onShowSizeChange={(page: number, pageSize: number) => handlePageSize(page, pageSize)}
          />
        </Col>
      </Row>
      <Modal
        title="Editar vecino registrado"
        open={showEditNeighborEventModal}
        onClose={() => {
          handleOnClose()
        }}
        onSave={() => {
          editNeighborEvent({
            id: selectedNeighborEventId,
            medicalClearanceStatusId: neighborEventFormData.medicalClearanceStatusId,
            medicalClearanceDueDate: neighborEventFormData.medicalClearanceStatusDueDate,
          })
        }}
        disabledSave={errors || isEditNeighborEventLoading}
        showActionButtons={neighborEventFormData.requiresMedicalClearance ? true : false}
      >
        {modalInputContainer}
      </Modal>
      <ImportModal
        title="Importar archivo de asignación a eventos"
        open={importModalIsOpen}
        onClose={() => setImportModaIsOpen(false)}
        entity="neighbor-event"
        accept=".xls, .xlsx"
      />
      <SendQrModal
        title="Confirme la acción"
        open={sendQrModalIsOpen}
        onClose={() => setSendQrModalIsOpen(false)}
        onConfirm={() => {
          console.log('confirm')
          sendQrNeighborEvents({})
        }}
        width={'100'}
        showActionButtons
        message={''}
      />
    </>
  )
}

export { RegisteredNeighbors }
