import { CfgKeysType } from '../types'

export const CFG_KEYS: CfgKeysType = {
  cuil: 'Cuil',
  firstname: 'Nombre',
  lastname: 'Apellido',
  email: 'eMail',
  phone: 'Teléfono',
  medicalclearance: 'Estado Apto médico',
}
