import { api } from './axios'

interface NewCategoryPayload {
  description: string
}

interface EditCategoryPayload {
  id: number
  description: string
}

interface DeleteCategoryPayload {
  id: number
}

const apiCategory = '/api/category'

const setCategory = async ({ description }: NewCategoryPayload) => {
  const response = await api.post(apiCategory, {
    description: description,
  })
  return response.data
}

const getCategory = async (page: number) => {
  const response = await api.get(`${apiCategory}?page=${page}`)
  return response.data
}

const updateCategory = async ({ id, description }: EditCategoryPayload) => {
  const response = await api.put(`${apiCategory}/${id}`, { description })
  return response.data
}

const deleteCategory = async ({ id }: DeleteCategoryPayload) => {
  const response = await api.delete(`${apiCategory}/${id}`)
  return response.data
}

const getCategoryCombo = async () => {
  const response = await api.get(`${apiCategory}/combo`)
  return response.data
}

export const CategoryApi = {
  setCategory,
  getCategory,
  updateCategory,
  deleteCategory,
  getCategoryCombo,
}
