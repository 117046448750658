import { api } from './axios'

interface NewBenefitsPayload {
  title: string
  commerceId: number
  description: string
  expiresAt: string
  isActive: boolean
  unlockableByAction: boolean
  actionId: number
  levelId: number
  isGroupType: boolean
  categoryIds: number[]
  maxStock: number
  maxUsesPerNeighbor: number
}

interface EditBenefitsPayload {
  id: number
  title: string
  description: string
  expiresAt: number
  isActive: boolean
  category: number
  maxStock: number
  maxUsesPerNeighbor: number
}

interface DeleteBenefitsPayload {
  id: number
}

const apiBenefit = '/api/benefit'

const setBenefits = async ({
  title,
  commerceId,
  description,
  expiresAt,
  isActive,
  unlockableByAction,
  actionId,
  levelId,
  isGroupType,
  categoryIds,
  maxStock,
  maxUsesPerNeighbor,
}: NewBenefitsPayload) => {
  const response = await api.post(apiBenefit, {
    title: title,
    commerceId: commerceId,
    description: description,
    expiresAt: expiresAt,
    isActive: isActive,
    unlockableByAction: unlockableByAction,
    actionId: actionId,
    levelId: levelId,
    isGroupType: isGroupType,
    categoryIds: categoryIds,
    maxStock: maxStock,
    maxUsesPerNeighbor: maxUsesPerNeighbor,
  })
  return response.data
}

const getBenefits = async (page: number) => {
  const response = await api.get(`${apiBenefit}?page=${page}`)
  return response.data
}

const updateBenefits = async ({
  id,
  title,
  description,
  expiresAt,
  isActive,
  category,
  maxStock,
  maxUsesPerNeighbor,
}: EditBenefitsPayload) => {
  const response = await api.put(`${apiBenefit}/${id}`, {
    title,
    description,
    expiresAt,
    isActive,
    category,
    maxStock,
    maxUsesPerNeighbor,
  })
  return response.data
}

const deleteBenefits = async ({ id }: DeleteBenefitsPayload) => {
  const response = await api.delete(`${apiBenefit}/${id}`)
  return response.data
}

const getUnusedAvailableBenefitCombo = async () => {
  const response = await api.get(`${apiBenefit}/unused-available`)
  return response.data.data
}

const getIDReportBenefits = async () => {
  const response = await api.get('/api/event-commerce/combo')
  return response.data.data
}

const getReportBenefits = async (eventId: number) => {
  try {
    const response = await api.get(`/api/consumed-benefits/report?eventId=${eventId}`, {
      responseType: 'blob',
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export const BenefitsApi = {
  setBenefits,
  getBenefits,
  updateBenefits,
  deleteBenefits,
  getUnusedAvailableBenefitCombo,
  getIDReportBenefits,
  getReportBenefits,
}
