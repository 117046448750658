import { useQueryClient } from 'react-query'

import { useGenericMutation } from '../useGenericMutation'
import { CfgApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useEditCfgAppData = () => {
  const queryClient = useQueryClient()
  const {
    mutate: editCfgAppData,
    isSuccess: isEditCfgAppDataSuccess,
    isLoading: editCfgAppDataIsLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.EDIT_CFG_APP_DATA,
    mutationFn: payload => CfgApi.updateCfgAppData(payload),
    errorMessage: 'No fue posible modificar la configuración',
    successMessage: 'Configuración modificada exitosamente',
    onSuccess: () => {
      queryClient.refetchQueries(QUERY_KEYS.CFG_APP_DATA)
    },
  })

  return {
    editCfgAppData,
    isEditCfgAppDataSuccess,
    editCfgAppDataIsLoading,
  }
}

export { useEditCfgAppData }
