import { useQuery } from 'react-query'

import { CommerceApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetCommerceCombo = () => {
  const { data: commerce } = useQuery([QUERY_KEYS.COMMERCE_COMBO], () =>
    CommerceApi.getCommerceCombo(),
  )

  return {
    commerceCombo: ((commerce?.data ?? []) as { id: number; value: string }[]).map(
      ({ id, value }) => ({
        label: value,
        value: id,
      }),
    ),
  }
}

export { useGetCommerceCombo }
