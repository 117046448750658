import { useGenericMutation } from '../useGenericMutation'
import { EventApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useCreateEvent = (onSuccess?: () => void) => {
  const {
    mutate: createEvent,
    isSuccess: isCreateEventSuccess,
    isLoading: isCreateEventLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.EVENTS,
    mutationFn: EventApi.createEvent,
    errorMessage: 'No fue posible guardar el evento',
    successMessage: 'Evento guardado exitosamente',
    onSuccess,
  })

  return {
    createEvent,
    isCreateEventSuccess,
    isCreateEventLoading,
  }
}

export { useCreateEvent }
