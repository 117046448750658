import { useGenericMutation } from '../useGenericMutation'
import { LevelApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useCreateLevel = (onSuccess?: () => void) => {
  const {
    mutate: createLevel,
    isSuccess: isCreateLevelSuccess,
    isLoading: isCreateLevelLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.LEVELS,
    mutationFn: LevelApi.setLevels,
    errorMessage: 'No fue posible guardar el nivel',
    successMessage: 'Nivel guardado exitosamente',
    onSuccess,
  })

  return {
    createLevel,
    isCreateLevelSuccess,
    isCreateLevelLoading,
  }
}

export { useCreateLevel }
