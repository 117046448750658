import { Row, Col } from 'antd'
import { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router'
import styled from 'styled-components'

import { OthersApi, setAuthHeader } from '../../api'
import { LOCAL_STORAGE_KEYS, PARAM_KEYS, ROUTE_KEYS } from '../../constants'
import { Activities } from '../Activities'
import { Events } from '../Events'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { Locations } from '../Locations'
import { SideMenu } from '../SideMenu'
import { Users } from '../Users'
import { Visits } from '../Visits'
import {
  ActivitiesIcon,
  LocationsIcon,
  UsersIcon,
  VisitsIcon,
  EventsIcon,
  SettingsIcon,
  PlusIcon,
  HouseIcon,
  GiftIcon,
  UserSolidIcon,
} from '../../assets/icons'
import { AppData } from '../Configurations'
import { loginRedirection } from '../../utils'
import { Page404 } from '../Page404'
import { useUserSession } from '../../hooks/useUserSession'
import { Levels } from '../Levels'
import { Benefits } from '../Benefits'
import { Commerce } from '../Commerce'
import { Action } from '../Action'
import { Category } from '../Category'
import { ReportBenefits } from '../ReportBenefits'
import { Profile } from '../../types'
import { RegisteredNeighbors } from '../RegisteredNeighbors'

const MainContainer = styled(Row)`
  max-width: 100vw;
  padding: 2rem 25px;
  flex: 1;
`

const Navigation = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true)
  const location = new URLSearchParams(window.location.search)
  const navigate = useNavigate()
  const { ACCESS_TOKEN, REFRESH_TOKEN, AUTH_SET } = LOCAL_STORAGE_KEYS
  window.localStorage.setItem(AUTH_SET, 'false')

  const { setUserSession } = useUserSession()
  const [userProfile, setUserProfile] = useState<Profile | undefined>(undefined)
  useEffect(() => {
    const access_token = location.get(PARAM_KEYS.TOKEN)
    const refresh_token = location.get(PARAM_KEYS.REFRESH)
    OthersApi.getProfile().then(result => setUserProfile(result))
    if (access_token) {
      window.localStorage.clear()
      window.localStorage.setItem(ACCESS_TOKEN, access_token)
      window.localStorage.setItem(REFRESH_TOKEN, refresh_token || '')
      setAuthHeader(access_token)
      navigate(ROUTE_KEYS.ROOT)
    } else if (window.localStorage.getItem(ACCESS_TOKEN)) {
      setAuthHeader(window.localStorage.getItem(ACCESS_TOKEN) as string)
    } else if (!location.get('type')) {
      loginRedirection(userProfile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [authSet, setAuthSet] = useState(false)
  const [isBackoffice, setIsBackoffice] = useState(false)
  const [isBackofficeFidelizacion, setIsBackofficeFidelizacion] = useState(false)
  const [isImportador, setIsImportador] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setAuthSet(window.localStorage.getItem(AUTH_SET) === 'true')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.localStorage])

  useEffect(() => {
    OthersApi.getProfile().then(result => {
      let hasBackofficeRole = false
      let hasBackofficeFidelizacionRole = false
      let hasImportadorRole = false
      let hasMobileRole = false

      result.rolesArray.forEach((role: string) => {
        if (role === 'backoffice') {
          hasBackofficeRole = true
        } else if (role === 'backoffice_fidelizacion') {
          hasBackofficeFidelizacionRole = true
        } else if (role === 'importador') {
          hasImportadorRole = true
        } else if (role === 'mobile') {
          hasMobileRole = true
        }
      })

      setUserSession(result)
      setIsBackofficeFidelizacion(hasBackofficeFidelizacionRole)
      setIsBackoffice(hasBackofficeRole)
      setIsImportador(hasImportadorRole)
      setIsMobile(hasMobileRole)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const menuItems = isBackoffice
    ? [
        {
          key: 'visits',
          icon: <VisitsIcon />,
          title: 'Registro de Visitas',
          path: '/',
        },
        {
          key: 'registered-neighbors',
          icon: <UsersIcon />,
          title: 'Vecinos Registrados',
          path: '/registered-neighbors',
        },
        {
          key: 'users',
          icon: <UserSolidIcon />,
          title: 'Usuarios',
          path: '/users',
        },
        {
          key: 'locations',
          icon: <LocationsIcon />,
          title: 'Locaciones',
          path: '/locations',
        },
        {
          key: 'activities',
          icon: <ActivitiesIcon />,
          title: 'Actividades',
          path: '/activities',
        },
        {
          key: 'events',
          icon: <EventsIcon />,
          title: 'Eventos',
          path: '/events',
        },
        {
          key: 'configuration',
          icon: <SettingsIcon />,
          title: 'Configuración',
          options: [
            {
              subMenuKey: 'appData',
              subMenuTitle: 'Datos en la APP',
              subMenuPath: '/configuration/app_data',
            },
          ],
        },
      ]
    : isBackofficeFidelizacion
    ? [
        {
          key: 'users',
          icon: <UsersIcon />,
          title: 'Usuarios',
          path: '/users',
        },
        {
          key: 'reportbenefits',
          icon: <VisitsIcon />,
          title: 'Reporte de Beneficios',
          path: '/reportbenefits',
        },
        {
          key: 'locations',
          icon: <LocationsIcon />,
          title: 'Locaciones',
          path: '/locations',
        },
        {
          key: 'activities',
          icon: <ActivitiesIcon />,
          title: 'Actividades',
          path: '/activities',
        },
        {
          key: 'events',
          icon: <EventsIcon />,
          title: 'Eventos',
          path: '/events',
        },
        {
          key: 'levels',
          icon: <PlusIcon />,
          title: 'Niveles',
          path: '/levels',
        },
        {
          key: 'benefits',
          icon: <GiftIcon />,
          title: 'Beneficios',
          path: '/benefits',
        },
        {
          key: 'commerce',
          icon: <HouseIcon />,
          title: 'Comercios',
          path: '/commerce',
        },
        {
          key: 'action',
          icon: <EventsIcon />,
          title: 'Accion',
          path: '/action',
        },
        {
          key: 'category',
          icon: <HouseIcon />,
          title: 'Categoria',
          path: '/category',
        },
      ]
    : isImportador
    ? [
        {
          key: 'visits',
          icon: <VisitsIcon />,
          title: 'Registro de Visitas',
          path: '/',
        },
        {
          key: 'registered-neighbors',
          icon: <UsersIcon />,
          title: 'Vecinos Registrados',
          path: '/registered-neighbors',
        },
        {
          key: 'reportbenefits',
          icon: <VisitsIcon />,
          title: 'Reporte de Beneficios',
          path: '/reportbenefits',
        },
      ]
    : [
        {
          key: 'visits',
          icon: <VisitsIcon />,
          title: 'Registro de Visitas',
          path: '/',
        },
      ]

  if (isBackoffice) {
    return (
      <div>
        <Routes>
          <Route path="/auth-error" element={<Page404 />} />
        </Routes>
        {authSet && (
          <>
            <Header setIsSideMenuOpen={() => setIsSideMenuOpen(prev => !prev)} />
            <MainContainer gutter={[32, 32]}>
              {isSideMenuOpen && (
                <Col className="aside" span={5}>
                  <SideMenu menuItems={menuItems} />
                </Col>
              )}
              <Col span={isSideMenuOpen ? 19 : 24}>
                <Routes>
                  {!isBackofficeFidelizacion && <Route path="/" element={<Visits />} />}
                  {!isBackofficeFidelizacion && (
                    <Route path="/registered-neighbors" element={<RegisteredNeighbors />} />
                  )}
                  {isBackoffice && <Route path="/locations" element={<Locations />} />}
                  {isBackoffice && <Route path="/activities" element={<Activities />} />}
                  {isBackoffice && <Route path="/users" element={<Users />} />}
                  {isBackoffice && <Route path="/events" element={<Events />} />}
                  {isBackoffice && <Route path="/configuration/app_data" element={<AppData />} />}
                </Routes>
              </Col>
            </MainContainer>
            <Footer />
          </>
        )}
      </div>
    )
  } else if (isBackofficeFidelizacion) {
    return (
      <div>
        <Routes>
          <Route path="/auth-error" element={<Page404 />} />
        </Routes>
        {authSet && (
          <>
            <Header setIsSideMenuOpen={() => setIsSideMenuOpen(prev => !prev)} />
            <MainContainer gutter={[32, 32]}>
              {isSideMenuOpen && (
                <Col className="aside" span={5}>
                  <SideMenu menuItems={menuItems} />
                </Col>
              )}
              <Col span={isSideMenuOpen ? 19 : 24}>
                <Routes>
                  {isBackofficeFidelizacion && <Route path="/locations" element={<Locations />} />}
                  {isBackofficeFidelizacion && (
                    <Route path="/activities" element={<Activities />} />
                  )}
                  {isBackofficeFidelizacion && <Route path="/users" element={<Users />} />}
                  {isBackofficeFidelizacion && (
                    <Route path="/reportbenefits" element={<ReportBenefits />} />
                  )}
                  {isBackofficeFidelizacion && <Route path="/events" element={<Events />} />}
                  {isBackofficeFidelizacion && <Route path="/levels" element={<Levels />} />}
                  {isBackofficeFidelizacion && <Route path="/benefits" element={<Benefits />} />}
                  {isBackofficeFidelizacion && <Route path="/commerce" element={<Commerce />} />}
                  {isBackofficeFidelizacion && <Route path="/action" element={<Action />} />}
                  {isBackofficeFidelizacion && <Route path="/category" element={<Category />} />}
                </Routes>
              </Col>
            </MainContainer>
            <Footer />
          </>
        )}
      </div>
    )
  } else if (isImportador) {
    return (
      <div>
        <Routes>
          <Route path="/auth-error" element={<Page404 />} />
        </Routes>
        {authSet && (
          <>
            <Header setIsSideMenuOpen={() => setIsSideMenuOpen(prev => !prev)} />
            <MainContainer gutter={[32, 32]}>
              {isSideMenuOpen && (
                <Col className="aside" span={5}>
                  <SideMenu menuItems={menuItems} />
                </Col>
              )}
              <Col span={isSideMenuOpen ? 19 : 24}>
                <Routes>{isImportador && <Route path="/" element={<Visits />} />}</Routes>
                <Routes>
                  {isImportador && (
                    <Route path="/registered-neighbors" element={<RegisteredNeighbors />} />
                  )}
                  {isImportador && <Route path="/reportbenefits" element={<ReportBenefits />} />}
                </Routes>
              </Col>
            </MainContainer>
            <Footer />
          </>
        )}
      </div>
    )
  } else if (isMobile) {
    return (
      <div>
        <Routes>
          <Route path="/auth-error" element={<Page404 />} />
        </Routes>
        {authSet && (
          <>
            <Header setIsSideMenuOpen={() => setIsSideMenuOpen(prev => !prev)} />
            <MainContainer gutter={[32, 32]}>
              {isSideMenuOpen && (
                <Col className="aside" span={5}>
                  <SideMenu menuItems={menuItems} />
                </Col>
              )}
              <Col span={isSideMenuOpen ? 19 : 24}>
                <Routes>{isImportador && <Route path="/" element={<Visits />} />}</Routes>
              </Col>
            </MainContainer>
            <Footer />
          </>
        )}
      </div>
    )
  } else {
    return (
      <div>
        <Routes>
          <Route path="/auth-error" element={<Page404 />} />
        </Routes>
      </div>
    )
  }
}

export default Navigation
