import { useQuery } from 'react-query'

import { EventApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { EventsFiltersProps } from '../../types'
import { errorDataMessage } from '../../utils'

const useGetEvents = (page: number, params: EventsFiltersProps, maxResult: number | null) => {
  const { data: events, isLoading: eventsIsLoading } = useQuery(
    [QUERY_KEYS.EVENTS],
    () => EventApi.getAllEvents(page, params, maxResult),
    {
      onError: errorDataMessage,
    },
  )

  return { events, eventsIsLoading }
}

export { useGetEvents }
