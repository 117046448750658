import { api } from './axios'
import { UsersFiltersProps } from '../types'
import { errorDataMessage, setEndpoint } from '../utils'

interface NewUserPayload {
  name: string
  last_name: string
  cuil: string
  email: string
  phone_number: string
  role_ids: number[]
  assigned_events: { id: number }[]
}

interface UpdateUserPayload {
  name: string
  last_name: string
  cuil: string
  email: string
  phone_number: string
  role_ids: number[]
  assigned_events: { id: number }[]
}

const getAllUsers = async (page: number, filters: UsersFiltersProps) => {
  try {
    const response = await api.get(setEndpoint(`/api/user/read?page=${page}`, filters))
    return response.data || []
  } catch {
    errorDataMessage()
    return []
  }
}

const getUser = async (userId: number) => {
  const response = await api.get(`/api/user/id/${userId}`)
  return response.data.data
}

const getUsersCombo = async () => {
  const response = await api.get('/api/user/combo')
  return response.data
}

const createUser = async (payload: NewUserPayload) => {
  const response = await api.post('/api/user/create', payload)
  return response.data
}

const updateUser = async (payload: UpdateUserPayload, userId: number) => {
  const response = await api.post(`/api/user/edit/${userId}`, payload)
  return response.data
}

const deleteUser = async (userId: number) => {
  const response = await api.delete(`/api/user/delete/${userId}`)
  return response.data
}

const getRolesCombo = async () => {
  const response = await api.get('/api/user-role/combo')
  return response.data.data
}

const getNeighborByCuil = async (cuil: string) => {
  const response = await api.get(`/api/user/find/${cuil}`)
  return response.data.data
}

export const UserApi = {
  getAllUsers,
  getUsersCombo,
  createUser,
  updateUser,
  deleteUser,
  getRolesCombo,
  getNeighborByCuil,
  getUser,
}
