import { useEffect, useState } from 'react'
import { Row, Col, Table, Pagination, Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useQueryClient } from 'react-query'

import { EditIcon, PlusIcon, TrashIcon } from '../../assets/icons'
import {
  ActionsContainer,
  FilterInput,
  FilterSelect,
  InputContainer,
  Modal,
  PrimaryButton,
  RequiredText,
} from '../../components'
import {
  useDeleteCommerce,
  useEditCommerce,
  useGetCommerce,
  useCreateCommerce,
  useGetEventsComboForAction,
} from '../../hooks'
import { CommerceInterface } from '../../types'
import { QUERY_KEYS } from '../../constants'
import { DeleteModal } from '../../components/Modal/DeleteModal'

const Commerce = () => {
  const [page, setPage] = useState(1)

  const [errors, setErrors] = useState(true)
  const queryClient = useQueryClient()

  const [showNewCommerceModal, setShowNewCommerceModal] = useState(false)
  const [showEditCommerceModal, setShowEditCommerceModal] = useState(false)

  const [selectedEventId, setSelectedEventId] = useState<number>()
  const [selectedCommerceId, setSelectedCommerceId] = useState(0)
  const [selectedCommerceName, setSelectedCommerceName] = useState('')
  const [selectedCommerceAddress, setSelectedCommerceAddress] = useState('')
  const [paginator, setPaginator] = useState<number | undefined>(1)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [selectedCommerceImage, setSelectedCommerceImage] = useState<string | null>(null)
  const [selectedCommerceImageError, setSelectedCommerceImageError] = useState('')
  const MAX_IMAGE_SIZE_KB = 100
  const MAX_WIDTH = 300
  const MAX_HEIGHT = 300

  const resetValues = () => {
    setSelectedCommerceName('')
    setSelectedCommerceId(0)
    setErrors(true)
    setSelectedCommerceAddress('')
    setSelectedEventId(0)
    setSelectedCommerceImage(null)
    setSelectedCommerceImageError('')
  }

  const { eventsCombo } = useGetEventsComboForAction()
  const { commerce, commerceFetching } = useGetCommerce(page)
  const { createCommerce, isCreateCommerceLoading } = useCreateCommerce(() => {
    setShowNewCommerceModal(false)
    resetValues()
  })

  const { editCommerce, isEditCommerceLoading } = useEditCommerce(() => {
    setShowEditCommerceModal(false)
    resetValues()
  })
  const { deleteCommerce } = useDeleteCommerce()

  const columns = [
    {
      title: 'Comercio',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Direccion',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Eventos',
      key: 'event',
      render: ({ event }: CommerceInterface) => event?.name,
    },
    {
      title: 'Imagen',
      key: 'image',
      render: ({ commerceImage }: CommerceInterface) => (
        <img
          src={commerceImage}
          alt="Comercio"
          style={{ maxWidth: '50px', maxHeight: '50px', borderRadius: '50%' }}
        />
      ),
    },
    {
      title: 'Acciones',
      key: 'action',
      render: ({ id, name, address, event, commerceImage }: CommerceInterface) => (
        <ActionsContainer>
          <EditIcon
            onClick={() => {
              setSelectedCommerceId(id)
              setSelectedCommerceName(name)
              setSelectedCommerceAddress(address)
              setSelectedEventId(event.id)
              setShowEditCommerceModal(true)
              setSelectedCommerceImage(commerceImage)
            }}
          />
          <TrashIcon
            onClick={() => {
              setSelectedCommerceId(id)
              setShowDeleteConfirmation(true)
            }}
          />
        </ActionsContainer>
      ),
    },
  ]

  const inputContainer = (
    <InputContainer>
      <RequiredText>(*)Datos Obligatorios</RequiredText>
      <FilterInput
        label="Comercio"
        name="commerce"
        required
        value={selectedCommerceName}
        onChange={e => {
          setSelectedCommerceName(e.target.value)
        }}
      />
      <FilterInput
        label="Direccion"
        name="address"
        required
        value={selectedCommerceAddress}
        onChange={e => {
          setSelectedCommerceAddress(e.target.value)
        }}
      />
      <FilterSelect
        name="eventId"
        label="Evento"
        required
        options={eventsCombo}
        value={selectedEventId}
        onChange={value => setSelectedEventId(value as number)}
      />
      <p style={{ fontSize: '15px', fontWeight: '600', color: '#858585' }}>
        {'IMAGEN (no mas de 100KB y no mas de 300x300px'}
        <span style={{ color: '#ff8585' }}> *</span>
      </p>
      <Upload
        beforeUpload={file => {
          const isSizeValid = file.size / 1024 <= MAX_IMAGE_SIZE_KB
          if (!isSizeValid) {
            setSelectedCommerceImageError('La imagen excede el tamaño máximo permitido.')
            return false
          }
          const reader = new FileReader()
          reader.onload = e => {
            const base64Image = e.target?.result
            if (base64Image) {
              const img = new Image()
              img.src = base64Image.toString()
              img.onload = () => {
                if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
                  setSelectedCommerceImageError('La imagen excede el tamaño máximo permitido.')
                } else {
                  setSelectedCommerceImageError('')
                  setSelectedCommerceImage(base64Image.toString())
                }
              }
            }
          }
          reader.readAsDataURL(file)
          return false
        }}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
      </Upload>
      {
        <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
          {selectedCommerceImageError}
        </p>
      }
      {selectedCommerceImage && (
        <img
          src={selectedCommerceImage}
          alt="Comercio"
          style={{ maxWidth: '80px', maxHeight: '80px', marginBottom: '10px' }}
        />
      )}
    </InputContainer>
  )

  useEffect(() => {
    setErrors(
      !selectedCommerceName.trim() ||
        !selectedCommerceAddress.trim() ||
        !selectedEventId ||
        !selectedCommerceImage,
    )
  }, [selectedCommerceName, selectedCommerceAddress, selectedEventId, selectedCommerceImage])

  useEffect(() => {
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.fetchQuery(QUERY_KEYS.COMMERCE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, paginator])

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h1>Lista de comercios</h1>
      </Col>
      <Col span={24}>
        <Row justify="end" align="middle" gutter={[12, 0]}>
          <Col>
            <PrimaryButton
              label="Nuevo Comercio"
              icon={<PlusIcon />}
              onClick={() => setShowNewCommerceModal(true)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          dataSource={commerce?.data || []}
          columns={columns}
          pagination={false}
          rowKey="id"
          loading={commerceFetching}
        />
        <Pagination onChange={e => handlePage(e)} current={page} total={commerce?.paginate.total} />
      </Col>
      <Modal
        title="Nuevo Comercio"
        open={showNewCommerceModal}
        onClose={() => {
          setShowNewCommerceModal(false)
          resetValues()
        }}
        onSave={() => {
          createCommerce({
            name: selectedCommerceName,
            address: selectedCommerceAddress,
            eventId: selectedEventId,
            commerceImage: selectedCommerceImage,
          })
        }}
        disabledSave={errors || isCreateCommerceLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <Modal
        title="Editar Comercio"
        open={showEditCommerceModal}
        onClose={() => {
          setShowEditCommerceModal(false)
          resetValues()
        }}
        onSave={() => {
          if (!selectedCommerceId) return
          editCommerce({
            id: selectedCommerceId,
            name: selectedCommerceName,
            address: selectedCommerceAddress,
            eventId: selectedEventId,
            commerceImage: selectedCommerceImage,
          })
        }}
        disabledSave={errors || isEditCommerceLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <DeleteModal
        title="Confirmar Eliminación"
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => {
          deleteCommerce({ id: selectedCommerceId })
          setShowDeleteConfirmation(false)
        }}
        showActionButtons
      >
        ¿Estás seguro de que deseas eliminar este comercio?
      </DeleteModal>
    </Row>
  )
}

export { Commerce }
