import { api } from './axios'
import { VisitsFiltersProps } from '../types'
import { errorDataMessage, setEndpoint } from '../utils'

const getAllVisits = async (
  page: number,
  filters: VisitsFiltersProps,
  maxResult: number | null,
  order: string | null,
  orderBy: string | null,
) => {
  const filtersAux = {
    ...filters,
    attendance_startDate:
      filters.attendance_dates && filters.attendance_dates[0]
        ? filters.attendance_dates[0].format('YYYY-MM-DD').toString()
        : null,
    attendance_endDate:
      filters.attendance_dates && filters.attendance_dates[1]
        ? filters.attendance_dates[1].format('YYYY-MM-DD').toString()
        : null,
    attendance_dates: null,
  }
  try {
    const response = await api.get(
      setEndpoint(
        `/api/event-attendance/read?page=${page}&maxResult=${maxResult}&order=${order}&orderBy=${orderBy}`,
        filtersAux,
      ),
    )

    return response.data || []
  } catch {
    errorDataMessage()
    return []
  }
}

const exportVisits = async (
  filters: VisitsFiltersProps,
  order: string | null,
  orderBy: string | null,
) => {
  const filtersAux = {
    ...filters,
    attendance_startDate:
      filters.attendance_dates && filters.attendance_dates[0]
        ? filters.attendance_dates[0].format('YYYY-MM-DD').toString()
        : null,
    attendance_endDate:
      filters.attendance_dates && filters.attendance_dates[1]
        ? filters.attendance_dates[1].format('YYYY-MM-DD').toString()
        : null,
    attendance_dates: null,
  }
  try {
    const response = await api.get(
      setEndpoint(`/api/event-attendance/export?order=${order}&orderBy=${orderBy}`, filtersAux),
      {
        responseType: 'blob',
      },
    )
    return response.data || []
  } catch {
    errorDataMessage()
    return []
  }
}

export const VisitApi = {
  getAllVisits,
  exportVisits,
}
