import { useQuery } from 'react-query'

import { ActionApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { errorDataMessage } from '../../utils'

const useGetAction = (page: number) => {
  const { data: action, isLoading: actionIsLoading } = useQuery(
    [QUERY_KEYS.ACTION],
    () => ActionApi.getAction(page),
    {
      onError: errorDataMessage,
    },
  )

  return { action, actionIsLoading }
}

export { useGetAction }
