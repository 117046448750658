import { Col, DatePicker, Input, InputNumber, Row, Select } from 'antd'
import styled from 'styled-components'

import { theme } from '../styles'

const { RangePicker } = DatePicker

const InputContainer = styled.div`
  margin-bottom: 3rem;
  display: grid;
  width: 100%;
  gap: 1rem;
  text-align: left;

  p,
  label {
    width: 100%;
    text-align: left;
  }
`

const RequiredText = styled.p`
  color: #ff8585;
  display: flex;
  justify-content: end;
  font-size: 12px;
`

const ActionsContainer = styled(Row)`
  display: flex;
  gap: 15px;
  justify-content: start;

  svg {
    &:hover {
      cursor: pointer;
    }
  }
`

const StyledTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  color: #163c68;
  margin-top: 1rem;
  margin-left: 0.7rem;
`

const StyledLabel = styled.label`
  color: ${props => props.theme.darkGray};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;

  span {
    color: ${props => props.theme.requiredRed};
  }
`

const StyledInput = styled(Input)`
  border: none;
  border-bottom: 1px solid ${props => props.theme.darkGray};
  background-color: ${props => props.theme.white} !important;
  margin-top: 10px;
  height: 2.55rem;
  .ant-input {
    background-color: unset;
  }
`

const StyledSearch = styled(Input.Search)`
  border: none;
  border-bottom: 1px solid ${props => props.theme.darkGray};
  background-color: ${props => props.theme.white} !important;
  margin-top: 10px;
  .ant-btn-icon-only.ant-input-search-button,
  .ant-input {
    background-color: unset;
    border: none;
    height: 2.5rem;
  }
  .ant-input-group-addon {
    background-color: unset;
  }
`

const InputNum = styled(InputNumber)`
  border: none;
  border-bottom: 1px solid ${props => props.theme.darkGray};
  background-color: ${props => props.theme.white} !important;
  margin-top: 10px;
  width: 100%;
  .ant-input-number-input {
    height: 2.45rem;
  }
  &.numeration .ant-input-number-handler-wrap {
    display: none !important;
  }
`

const InputGroup = styled(Col)`
  display: flex;
  flex-direction: column;
  .ant-input {
    background-color: unset;
  }
`

const StyledSelect = styled(Select)`
  border: none;
  border-bottom: 1px solid ${props => props.theme.darkGray};
  width: 100%;
  margin-top: 12px;
  height: 2.55rem;

  .ant-select-selector {
    border: none !important;
    background-color: ${props => props.theme.white} !important;
    max-height: 85px;
    overflow: auto;
  }

  .ant-select-selection-placeholder {
    text-transform: capitalize !important;
    font-weight: 400;
  }
  .anticon-close-circle {
    color: rgb(255, 133, 133);
  }
`

const StyledInputNumber = styled(InputNumber)`
  border: none;
  border-bottom: 1px solid ${props => props.theme.darkGray};
  width: 100%;
  margin-top: 10px;

  .ant-select-selector {
    border: none !important;
    background-color: ${theme.white} !important;
  }

  .ant-select-selection-placeholder {
    text-transform: capitalize !important;
    font-weight: 400;
  }
`

const StyledRangePicker = styled(RangePicker)`
  border: none;
  border-bottom: 1px solid ${props => props.theme.darkGray};
  width: 100%;
  height: 2.55rem;
  background-color: ${props => props.theme.white} !important;
`

const StyledSwitch = styled(Col)`
  padding: 3% 6% 1% 0;
  flex-basis: 14%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .toggle-check-container {
    display: flex;
    border-radius: 0.125rem;
  }
  .toggle-check-container input {
    height: 2rem !important;
  }
  .toggle-no,
  .toggle-yes {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: unset;
    -webkit-appearance: unset;
    color: ${theme.borderBottomInputColor};
    border: 1px solid var(--non-selected-toggle) !important;
    outline: none;
    max-width: 2.8125rem;
    min-width: 2.8125rem;
    transition: all ease 500ms;
    z-index: 0;
    padding: unset !important;
    height: 2rem !important;
    position: relative;
    background-color: ${theme.lightGray};
  }
  .toggle-no:hover,
  .toggle-yes:hover {
    border: 1px solid ${theme.lightGray} !important;
    background-color: ${theme.white};
  }
  .toggle-no:checked,
  .toggle-yes:checked {
    color: ${theme.primaryColor};
    border: 1px solid ${theme.primaryColor} !important;
    transition: all ease 500ms;
    outline: none;
    z-index: 1 !important;
    background-color: ${theme.white};
  }
  .toggle-no::before,
  .toggle-yes::before {
    font-size: 0.6875rem;
    font-weight: 600;
    line-height: 0.5rem;
    padding-top: 0.0625rem;
  }
  .toggle-yes {
    border-radius: 0.25rem 0px 0px 0.25rem;
    border-right: 1px solid transparent !important;
    /* padding: .125rem .8125rem .25rem .8125rem !important; */
  }
  .toggle-yes::before {
    content: 'SI';
  }
  .toggle-no {
    transform: translateX(-1px);
    border-left: 1px solid transparent !important;
    border-radius: 0px 0.25rem 0.25rem 0px;
    /* padding: .125rem .25rem .25rem .5625rem !important; */
  }
  .toggle-no::before {
    content: 'NO';
  }
`

export { FilterButton } from './FilterButton'
export { FilterInput } from './FilterInput'
export { FilterSelect } from './FilterSelect'
export { FilterDatePicker } from './FilterDatePicker'
export { PrimaryButton } from './PrimaryButton'
export { SecundaryButton } from './SecundaryButton'
export { Modal } from './Modal'
export { Checkbox } from './Checkbox'
export { CustomCard } from './CustomCard'
export {
  InputContainer,
  RequiredText,
  ActionsContainer,
  StyledTitle,
  StyledLabel,
  StyledInput,
  InputNum,
  InputGroup,
  StyledSelect,
  StyledSearch,
  StyledRangePicker,
  StyledSwitch,
  StyledInputNumber,
}
