import React from 'react'

import { StyledInput, StyledLabel } from '..'

interface Props {
  label: string
  name: string
  required?: boolean
  value?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  keyEnter?: () => void
  allowClear?:
    | boolean
    | {
        clearIcon?: React.ReactNode
      }
}

const FilterInput = ({
  label,
  name,
  required,
  value,
  onChange,
  placeholder,
  keyEnter,
  allowClear = true,
}: Props) => {
  return (
    <StyledLabel htmlFor={name}>
      {label} {required && <span>*</span>}
      <StyledInput
        placeholder={placeholder || 'Ingrese texto'}
        name={name}
        allowClear={allowClear}
        value={value}
        onChange={onChange}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            keyEnter?.()
          }
        }}
      />
    </StyledLabel>
  )
}

export { FilterInput }
