import { ReactElement } from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.secondaryColor};
  border: 1px solid ${props => props.theme.secondaryColor};
  border-radius: 50px;
  padding: 0 20px;
  width: 100%;
  height: 45px;
  font-weight: 600;
  text-transform: uppercase;

  svg {
    margin-right: 10px;
    height: 1.3rem;
    width: 1.3rem;

    & > path {
      fill: ${props => props.theme.white};
    }
  }

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.secondaryColor};
    background-color: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.secondaryColor};

    svg > path {
      fill: ${props => props.theme.secondaryColor};
    }
  }
`

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string
  icon?: ReactElement
}

const PrimaryButton = ({ icon, label, ...buttonProps }: Props) => {
  return (
    <StyledButton {...buttonProps}>
      {icon}
      <p>{label}</p>
    </StyledButton>
  )
}

export { PrimaryButton }
