import { useQuery } from 'react-query'

import { UserApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { UsersFiltersProps } from '../../types'
import { errorDataMessage } from '../../utils'

const useGetUsers = (page: number, params: UsersFiltersProps) => {
  const { data: users, isLoading: usersIsLoading } = useQuery(
    [QUERY_KEYS.USERS],
    () => UserApi.getAllUsers(page, params),
    {
      onError: errorDataMessage,
    },
  )

  return { users, usersIsLoading }
}

export { useGetUsers }
