import { PropsWithChildren } from 'react'

import { CrossIcon } from '../../../assets/icons'
import { SecundaryButton } from '../../SecundaryButton'
import './styles.css'

interface Props {
  title: string
  open: boolean
  onClose: () => void
  showActionButtons?: boolean
  onConfirm?: () => void
  disabledConfirm?: boolean
  maxWidth?: string
  width?: string
}

const DeleteModal = ({
  title,
  open,
  onClose,
  showActionButtons,
  onConfirm,
  disabledConfirm,
  children,
  maxWidth,
  width,
}: PropsWithChildren<Props>) => {
  return (
    <div id="modalWrapper" className={`${open ? 'visible' : ''}`}>
      <div className="modal-content" style={maxWidth && width ? { maxWidth, width } : {}}>
        <div onClick={() => onClose()} className="modal__close" id="modalClose">
          <CrossIcon />
        </div>
        <p className="modal-content__success-msg" id="msg">
          {title}
        </p>
        {children}
        {showActionButtons && (
          <div className="button-container">
            <SecundaryButton label="Cancelar" onClick={() => onClose()} />
            <SecundaryButton
              label="Confirmar"
              onClick={() => onConfirm && onConfirm()}
              disabled={disabledConfirm}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export { DeleteModal }
