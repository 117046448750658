import { useGenericMutation } from '../useGenericMutation'
import { ActionApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useDeleteAction = (onSuccess?: () => void) => {
  const {
    mutate: deleteAction,
    isSuccess: isDeleteActionSuccess,
    isLoading: isDeleteActionLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.ACTION,
    mutationFn: ActionApi.deleteAction,
    errorMessage: 'No fue posible eliminar la accion',
    successMessage: 'Accion eliminada exitosamente',
    onSuccess,
  })

  return {
    deleteAction,
    isDeleteActionSuccess,
    isDeleteActionLoading,
  }
}

export { useDeleteAction }
