import { useGenericMutation } from '../useGenericMutation'
import { EventApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useDeleteEvent = (onSuccess?: () => void) => {
  const {
    mutate: deleteEvent,
    isSuccess: isDeleteEventSuccess,
    isLoading: isDeleteEventLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.EVENTS,
    mutationFn: EventApi.deleteEvent,
    errorMessage: 'No fue posible eliminar el evento',
    successMessage: 'Evento eliminado exitosamente',
    onSuccess,
  })

  return {
    deleteEvent,
    isDeleteEventSuccess,
    isDeleteEventLoading,
  }
}

export { useDeleteEvent }
