import { useGenericMutation } from '../useGenericMutation'
import { LevelApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useEditLevel = (onSuccess?: () => void) => {
  const {
    mutate: editLevel,
    isSuccess: isEditLevelSuccess,
    isLoading: isEditLevelLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.LEVELS,
    mutationFn: LevelApi.updateLevel,
    errorMessage: 'No fue posible modificar el nivel',
    successMessage: 'Nivel modificado exitosamente',
    onSuccess,
  })

  return {
    editLevel,
    isEditLevelSuccess,
    isEditLevelLoading,
  }
}

export { useEditLevel }
