import { useQuery } from 'react-query'

import { BenefitsApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { errorDataMessage } from '../../utils'

const useGetBenefits = (page: number) => {
  const { data: benefits, isLoading: benefitsIsLoading } = useQuery(
    [QUERY_KEYS.BENEFITS],
    () => BenefitsApi.getBenefits(page),
    {
      onError: errorDataMessage,
    },
  )

  return { benefits, benefitsIsLoading }
}

export { useGetBenefits }
