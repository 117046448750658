import { useGenericMutation } from '../useGenericMutation'
import { CategoryApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useEditCategory = (onSuccess?: () => void) => {
  const {
    mutate: editCategory,
    isSuccess: isEditCategorySuccess,
    isLoading: isEditCategoryLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.CATEGORY,
    mutationFn: CategoryApi.updateCategory,
    errorMessage: 'No fue posible modificar la categoria',
    successMessage: 'Categoria modificada exitosamente',
    onSuccess,
  })

  return {
    editCategory,
    isEditCategorySuccess,
    isEditCategoryLoading,
  }
}

export { useEditCategory }
