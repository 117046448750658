import { useGenericMutation } from '../useGenericMutation'
import { ActionApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useCreateAction = (onSuccess?: () => void) => {
  const {
    mutate: createAction,
    isSuccess: isCreateActionSuccess,
    isLoading: isCreateActionLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.ACTION,
    mutationFn: ActionApi.setAction,
    errorMessage: 'No fue posible guardar la accion',
    successMessage: 'Accion guardada exitosamente',
    onSuccess,
  })

  return {
    createAction,
    isCreateActionSuccess,
    isCreateActionLoading,
  }
}

export { useCreateAction }
