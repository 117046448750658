import { api } from './axios'

const getPeriodicityCombo = async () => {
  const response = await api.get('/api/periodicity/combo')
  return response.data
}

export const PeriodicityApi = {
  getPeriodicityCombo,
}
