import { StyledLabel, StyledSwitch } from '..'

interface CustomSwitchProps {
  name: string
  value: number
  title: string
  handleCfg: (key: string, value: number) => void
}

const CustomSwitch = ({ name, value, title, handleCfg }: CustomSwitchProps) => {
  return (
    <StyledSwitch>
      <StyledLabel>
        <p style={{ marginBottom: '0.5rem', whiteSpace: 'nowrap' }}>{title}</p>
      </StyledLabel>
      <div className="toggle-check-container">
        <input
          className="toggle-yes"
          type="radio"
          name={name}
          checked={!!value}
          onClick={() => handleCfg(name, 0)}
        />
        <input
          className="toggle-no"
          type="radio"
          name={name}
          checked={!value}
          onClick={() => handleCfg(name, 1)}
        />
      </div>
    </StyledSwitch>
  )
}

export { CustomSwitch }
