import { useGenericMutation } from '../useGenericMutation'
import { LevelApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useDeleteLevel = (onSuccess?: () => void) => {
  const {
    mutate: deleteLevel,
    isSuccess: isDeleteLevelSuccess,
    isLoading: isDeleteLevelLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.LEVELS,
    mutationFn: LevelApi.deleteLevel,
    errorMessage: 'No fue posible eliminar el nivel',
    successMessage: 'Nivel eliminado exitosamente',
    onSuccess,
  })

  return {
    deleteLevel,
    isDeleteLevelSuccess,
    isDeleteLevelLoading,
  }
}

export { useDeleteLevel }
