import styled from 'styled-components'
import {
  Col,
  Row,
  Button,
  Table,
  DatePicker,
  InputNumber,
  Modal,
  Typography,
  Tag,
  Collapse,
  Form,
  Tabs,
  Dropdown,
  Spin,
} from 'antd'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

const imgHeight = 3
const headerPaddingHeight = 0.8
const headerHeight = headerPaddingHeight * 2 + imgHeight

const { Title } = Typography

const Header = styled(Row)`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  padding: ${headerPaddingHeight}rem 1.25rem;
  background-color: ${props => props.theme.secundaryColor};
  .ant-dropdown-link {
    color: white;
    cursor: pointer;
  }
  .ant-dropdown-link svg {
    color: white;
  }
`
const Main = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  height: calc(100% - ${headerHeight}rem);
  background-color: ${props => props.theme.backgroundColor};
`
const Nav = styled(Col)`
  width: auto;
  height: 100%;
  background-color: ${props => props.theme.primaryColor};
  a {
    white-space: nowrap;
    color: white;
    width: 100%;
    transition: all ease 500ms;
    height: ${headerHeight - 1}rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid white;
  }
  a .anticon {
    font-size: 1.5rem;
    color: white;
    margin: 1rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
  }
  a .anticon svg {
    color: white;
  }
  a.active {
    color: ${props => props.theme.darkGreenText};
    background-color: ${props => props.theme.backgroundColor};
  }
  a.active svg {
    color: ${props => props.theme.darkGreenText};
  }
`
const LinkLabel = styled.span`
  margin-right: 1rem;
  font-size: 1rem;
`
const ContentWrapper = styled(Col)`
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow: hidden;
`
const Logo = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  .header-logo {
    height: ${imgHeight}rem;
    filter: brightness(100);
  }
  .header-logo.negro {
    filter: brightness(0);
  }
`
const LogoutButton = styled(Button)`
  border: none !important;
  padding: unset !important;
`
const SiteWrapper = styled(Row)`
  width: 100%;
  height: 100vh;
`
const CardWrapper = styled(Col)`
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  .principal-table {
    position: relative;
    overflow-y: auto;
  }
`
const OrderModal = styled(Modal)`
  margin-top: 1rem;
  .ant-modal-title {
    font-size: 1.5rem;
  }
  .ant-space {
    align-items: flex-start !important;
  }
  .ant-modal-header h1 {
    color: ${props => props.theme.darkGreenText};
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: 0.125rem;
  }
  .ant-modal-content {
    border-radius: 0.5rem !important;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  }
  .ant-modal-header {
    border-radius: 0.5rem 0.5rem 0 0 !important;
  }
`

const ExpedientModal = styled(Modal)`
  margin-top: 1rem;
  .ant-modal-title {
    font-size: 1.5rem;
  }
  .ant-space {
    align-items: flex-start !important;
  }
  .ant-modal-header h1 {
    color: ${props => props.theme.darkGreenText};
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: 0.125rem;
  }
  .ant-modal-content {
    border-radius: 0.5rem !important;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  }
  .ant-modal-header {
    border-radius: 0.5rem 0.5rem 0 0 !important;
  }
`
const StyledForm = styled(Form)`
  .ant-picker-input > input[disabled],
  .ant-input[disabled],
  .ant-input-number-disabled,
  .ant-input-affix-wrapper-disabled,
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker.ant-picker-disabled {
    background: ${props => props.theme.disabledInput} !important;
    border-color: white !important;
    color: black !important;
    cursor: text !important;
  }
  &.survey-form {
    .disabled-panel {
      color: ${props => props.theme.grayCE};
      font-weight: 400;
      cursor: not-allowed;
    }
    .disabled-panel svg {
      fill: ${props => props.theme.grayCE};
    }
    .disabled-input {
      color: ${props => props.theme.grayCE};
      font-weight: 400;
      cursor: not-allowed;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header h1 {
      font-size: 1.125rem !important;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      transform: rotate(180deg);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
      transform: rotate(-90deg);
    }
    .ant-collapse-item:last-child {
      border-bottom: unset;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header::before,
    .ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
      display: none !important;
    }
  }
`
const StandardModal = styled(Modal)`
  width: fit-content !important;
  min-width: 25rem;
  margin-top: 1rem;
  .ant-modal-content {
    border-radius: 0.5rem !important;
    overflow: hidden;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
    overflow: auto;
  }
  .ant-modal-title {
    font-size: 1.5rem;
    margin-right: 1.75rem;
  }
  &.survey-notes-modal {
    max-width: 32rem !important;
  }
  &.media-modal {
    max-width: 50rem;
    .ant-modal-content {
      display: flex;
      flex-direction: column;
    }
    .ant-modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
    .ant-tabs {
      height: 100%;
    }
    .ant-tabs-content {
      height: 100%;
    }
    .ant-tabs-tabpane > div {
      height: 100% !important;
    }
  }
  &.media-modal .ant-modal-content {
    height: 96vh;
    width: 85vw;
    max-width: 50rem;
  }
  .ant-modal-header h1 {
    color: ${props => props.theme.darkGreenText};
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: 0.125rem;
  }
  &.inspectors-modal {
    width: fit-content !important;
    min-width: 25rem;
    max-width: 30rem;
    margin-top: 1rem;
    .ant-modal-content {
      display: flex;
      flex-direction: column;
    }
    .ant-modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
  &.bulk-data-upload {
    #file-upload-button {
      display: none;
    }
  }
  .info-upload-orders-modal {
    margin-left: 0.25rem;
  }
  .info-upload-orders-modal svg {
    cursor: help;
    color: ${props => props.theme.darkText} !important;
    transform: translateY(0.0625rem);
  }
`
const CloserModal = styled(Modal)`
  width: fit-content !important;
  min-width: 30rem;
  margin-top: 1rem;
  .ant-modal-title {
    font-size: 1.5rem;
  }
  .ant-modal-body {
    padding-bottom: 0.5rem !important;
  }
  .ant-modal-header h1 {
    color: ${props => props.theme.darkGreenText};
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: 0.125rem;
  }
  .ant-modal-content {
    border-radius: 0.5rem !important;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  }
  .ant-modal-header {
    border-radius: 0.5rem 0.5rem 0 0 !important;
  }
`
const ConfirmModal = styled(Modal)`
  width: fit-content !important;
  min-width: 20rem;
  margin-top: 1rem;
  .ant-modal-title {
    font-size: 1.25rem;
  }
  .ant-modal-header h1 {
    color: ${props => props.theme.darkGreenText};
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: 0.125rem;
  }
  .ant-modal-content {
    border-radius: 0.5rem !important;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  }
  .ant-modal-header {
    border-radius: 0.5rem 0.5rem 0 0 !important;
  }
`
const SectionTitle = styled(Title)`
  border-bottom: 1px solid ${props => props.theme.grayCE};
  text-align: right;
  color: ${props => props.theme.grayCE};
  padding-bottom: 0.5rem;
  font-size: 1.25rem !important;
  padding-top: 2rem;
  &:first-child {
    padding-top: 0 !important;
  }
  &.left-align {
    text-align: left;
    margin-bottom: 0;
  }
  &.no-border {
    border-bottom: unset;
  }
  &.section-title-header,
  &.see-worker-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    h1 {
      padding: 0;
      margin-bottom: 0;
    }
    img {
      max-height: 3rem;
    }
  }
`
const VerificationTitle = styled(Title)`
  text-align: left;
  color: ${props => props.theme.grayCE};
  font-size: 1.125rem !important;
`
const PanelSectionTitle = styled(Title)`
  text-align: right;
  color: ${props => props.theme.grayCE};
  font-size: 1.25rem !important;
  margin: 1rem 0 !important;
  padding-right: 1rem;
  align-items: center;
  &:first-child {
    padding-top: 0 !important;
  }
`
const ConfirmationMsg = styled(Title)`
  border-bottom: unset;
  text-align: right;
  color: ${props => props.theme.grayCE};
  font-size: 1rem !important;
  margin-bottom: unset !important;
`
const GroupTitle = styled(Title)`
  font-size: 1rem !important;
  padding: 2rem 0 1rem 0;
  &.inspectors {
    padding: 1rem 0 0 0 !important;
  }
`
const VerificationSubtitle = styled(Title)`
  font-size: 1rem !important;
`
const LabelDisplayer = styled(Row)`
  justify-content: right;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  svg {
    color: white !important;
  }
  .anticon {
    font-size: 1.5rem;
    margin: 1rem;
    width: 1.5rem;
  }
`
const MenuLabelsDisplayer = styled.div`
  padding: 0;
  cursor: pointer;
`
const CardWrapperHeader = styled(Row)`
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 1rem;
`
const PageName = styled(Col)`
  margin-left: 1rem;
  h1 {
    color: ${props => props.theme.darkGreenText};
  }
  svg {
    fill: ${props => props.theme.darkGreenText};
  }
`
const Options = styled(Col)`
  margin-left: auto;
  align-self: flex-end;
  align-items: center;
  padding: 1rem 1rem 0 0;
  display: flex;
`
const AddButton = styled(Button)`
  font-size: 1rem;
  font-weight: 600;
  border-radius: 2rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 0.75rem;
  svg {
    color: white !important;
  }
`
const OptionsWrapper = styled(Row)`
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  .anticon {
    font-size: 1.25rem;
  }
  svg {
    cursor: pointer;
    color: ${props => props.theme.darkGreenText} !important;
    margin: 0.125rem 0.5rem;
  }
  .filter-btn-container {
    display: flex;
    align-items: center;
    overflow: auto;
  }
  .anticon {
    font-size: 1rem;
  }
`
const CardWrapperTable = styled(Table)`
  position: relative;
  width: 100%;
  .ant-table {
    width: 100%;
    margin-bottom: auto;
    line-height: 1.25;
  }
  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
  }
  .ant-spin-container {
    justify-content: space-between;
    width: 100%;
  }
  .ant-table-wrapper {
    width: 100%;
  }
  .ant-pagination {
    margin: 0;
    margin-top: 1rem !important;
    width: auto;
    display: flex;
    padding: 1rem;
    justify-content: right;
    background-color: ${props => props.theme.backgroundColor};
    position: sticky;
    bottom: 0;
    right: 0;
  }
  .ant-table-tbody > tr:nth-child(odd) > td {
    background-color: ${props => props.theme.oddRows};
  }
  .ant-table-tbody > tr:hover > td {
    background-color: ${props => props.theme.selectedRows};
  }
  .ant-table-tbody > tr > td {
    font-size: 0.75rem;
    border-bottom: unset !important;
  }
  .ant-table-thead > tr > th {
    text-align: center;
    border-bottom: 1px solid ${props => props.theme.grayCE} !important;
  }
  .ant-table-cell svg {
    color: ${props => props.theme.darkGreenText} !important;
    cursor: pointer;
  }
  td.ant-table-cell {
    text-align: center;
    cursor: zoom-in;
  }
  .ant-table-cell {
    padding: 0.5rem;
  }
  &.bulk-data-table td.ant-table-cell {
    cursor: unset;
  }
  td {
    background-color: white;
  }
  &.inspectors-table td.ant-table-cell {
    cursor: unset !important;
  }
  .column-sort-elements {
    display: flex !important;
    flex-direction: column !important;
    min-width: 0.65rem;
    span {
      height: 0.75rem;
    }
  }
  .column-title-row {
    gap: 0.2rem;
    justify-content: space-between;
  }
  .status-info svg {
    cursor: help;
  }
  .status-info.error svg {
    color: ${props => props.theme.red} !important;
  }
`

const FilterWrapper = styled(Col)`
  width: 100%;
  height: 100%;
`

const MaxWidthCol = styled(Col)`
  width: 100%;
`

const InputsRow = styled(Row)`
  width: 100%;
  .ant-space {
    width: 100%;
  }
  .ant-space-item {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
  .anticon-close-circle {
    color: ${props => props.theme.red} !important;
  }
  .ant-picker {
    width: 100%;
  }
  .address-row .ant-space-item:first-child {
    width: 220px !important;
  }
  .address-row .ant-space-item:last-child {
    width: 230px !important;
  }
  .expedient-survey-row .ant-space-item:first-child {
    width: 16% !important;
  }
  .expedient-survey-row .ant-space-item:last-child {
    width: auto !important;
  }
  .expedient-survey-row .ant-space-item:last-child .ant-form-item {
    margin-bottom: 0;
  }
  .expedient-header-row {
    align-items: center;
  }
  .filter-cuit .ant-input-number-handler-wrap {
    display: none;
  }
  .filter-input .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-form-item-label {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .all-selector .ant-form-item-control-input {
    align-items: unset;
  }
  .ant-form-item-label > label {
    color: ${props => props.theme.lightBlack} !important;
    font-size: 0.8rem;
  }
  .ant-form-item-label.between {
    justify-content: space-between;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: ${props => props.theme.lightBlack} !important;
    border-color: ${props => props.theme.darkGray} !important;
    background-color: ${props => props.theme.disabledInput} !important;
  }
  .georeference-last-form-item {
    flex-flow: row nowrap;
  }
  .georeference-last-form-item svg {
    height: 100%;
    margin-left: 0.5rem;
  }
`

const InputNum = styled(InputNumber)`
  width: 100%;
  .ant-input-number {
    width: 100%;
  }
  &.numeration .ant-input-number-handler-wrap {
    display: none !important;
  }
`

const { RangePicker } = DatePicker

const DateFromTo = styled(RangePicker)`
  width: 100%;
`

const FilterTag = styled(Tag)`
  display: inline-flex;
  padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: ${props => props.theme.darkGreenText};
  color: ${props => props.theme.inputBg};
  svg {
    margin: 0.125rem;
    fill: ${props => props.theme.inputBg};
  }
`

const ArrowAsc = styled(CaretUpOutlined)`
  svg {
    fill: ${props => props.theme.darkGray};
    transition: ease all 500ms;
  }
  &.selected svg {
    fill: ${props => props.theme.primaryColor};
  }
`
const ArrowDesc = styled(CaretDownOutlined)`
  svg {
    fill: ${props => props.theme.darkGray};
    transition: ease all 500ms;
  }
  &.selected svg {
    fill: ${props => props.theme.primaryColor};
  }
`
const SurveyModal = styled(Modal)`
  margin-top: 1rem;
  .ant-modal-title {
    font-size: 1.5rem;
  }
  .ant-space {
    align-items: flex-start !important;
  }
  .ant-modal-header h1 {
    color: ${props => props.theme.darkGreenText};
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: 0.125rem;
  }
  .ant-modal-content {
    border-radius: 0.5rem !important;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  }
  .ant-modal-header {
    border-radius: 0.5rem 0.5rem 0 0 !important;
  }
  &.pdf-modal {
    width: 90vw !important;
    .ant-modal-body {
      padding: unset;
      height: 90vh;
      .ant-spin-nested-loading {
        height: 100%;
        .ant-spin-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          iframe {
            flex: 1;
          }
        }
      }
    }
  }
`

const BackButton = styled(Button)`
  border-radius: 0.25rem;
  color: ${props => props.theme.darkGreenText};
  padding: 0.125rem 0.5rem;
  box-sizing: content-box;
  font-weight: 500;
`

const CollapseForm = styled(Collapse)`
  &.ant-collapse {
    background: unset !important;
  }
  .ant-collapse-header {
    padding: 0 !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between !important;
    align-items: center;
  }
  .ant-space-item {
    height: 100% !important;
    justify-content: flex-end;
  }
  .ant-form-item {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end;
  }
  .ant-form-item-control {
    justify-content: flex-end;
    flex-grow: unset;
    margin-bottom: 1rem;
  }
`
const PanelSubSectionTitle = styled(Title)`
  font-size: 1.125rem !important;
  text-align: right;
  margin-top: 2rem !important;
`
const ScheduleTable = styled(Table)`
  width: 50%;
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: 1px solid ${props => props.theme.disabledInput};
    text-align: center;
  }
  .ant-table-tbody > tr > td:first-child,
  .ant-table-thead > tr > th:first-child {
    text-align: left;
  }
  .ant-table-tbody > tr:last-child > td {
    border-bottom: unset !important;
  }
`
const FilterModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0.5rem !important;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  }
  .ant-modal-header {
    border-radius: 0.5rem 0.5rem 0 0 !important;
  }
`
const StyledTabs = styled(Tabs)`
  color: ${props => props.theme.darkGreenText};
  .ant-tabs-tab svg {
    fill: ${props => props.theme.darkGreenText};
    transition: all ease 300ms;
  }
  .ant-tabs-content-holder {
    flex: 1;
  }
  .ant-tabs-tab-active svg {
    fill: ${props => props.theme.primaryColor};
  }
  .ant-tabs-tab:hover svg {
    fill: ${props => props.theme.primaryColor};
  }
  .ant-tabs-nav {
    margin-bottom: 1.5rem !important;
  }
  .chonky-chonkyRoot {
    border: unset;
    font-weight: 200;
    padding: unset;
  }
  .chonky-fileListWrapper > div > div > div > div:nth-child(odd) {
    background-color: ${props => props.theme.oddRows};
  }
  .chonky-fileListWrapper > div > div > div > div > div > div > div > div {
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
  .chonky-gridContainer > div > div {
    background-color: unset !important;
  }
  .chonky-gridContainer > div > div > div > div > div > div {
    border: none !important;
    box-shadow: none !important;
  }
  .chonky-selectionSizeText {
    color: ${props => props.theme.primaryColor};
  }
  .chonky-activeButton svg path {
    fill: ${props => props.theme.primaryColor};
  }
  .chonky-activeButton .chonky-text {
    color: ${props => props.theme.primaryColor};
    fill: ${props => props.theme.primaryColor};
  }
`
const StyledSpin = styled(Spin)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledOrderActivityColumn = styled.div`
  display: flex;
  justify-content: center;
  width: 300px;
`

const StyledDropdown = styled(Dropdown)``

const StatusLed = styled.div`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  position: relative;
  box-shadow: 0px 0px 2px 1px inset #b2b097;
  overflow: hidden;
  cursor: help;
  &.IN_PROGRESS {
    background-color: ${props => props.theme.status.yellow} !important;
  }
  &.FINALIZED {
    background-color: ${props => props.theme.status.green} !important;
  }
  &.PENDING {
    background-color: ${props => props.theme.status.red} !important;
  }
`

const SortContainer = styled(Col)`
  display: flex !important;
  flex-direction: column !important;
  min-width: 0.65rem;
  span {
    height: 0.75rem;
  }
`

export {
  Header,
  Nav,
  Main,
  ContentWrapper,
  Logo,
  SiteWrapper,
  CardWrapper,
  LinkLabel,
  LabelDisplayer,
  MenuLabelsDisplayer,
  CardWrapperHeader,
  PageName,
  Options,
  MaxWidthCol,
  AddButton,
  OrderModal,
  CardWrapperTable,
  OptionsWrapper,
  DateFromTo,
  FilterWrapper,
  InputsRow,
  LogoutButton,
  InputNum,
  SectionTitle,
  GroupTitle,
  FilterTag,
  StandardModal,
  CloserModal,
  ConfirmationMsg,
  ConfirmModal,
  ArrowAsc,
  ArrowDesc,
  SurveyModal,
  BackButton,
  CollapseForm,
  PanelSectionTitle,
  PanelSubSectionTitle,
  StyledForm,
  ScheduleTable,
  FilterModal,
  StyledTabs,
  StyledDropdown,
  StyledSpin,
  StyledOrderActivityColumn,
  ExpedientModal,
  VerificationTitle,
  VerificationSubtitle,
  StatusLed,
  SortContainer,
}
