import { useQuery } from 'react-query'

import { BenefitsApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetIdReportBenefits = () => {
  const { data } = useQuery([QUERY_KEYS.REPORT_BENEFITS_COMBO], () =>
    BenefitsApi.getIDReportBenefits(),
  )

  return { report: data ?? [] }
}

export { useGetIdReportBenefits }
