import styled from 'styled-components'

import { StyledSelect } from '..'
import { OptionType } from '../../types'

const StyledLabel = styled.label`
  p {
    color: ${props => props.theme.darkGray};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;

    & > span:first-of-type {
      color: #ff8585;
    }
  }
`

interface Props {
  label: string
  name: string
  allowClear?: boolean
  required?: boolean
  options?: OptionType[]
  value?: unknown
  onChange?: (value: unknown) => void
  keyEnter?: () => void
}

const FilterSelect = ({
  label,
  name,
  required,
  options,
  value,
  allowClear = true,
  onChange,
  keyEnter,
}: Props) => {
  const { Option } = StyledSelect
  return (
    <StyledLabel htmlFor={name}>
      <p>
        {label} {required && <span>*</span>}
      </p>
      <StyledSelect
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
        optionFilterProp="children"
        showSearch
        allowClear={allowClear}
        placeholder="Seleccionar"
        value={value}
        onChange={onChange}
        onKeyUp={(event: { key: string }) => {
          if (event.key === 'Enter') {
            keyEnter?.()
          }
        }}
        filterOption={(value, option) => {
          if (option?.value) {
            return removeAccents(option?.children).includes(removeAccents(value))
          } else {
            return false
          }
        }}
      >
        {options?.map((option: OptionType) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
    </StyledLabel>
  )
}

const removeAccents = (str: string) => {
  return str
    ? str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : str
}

export { FilterSelect }
