import { api } from './axios'
import { EventsFiltersProps } from '../types'
import { errorDataMessage, setEndpoint } from '../utils'

interface EventPayload {
  location_id: number
  activity_id: number
  max_read_enabled: number
}

interface EditEventPayload extends EventPayload {
  id: number
}

interface DeleteEventPayload {
  id: number
}

const getAllEvents = async (
  page: number,
  filters: EventsFiltersProps,
  maxResult: number | null,
) => {
  try {
    const response = await api.get(
      setEndpoint(`/api/event/read?page=${page}&maxResult=${maxResult}`, filters),
    )
    return response.data || []
  } catch {
    errorDataMessage()
    return []
  }
}

const createEvent = async (payload: EventPayload) => {
  const response = await api.post('/api/event/create', payload)
  return response.data
}

const updateEvent = async ({
  id,
  location_id,
  activity_id,
  max_read_enabled,
}: EditEventPayload) => {
  const response = await api.post(`/api/event/edit/${id}`, {
    location_id,
    activity_id,
    max_read_enabled,
  })
  return response.data
}

const deleteEvent = async ({ id }: DeleteEventPayload) => {
  const response = await api.delete(`/api/event/delete/${id}`)
  return response.data
}

const getEventsCombo = async () => {
  const response = await api.get('/api/event/combo')
  return response.data.data
}

export const EventApi = {
  getAllEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  getEventsCombo,
}
