import { useQuery } from 'react-query'

import { LevelApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetLevelsCombo = () => {
  const { data: levels } = useQuery([QUERY_KEYS.LEVELS_COMBO], () => LevelApi.getLevelsCombo())

  return {
    levelsCombo: ((levels?.data ?? []) as { id: number; value: string }[]).map(({ id, value }) => ({
      label: value,
      value: id,
    })),
  }
}

export { useGetLevelsCombo }
