import { CheckboxOptionType, Radio, RadioChangeEvent } from 'antd'
import styled from 'styled-components'

const StyledCheckbox = styled(Radio.Group)`
  display: flex;
  width: 20%;
  margin-top: 10px;

  .ant-radio-button-wrapper {
    background: #f0f0f0;
    color: #c4c4c4;
    border: 1px solid #c4c4c4;
  }

  .ant-radio-button-wrapper-checked {
    color: ${props => props.theme.primaryColor} !important;
    border: 1px solid ${props => props.theme.primaryColor} !important;
    background: #f0f0f0 !important;
    &:hover {
      color: ${props => props.theme.primaryColor};
      border: 1px solid ${props => props.theme.primaryColor};
    }
  }

  .ant-radio-button-wrapper-checked:before {
    background-color: ${props => props.theme.primaryColor};
  }
`

const StyledLabel = styled.label`
  color: ${props => props.theme.darkGray};
  font-size: 14px;
  font-weight: 600;
  text-transform: none !important;

  span:first-of-type {
    color: #ff8585;
  }
`

interface Props {
  label: string
  name: string
  options: CheckboxOptionType[]
  required?: boolean
  value?: string | boolean | number
  onChange?: (e: RadioChangeEvent) => void
}

const Checkbox = ({ label, name, required, value, onChange, options }: Props) => {
  return (
    <StyledLabel htmlFor={name}>
      {label} {required && <span>*</span>}
      <StyledCheckbox
        options={options}
        value={value}
        onChange={onChange}
        optionType="button"
        buttonStyle="solid"
      />
    </StyledLabel>
  )
}

export { Checkbox }
