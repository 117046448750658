import { errorDataMessage, setEndpoint } from '../utils'
import { api } from './axios'
import { LevelsFiltersProps } from '../types'

interface NewLevelPayload {
  category: string
  neededPoints: number
}

interface EditLevelPayload {
  id: number
  category: string
  neededPoints: number
}

interface DeleteActivityPayload {
  id: number
}

const apiLevel = '/api/level/'

const setLevels = async ({ category, neededPoints }: NewLevelPayload) => {
  const response = await api.post(`${apiLevel}create`, {
    category: category,
    neededPoints: neededPoints,
  })
  return response.data
}

const getLevels = async (page: number, filters: LevelsFiltersProps) => {
  try {
    const response = await api.get(setEndpoint(`${apiLevel}read?page=${page}`, filters))
    return response.data || []
  } catch {
    errorDataMessage()
    return []
  }
}

const updateLevel = async ({ id, category, neededPoints }: EditLevelPayload) => {
  const response = await api.post(`${apiLevel}edit/${id}`, { category, neededPoints })
  return response.data
}

const deleteLevel = async ({ id }: DeleteActivityPayload) => {
  const response = await api.delete(`${apiLevel}delete/${id}`)
  return response.data
}

const getLevelsCombo = async () => {
  const response = await api.get(`${apiLevel}combo`)
  return response.data
}

export const LevelApi = {
  setLevels,
  getLevels,
  updateLevel,
  deleteLevel,
  getLevelsCombo,
}
