import { useGenericMutation } from '../useGenericMutation'
import { ActivityApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useDeleteActivity = (onSuccess?: () => void) => {
  const {
    mutate: deleteActivity,
    isSuccess: isDeleteActivitySuccess,
    isLoading: isDeleteActivityLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.ACTIVITIES,
    mutationFn: ActivityApi.deleteActivity,
    errorMessage: 'No fue posible eliminar la actividad',
    successMessage: 'Actividad eliminada exitosamente',
    onSuccess,
  })

  return {
    deleteActivity,
    isDeleteActivitySuccess,
    isDeleteActivityLoading,
  }
}

export { useDeleteActivity }
