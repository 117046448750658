import { useQuery } from 'react-query'

import { LocationApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { LocationsFiltersProps } from '../../types'
import { errorDataMessage } from '../../utils'

const useGetLocations = (page: number, params: LocationsFiltersProps) => {
  const { data: locations, isLoading: locationsIsLoading } = useQuery(
    [QUERY_KEYS.LOCATIONS],
    () => LocationApi.getAllLocations(page, params),
    {
      onError: errorDataMessage,
    },
  )

  return { locations, locationsIsLoading }
}

export { useGetLocations }
