import styled from 'styled-components'

import { FilterIcon } from '../../assets/icons'

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.secondaryColor};
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.secondaryColor};
  border-radius: 50px;
  width: 40px;
  height: 40px;
  font-weight: 600;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.secondaryColor};

    svg > path {
      fill: ${props => props.theme.white};
    }
  }
`

interface Props {
  onClick?: () => void
}

const FilterButton = ({ onClick }: Props) => {
  return (
    <StyledButton onClick={onClick}>
      <FilterIcon />
    </StyledButton>
  )
}

export { FilterButton }
