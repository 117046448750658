import { MutationFunction, useMutation, useQuery } from 'react-query'

import { NotificationUtils } from '../../utils'

interface Props {
  queryKey: string
  mutationFn: MutationFunction<unknown, any>
  onSuccess?: () => void
  errorMessage: string
  successMessage: string
}

const useGenericMutation = ({
  queryKey,
  mutationFn,
  onSuccess,
  errorMessage,
  successMessage,
}: Props) => {
  const { refetch } = useQuery(queryKey)

  const { mutate, isSuccess, isLoading } = useMutation(mutationFn, {
    onError: data => {
      const info: any = data ? data : { response: { data: { message: errorMessage } } }
      const message =
        info?.response.data.message === 'El evento ya existe.'
          ? info?.response.data.message
          : errorMessage
      NotificationUtils.errorNotification(message)
    },
    onSuccess: () => {
      NotificationUtils.successNotification(successMessage)
      refetch()
      if (onSuccess) onSuccess()
    },
  })

  return {
    mutate,
    isSuccess,
    isLoading,
  }
}

export { useGenericMutation }
