import { ThemeProvider } from 'styled-components'
import 'antd/dist/antd.min.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import locale from 'antd/lib/locale/es_ES'
import moment from 'moment'

import { GlobalStyles, theme } from '../styles'
import Navigation from './Navigation'
import 'moment/locale/es'
import { UserSessionProvider } from '../context/UserSessionContext'

moment.locale('es-AR')

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <BrowserRouter>
      <ConfigProvider locale={locale}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            <UserSessionProvider>
              <Navigation />
            </UserSessionProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </ConfigProvider>
    </BrowserRouter>
  )
}

export default App
