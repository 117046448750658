import { useQuery } from 'react-query'

import { MedicalClearanceStatusApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetMedicalClearanceStatusCombo = () => {
  const { data: medicalClearanceStatuses } = useQuery(
    [QUERY_KEYS.MEDICAL_CLEARANCE_STATUS_COMBO],
    () => MedicalClearanceStatusApi.getMedicalClearanceStatusCombo(),
  )

  return {
    medicalClearanceStatusesCombo: (
      (medicalClearanceStatuses ?? []) as { id: number; value: string }[]
    ).map(({ id, value }) => {
      return {
        label: value,
        value: id,
      }
    }),
  }
}

export { useGetMedicalClearanceStatusCombo }
