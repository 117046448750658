import axios from 'axios'

import { config } from '../config'
import { LOCAL_STORAGE_KEYS } from '../constants'
import { setInterceptors } from '../utils'

const api = setInterceptors(
  axios.create({
    baseURL: config.baseURL,
  }),
)

api.defaults.headers.common['Content-Type'] = 'application/json'
api.defaults.headers.common.Accept = 'application/json'

export const setAuthHeader = (token: string) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_SET, 'true')
}

export const removeAuthHeader = () => {
  delete api.defaults.headers.common.Authorization
}

export { api }
