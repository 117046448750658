import { useQuery } from 'react-query'

import { LocationApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetLocationCombo = () => {
  const { data: locations } = useQuery([QUERY_KEYS.LOCATIONS_COMBO], () =>
    LocationApi.getLocationsCombo(),
  )

  return {
    locationCombo: ((locations?.data ?? []) as { id: number; value: string }[]).map(
      ({ id, value }) => ({
        label: value,
        value: id,
      }),
    ),
  }
}

export { useGetLocationCombo }
