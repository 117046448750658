import { api } from './axios'
import { ActivitiesFiltersProps } from '../types'
import { errorDataMessage, setEndpoint } from '../utils'

interface NewActivityPayload {
  name: string
  enable_unregistered: boolean
}

interface EditActivityPayload {
  id: number
  name: string
  enable_unregistered: boolean
}

interface DeleteActivityPayload {
  id: number
}
interface findEventByActivityIdPayload {
  id: number
}

const getAllActivities = async (page: number, filters: ActivitiesFiltersProps) => {
  try {
    const response = await api.get(setEndpoint(`/api/activity/read?page=${page}`, filters))
    return response.data || []
  } catch {
    errorDataMessage()
    return []
  }
}

const getActivityCombo = async () => {
  const response = await api.get('/api/activity/combo')
  return response.data
}

const createActivity = async ({ name, enable_unregistered }: NewActivityPayload) => {
  const response = await api.post('/api/activity/create', { name, enable_unregistered })
  return response.data
}

const updateActivity = async ({ id, name, enable_unregistered }: EditActivityPayload) => {
  const response = await api.post(`/api/activity/edit/${id}`, { name, enable_unregistered })
  return response.data
}

const deleteActivity = async ({ id }: DeleteActivityPayload) => {
  const response = await api.delete(`/api/activity/delete/${id}`)
  return response.data
}

const findEventByActivityId = async ({ id }: findEventByActivityIdPayload) => {
  const response = await api.get(`/api/activity/findByEvent/${id}`)
  return response.data.data
}

export const ActivityApi = {
  getAllActivities,
  getActivityCombo,
  createActivity,
  updateActivity,
  deleteActivity,
  findEventByActivityId,
}
