const size = {
  xs: '575px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}
const primaryColor = '#71C0B2'
const secondaryColor = '#163C68'
const lightPrimaryColor = '#E3EFEB'
const white = '#FCFCFC'
const lightGray = '#E7E7E7'
const darkGray = '#858585'
const fontGray = '#40444D'
const primaryFont = '#3B4652'
const requiredRed = '#ff8585'
const backgroundColor = '#e2f0eb'
const borderBottomInputColor = '#c6c6c6'

const theme = {
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
  xxl: `(max-width: ${size.xxl})`,
  primaryColor,
  secondaryColor,
  lightPrimaryColor,
  white,
  lightGray,
  darkGray,
  fontGray,
  primaryFont,
  requiredRed,
  backgroundColor,
  borderBottomInputColor,
}

module.exports = { theme }
