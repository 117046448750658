import { DatePicker } from 'antd'
import moment from 'moment'
import styled from 'styled-components'

import { dateFormat } from '../../utils'

const StyledDatePicker = styled(DatePicker)`
  border: none;
  border-bottom: 1px solid ${props => props.theme.darkGray};
  width: 100%;
  height: 55px;
  background-color: ${props => props.theme.white} !important;
`

interface Props {
  placeholder: string
  value?: string
  beforeToday?: boolean
  onChange?: (value: unknown) => void
}

const FilterDatePicker = ({ placeholder, value, beforeToday, onChange }: Props) => {
  return (
    <StyledDatePicker
      value={value as unknown as moment.Moment}
      format={dateFormat}
      placeholder={placeholder}
      disabledDate={date => (beforeToday ? date > moment() : date < moment())}
      onChange={onChange}
    />
  )
}

export { FilterDatePicker }
