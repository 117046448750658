import { useGenericMutation } from '../useGenericMutation'
import { ActivityApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useCreateActivity = (onSuccess?: () => void) => {
  const {
    mutate: createActivity,
    isSuccess: isCreateActivitySuccess,
    isLoading: isCreateActivityLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.ACTIVITIES,
    mutationFn: ActivityApi.createActivity,
    errorMessage: 'No fue posible guardar la actividad',
    successMessage: 'Actividad guardada exitosamente',
    onSuccess,
  })

  return {
    createActivity,
    isCreateActivitySuccess,
    isCreateActivityLoading,
  }
}

export { useCreateActivity }
