import { useQuery } from 'react-query'

import { ActivityApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetEventByActivityId = (id: number) => {
  const { data: eventCondition, isLoading } = useQuery(
    [QUERY_KEYS.ACTIVITY_ID_SEARCH, id],
    () => {
      return ActivityApi.findEventByActivityId({ id })
    },
    { enabled: !!id },
  )
  return { eventCondition, eventConditionIsLoading: isLoading }
}

export { useGetEventByActivityId }
