import { useGenericMutation } from '../useGenericMutation'
import { BenefitsApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useCreateBenefits = (onSuccess?: () => void) => {
  const {
    mutate: createBenefits,
    isSuccess: isCreateBenefitsSuccess,
    isLoading: isCreateBenefitsLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.BENEFITS,
    mutationFn: BenefitsApi.setBenefits,
    errorMessage: 'No fue posible crear el beneficio',
    successMessage: 'Beneficio creado exitosamente',
    onSuccess,
  })

  return {
    createBenefits,
    isCreateBenefitsSuccess,
    isCreateBenefitsLoading,
  }
}

export { useCreateBenefits }
