import { useQuery } from 'react-query'

import { CfgApi } from '../../api/configurations'
import { QUERY_KEYS } from '../../constants'
import { errorDataMessage } from '../../utils'

const useGetCfgAppData = () => {
  const { data: cfgAppData, isLoading: cfgAppDataIsLoading } = useQuery(
    [QUERY_KEYS.CFG_APP_DATA],
    CfgApi.getCfgAppData,
    {
      onError: errorDataMessage,
    },
  )

  return { cfgAppData, cfgAppDataIsLoading }
}

export { useGetCfgAppData }
