import { createContext, ReactNode, useState } from 'react'

import { User } from '../types'

type UserSessionContextDefaultValues = {
  userSession: User | null
  setUserSession: (value: User | null) => void
}

const defaultProvider: UserSessionContextDefaultValues = {
  userSession: null,
  setUserSession: () => null,
}

const UserSessionContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const UserSessionProvider = ({ children }: Props) => {
  const [userSession, setUserSession] = useState<User | null>(defaultProvider.userSession)

  const values = {
    userSession,
    setUserSession,
  }

  return <UserSessionContext.Provider value={values}>{children}</UserSessionContext.Provider>
}

export { UserSessionContext, UserSessionProvider }
