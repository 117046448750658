import { CfgType } from '../types'
import { api } from './axios'

const getCfgAppData = async () => {
  const response = await api.get('/api/field_setting')
  return response.data.data
}

const updateCfgAppData = async (payload: CfgType) => {
  const response = await api.put('/api/field_setting', payload)
  return response.data
}

export const CfgApi = {
  getCfgAppData,
  updateCfgAppData,
}
