import { useGenericMutation } from '../useGenericMutation'
import { ActionApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useEditAction = (onSuccess?: () => void) => {
  const {
    mutate: editAction,
    isSuccess: isEditActionSuccess,
    isLoading: isEditActionLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.ACTION,
    mutationFn: ActionApi.updateAction,
    errorMessage: 'No fue posible modificar la accion',
    successMessage: 'Accion modificada exitosamente',
    onSuccess,
  })

  return {
    editAction,
    isEditActionSuccess,
    isEditActionLoading,
  }
}

export { useEditAction }
