import { Row, Col, Tooltip } from 'antd'

import { ArrowAsc, ArrowDesc, SortContainer } from '../../features/Common/Components'
import { OrderBy } from '../../types'

const ColumnTitleRender = ({
  title,
  key,
  sortable,
  tooltip,
  orderBy,
  setOrderBy,
}: {
  title: string
  key: string
  sortable?: boolean
  tooltip?: boolean
  orderBy: OrderBy | undefined
  setOrderBy: React.Dispatch<React.SetStateAction<OrderBy | undefined>>
}) => {
  const SortableComponent = ({ keyAux }: { keyAux: string }) => {
    return (
      <SortContainer className="column-sort-elements" span={2}>
        <Tooltip placement="top" title="Asc">
          <ArrowAsc
            className={keyAux === orderBy?.field && orderBy?.order === 'asc' ? 'selected' : ''}
            onClick={() => {
              if (orderBy?.order !== 'asc') {
                setOrderBy({ field: key, order: 'asc' })
              }
            }}
          />
        </Tooltip>
        <Tooltip placement="bottom" title="Desc">
          <ArrowDesc
            className={keyAux === orderBy?.field && orderBy?.order === 'desc' ? 'selected' : ''}
            onClick={() => {
              if (orderBy?.order !== 'desc') {
                setOrderBy({ field: key, order: 'desc' })
              }
            }}
          />
        </Tooltip>
      </SortContainer>
    )
  }
  return (
    <Row className="column-title-row" align="middle" justify="space-between" wrap={false}>
      {sortable ? (
        <Col style={{ visibility: 'hidden' }} className="column-sort-elements" span={2}>
          <ArrowAsc />
        </Col>
      ) : (
        <div />
      )}
      {tooltip ? (
        <Tooltip title={title}>
          <Col style={{ cursor: 'help', userSelect: 'none' }}>{title}</Col>
        </Tooltip>
      ) : (
        <Col style={{ cursor: 'unset', userSelect: 'none', flex: 1 }}>{title}</Col>
      )}
      {sortable ? <SortableComponent keyAux={key} /> : <div />}
    </Row>
  )
}
ColumnTitleRender.defaultProps = {
  sortable: false,
  tooltip: false,
}

export { ColumnTitleRender }
