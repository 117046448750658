import { useQuery } from 'react-query'

import { UserApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetRolesCombo = () => {
  const { data } = useQuery([QUERY_KEYS.ROLES_COMBO], () => UserApi.getRolesCombo())

  return { roles: data ?? [] }
}

export { useGetRolesCombo }
