import { Col, Row } from 'antd'
import { ReactElement, useState } from 'react'

import { Modal } from '..'
import { PrimaryButton } from '../../PrimaryButton'
import { SearchIcon } from '../../../assets/icons'
import { OthersApi } from '../../../api'

interface ImportModalProps {
  title: string
  open: boolean
  onClose: () => void
  entity: string
  accept: string
}

const ImportModal = ({ title, open, onClose, entity, accept }: ImportModalProps) => {
  const emptyInput = `Seleccione un archivo (${accept})`
  const [fileChargeStatusMsg, setFileChargeStatusMsg] = useState<string | undefined>(undefined)
  const [isUploading, setIsUploading] = useState(false)
  const [errors, setErrors] = useState<ReactElement | undefined>(undefined)

  const handleResponse = (message: string, form: HTMLFormElement) => {
    setFileChargeStatusMsg(message)
    setIsUploading(false)
    form.reset()
  }

  const handleImport = () => {
    const form = document.querySelector('form') as HTMLFormElement
    const input = document.querySelector(`#${entity}`) as HTMLInputElement
    input.click()
    input.onchange = e => {
      const { files } = e.target as HTMLInputElement
      if (files) {
        setFileChargeStatusMsg('Espere, procesando ' + files[0].name)
        setIsUploading(true)
        const formData = new FormData()
        formData.append('file', files[0])
        OthersApi.importData(formData, entity)
          .then(res => {
            handleResponse(res.message, form)
            setErrors(undefined)
          })
          .catch(res => {
            handleResponse(res.response.data.message, form)
            setErrors(
              <Col>
                {res.response.data.errors.map((error: string) => (
                  <p style={{ whiteSpace: 'nowrap' }}>{error}</p>
                ))}
              </Col>,
            )
          })
      } else if (files === null) {
        setFileChargeStatusMsg(undefined)
        setErrors(undefined)
        setIsUploading(false)
        form.reset()
      }
    }
  }

  const handleClose = () => {
    const form = document.querySelector('form') as HTMLFormElement
    setFileChargeStatusMsg(undefined)
    setErrors(undefined)
    form.reset()
    onClose()
  }

  return (
    <Modal maxWidth="unset" width="auto" open={open} title={title} onClose={handleClose}>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'nowrap',
        }}
      >
        <Col style={{ marginRight: '1rem' }}>
          <PrimaryButton
            label="Examinar"
            disabled={isUploading}
            icon={<SearchIcon />}
            onClick={handleImport}
          />
        </Col>
        <p style={{ whiteSpace: 'nowrap' }}>{emptyInput}</p>
      </Row>
      {fileChargeStatusMsg && (
        <h2 style={{ whiteSpace: 'nowrap', marginTop: '2rem', marginBottom: '1rem' }}>
          {fileChargeStatusMsg}
        </h2>
      )}
      {errors && errors}
      <form>
        <input type="file" accept={accept} id={entity} name={entity} hidden />
      </form>
    </Modal>
  )
}

export default ImportModal
