import { notification } from 'antd'

const errorNotification = (error: string) => {
  notification.error({
    message: 'Hubo un error',
    description: error,
    duration: 3,
  })
}

const successNotification = (message: string) => {
  notification.success({
    message,
    duration: 3,
  })
}

export const NotificationUtils = {
  errorNotification,
  successNotification,
}
