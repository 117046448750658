import { useGenericMutation } from '../useGenericMutation'
import { LocationApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useDeleteLocation = (onSuccess?: () => void) => {
  const {
    mutate: deleteLocation,
    isSuccess: isDeleteLocationSuccess,
    isLoading: isDeleteLocationLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.LOCATIONS,
    mutationFn: LocationApi.deleteLocation,
    errorMessage: 'No fue posible eliminar la locación',
    successMessage: 'Locación eliminada exitosamente',
    onSuccess,
  })

  return {
    deleteLocation,
    isDeleteLocationSuccess,
    isDeleteLocationLoading,
  }
}

export { useDeleteLocation }
