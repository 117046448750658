const SSO = '/single-sign-on'

export const ROUTE_KEYS = {
  LOGIN: SSO + '/login',
  LOGOUT: SSO + '/logout',
  ROOT: '/',
  SESSION_CLEARING: '/logout',
  AUTH_ERROR: '/auth-error',
  REFRESH: SSO + '/refresh',
}
