import { useEffect, useState } from 'react'
import { Row, Col, Table, Pagination } from 'antd'
import { useQueryClient } from 'react-query'

import { EditIcon, PlusIcon, TrashIcon } from '../../assets/icons'
import {
  ActionsContainer,
  FilterInput,
  InputContainer,
  Modal,
  PrimaryButton,
  RequiredText,
} from '../../components'
import { QUERY_KEYS } from '../../constants'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import { useCreateCategory, useDeleteCategory, useEditCategory, useGetCategory } from '../../hooks'

const Category = () => {
  const [page, setPage] = useState(1)

  const [errors, setErrors] = useState(true)
  const queryClient = useQueryClient()

  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false)
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false)

  const [selectedCategoryId, setSelectedCategoryId] = useState(0)
  const [selectedCategoryName, setSelectedCategoryName] = useState('')
  const [paginator, setPaginator] = useState<number | undefined>(1)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const resetValues = () => {
    setSelectedCategoryName('')
    setSelectedCategoryId(0)
    setErrors(true)
  }

  const { category, categoryIsLoading } = useGetCategory(page)
  const { createCategory, isCreateCategoryLoading } = useCreateCategory(() => {
    setShowNewCategoryModal(false)
    resetValues()
  })

  const { editCategory, isEditCategoryLoading } = useEditCategory(() => {
    setShowEditCategoryModal(false)
    resetValues()
  })
  const { deleteCategory } = useDeleteCategory()

  const columns = [
    {
      title: 'Categoria',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Acciones',
      key: 'action',
      render: ({ id, description }: any) => (
        <ActionsContainer>
          <EditIcon
            onClick={() => {
              setSelectedCategoryId(id)
              setSelectedCategoryName(description)
              setShowEditCategoryModal(true)
            }}
          />
          <TrashIcon
            onClick={() => {
              setSelectedCategoryId(id)
              setShowDeleteConfirmation(true)
            }}
          />
        </ActionsContainer>
      ),
    },
  ]

  const inputContainer = (
    <InputContainer>
      <RequiredText>(*)Datos Obligatorios</RequiredText>
      <FilterInput
        label="Categoria"
        name="category"
        required
        value={selectedCategoryName}
        onChange={e => {
          setSelectedCategoryName(e.target.value)
        }}
      />
    </InputContainer>
  )

  useEffect(() => {
    setErrors(!selectedCategoryName.trim())
  }, [selectedCategoryName])

  useEffect(() => {
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.fetchQuery(QUERY_KEYS.CATEGORY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, paginator])

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h1>Lista de categorias</h1>
      </Col>
      <Col span={24}>
        <Row justify="end" align="middle" gutter={[12, 0]}>
          <Col>
            <PrimaryButton
              label="Nueva Categoria"
              icon={<PlusIcon />}
              onClick={() => setShowNewCategoryModal(true)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          dataSource={category?.data || []}
          columns={columns}
          pagination={false}
          rowKey="id"
          loading={categoryIsLoading}
        />
        <Pagination onChange={e => handlePage(e)} current={page} total={category?.paginate.total} />
      </Col>
      <Modal
        title="Nueva Categoria"
        open={showNewCategoryModal}
        onClose={() => {
          setShowNewCategoryModal(false)
          resetValues()
        }}
        onSave={() => {
          createCategory({
            description: selectedCategoryName,
          })
        }}
        disabledSave={errors || isCreateCategoryLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <Modal
        title="Editar Categoria"
        open={showEditCategoryModal}
        onClose={() => {
          setShowEditCategoryModal(false)
          resetValues()
        }}
        onSave={() => {
          if (!selectedCategoryId) return
          editCategory({
            id: selectedCategoryId,
            description: selectedCategoryName,
          })
        }}
        disabledSave={errors || isEditCategoryLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <DeleteModal
        title="Confirmar Eliminación"
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => {
          deleteCategory({ id: selectedCategoryId })
          setShowDeleteConfirmation(false)
        }}
        showActionButtons
      >
        ¿Estás seguro de que deseas eliminar esta categoria?
      </DeleteModal>
    </Row>
  )
}

export { Category }
