import { Row, Col, Spin } from 'antd'
import { useEffect, useState } from 'react'

import { PrimaryButton, SecundaryButton } from '../../../components'
import { CustomSwitch } from '../../../components/CustomSwitch'
import { CFG_KEYS } from '../../../constants'
import { useEditCfgAppData, useGetCfgAppData } from '../../../hooks'
import { CfgType } from '../../../types'

const AppData = () => {
  const { cfgAppData, cfgAppDataIsLoading } = useGetCfgAppData()
  const { editCfgAppData, editCfgAppDataIsLoading } = useEditCfgAppData()

  const [cfg, setCfg] = useState<CfgType | undefined>(undefined)

  const handleCfg = (key: string, value: number) => {
    setCfg({
      ...cfg!,
      [key]: !!value ? 0 : 1,
    })
  }

  const [toSave, setToSave] = useState(false)

  const handleToSave = () => {
    let cfgChanges = false
    if (cfg && cfgAppData) {
      Object.entries(cfg).forEach(item => {
        if (!cfgChanges) {
          if (cfgAppData[item[0]] !== item[1]) cfgChanges = true
        }
      })
    }
    setToSave(cfgChanges)
  }

  const handleEdit = () => {
    editCfgAppData(cfg)
  }
  const handleRestore = () => {
    setCfg(cfgAppData)
  }

  useEffect(() => {
    handleToSave()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfg])

  useEffect(() => {
    if (cfgAppData && !cfgAppDataIsLoading) {
      handleRestore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfgAppData])

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h1>Datos en la APP</h1>
        <Col className="card" style={{ marginTop: '2rem' }}>
          <h4 style={{ fontWeight: '600' }}>
            Seleccione los datos que se mostrarán en la lectura de QR de los usuarios
          </h4>
          <Spin spinning={cfgAppDataIsLoading || !cfg || editCfgAppDataIsLoading}>
            <div className="toggles-box">
              {cfg &&
                Object.entries(cfg).map(item => (
                  <CustomSwitch
                    key={item[0]}
                    name={item[0]}
                    value={item[1]}
                    title={CFG_KEYS[item[0]]}
                    handleCfg={handleCfg}
                  />
                ))}
            </div>
          </Spin>
        </Col>
        <Col span={24} style={{ marginTop: '2rem' }}>
          <Row justify="end" align="middle" gutter={[12, 0]}>
            <Col>
              <SecundaryButton label="Restablecer" disabled={!toSave} onClick={handleRestore} />
            </Col>
            <Col>
              <PrimaryButton label="Guardar" disabled={!toSave} onClick={handleEdit} />
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>
  )
}

export { AppData }
