import { api } from './axios'

interface NewCommercePayload {
  name: string
  address: string
  eventId: number
  commerceImage: string
}

interface EditCommercePayload {
  id: number
  name: string
  address: string
  eventId: number
  commerceImage: string
}

interface DeleteCommercePayload {
  id: number
}

const apiCommerce = '/api/commerce'

const setCommerce = async ({ name, address, eventId, commerceImage }: NewCommercePayload) => {
  const response = await api.post(apiCommerce, {
    name: name,
    address: address,
    eventId: eventId,
    commerceImage: commerceImage,
  })
  return response.data
}

const getCommerce = async (page: number) => {
  const response = await api.get(`${apiCommerce}?page=${page}`)
  return response.data
}

const updateCommerce = async ({
  id,
  name,
  address,
  eventId,
  commerceImage,
}: EditCommercePayload) => {
  const response = await api.put(`${apiCommerce}/${id}`, { name, address, eventId, commerceImage })
  return response.data
}

const deleteCommerce = async ({ id }: DeleteCommercePayload) => {
  const response = await api.delete(`${apiCommerce}/${id}`)
  return response.data
}

const getCommerceCombo = async () => {
  const response = await api.get(`${apiCommerce}/combo`)
  return response.data
}

export const CommerceApi = {
  setCommerce,
  getCommerce,
  updateCommerce,
  deleteCommerce,
  getCommerceCombo,
}
