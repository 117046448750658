import { useQuery } from 'react-query'

import { ActionApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetActionTypeCombo = () => {
  const { data: action } = useQuery([QUERY_KEYS.ACTION_COMBO], () => ActionApi.getActionTypeCombo())
  return {
    actionCombo: ((action ?? []) as { id: number; value: string }[]).map(({ id, value }) => ({
      value: id,
      label: value,
    })),
  }
}

export { useGetActionTypeCombo }
