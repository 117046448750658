import { useGenericMutation } from '../useGenericMutation'
import { CommerceApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useDeleteCommerce = (onSuccess?: () => void) => {
  const {
    mutate: deleteCommerce,
    isSuccess: isDeleteCommerceSuccess,
    isLoading: isDeleteCommerceLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.COMMERCE,
    mutationFn: CommerceApi.deleteCommerce,
    errorMessage: 'No fue posible eliminar el comercio',
    successMessage: 'Comercio eliminado exitosamente',
    onSuccess,
  })

  return {
    deleteCommerce,
    isDeleteCommerceSuccess,
    isDeleteCommerceLoading,
  }
}

export { useDeleteCommerce }
