import { useEffect, useState } from 'react'
import { Row, Col, Table, Pagination } from 'antd'
import { useQueryClient } from 'react-query'

import { EditIcon, PlusIcon, TrashIcon } from '../../assets/icons'
import {
  ActionsContainer,
  FilterInput,
  FilterSelect,
  InputContainer,
  Modal,
  PrimaryButton,
  RequiredText,
} from '../../components'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import {
  useDeleteAction,
  useEditAction,
  useGetAction,
  useCreateAction,
  useGetUnusedAvailableBenefitCombo,
  useGetActionTypeCombo,
  useGetEventsComboForAction,
} from '../../hooks'
import { QUERY_KEYS } from '../../constants'

const Action = () => {
  const [page, setPage] = useState(1)

  const [errors, setErrors] = useState(true)
  const queryClient = useQueryClient()

  const [showNewActionModal, setShowNewActionModal] = useState(false)
  const [showEditActionModal, setShowEditActionModal] = useState(false)

  const [selectedActionId, setSelectedActionId] = useState(0)
  const [selectedActionName, setSelectedActionName] = useState('')
  const [selectedActionDescription, setSelectedActionDescription] = useState('')
  const [selectedActionPoints, setSelectedActionPoints] = useState(0)
  const [selectedActionEvent, setSelectedActionEvent] = useState(0)
  const [selectedActionType, setSelectedActionType] = useState(0)
  const [selectedActionBenefit, setSelectedActionBenefit] = useState(0)
  const [paginator, setPaginator] = useState<number | undefined>(1)
  const [inputError, setInputError] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const resetValues = () => {
    setSelectedActionName('')
    setSelectedActionDescription('')
    setSelectedActionPoints(0)
    setSelectedActionId(0)
    setSelectedActionEvent(0)
    setSelectedActionType(0)
    setSelectedActionBenefit(0)
    setErrors(true)
    setInputError(false)
  }

  const { action, actionIsLoading } = useGetAction(page)
  const { benefitsCombo } = useGetUnusedAvailableBenefitCombo()
  const { eventsCombo } = useGetEventsComboForAction()
  const { actionCombo } = useGetActionTypeCombo()
  const { createAction, isCreateActionLoading } = useCreateAction(() => {
    setShowNewActionModal(false)
    resetValues()
  })

  const { editAction, isEditActionLoading } = useEditAction(() => {
    setShowEditActionModal(false)
  })
  const { deleteAction } = useDeleteAction()

  const columns = [
    {
      title: 'Accion',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Descripcion',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Monto de puntos',
      dataIndex: 'pointsAmount',
      key: 'pointsAmount',
    },
    {
      title: 'Evento',
      dataIndex: 'event',
      key: 'event',
      render: (data: any) => data?.name,
    },
    {
      title: 'Tipo de accion',
      dataIndex: 'actionType',
      key: 'actionType',
      render: (data: any) => data?.description,
    },
    {
      title: 'Beneficio',
      dataIndex: 'benefit',
      key: 'benefit',
      render: (benefit: any) => benefit?.title,
    },
    {
      title: 'Acciones',
      key: 'action',
      render: ({ id, action, description, pointsAmount, eventId, actionTypeId, benefit }: any) => (
        <ActionsContainer>
          <EditIcon
            onClick={() => {
              setSelectedActionId(id)
              setSelectedActionName(action)
              setSelectedActionDescription(description)
              setShowEditActionModal(true)
              setSelectedActionPoints(pointsAmount)
              setSelectedActionEvent(eventId)
              setSelectedActionType(actionTypeId)
              setSelectedActionBenefit(benefit)
            }}
          />
          <TrashIcon
            onClick={() => {
              setSelectedActionId(id)
              setShowDeleteConfirmation(true)
            }}
          />
        </ActionsContainer>
      ),
    },
  ]

  const inputContainer = (
    <InputContainer>
      <RequiredText>(*)Datos Obligatorios</RequiredText>
      <FilterInput
        label="Accion"
        name="action"
        required
        value={selectedActionName}
        onChange={e => {
          setSelectedActionName(e.target.value)
        }}
      />
      <FilterInput
        label="Descripcion"
        name="description"
        required
        value={selectedActionDescription}
        onChange={e => {
          setSelectedActionDescription(e.target.value)
        }}
      />
      <FilterSelect
        name="eventId"
        label="Evento"
        options={eventsCombo}
        value={selectedActionEvent}
        onChange={value => setSelectedActionEvent(value as number)}
      />
      <FilterSelect
        name="actionTypeId"
        label="Tipo de Accion"
        required
        options={actionCombo}
        value={selectedActionType}
        onChange={value => setSelectedActionType(value as number)}
      />
      {selectedActionType === 1 ? (
        <FilterInput
          label="Puntos Necesarios"
          name="pointsAmount"
          required
          value={selectedActionPoints.toString()}
          onChange={e => {
            const inputValue = e.target.value
            const parsedValue = Number(inputValue)
            if (!isNaN(parsedValue)) {
              setSelectedActionPoints(parsedValue)
              setInputError(false)
            } else {
              setInputError(true)
            }
          }}
        />
      ) : selectedActionType === 2 || selectedActionType === 3 ? (
        <FilterSelect
          name="benefitId"
          label="Beneficio"
          required
          options={benefitsCombo}
          value={selectedActionBenefit}
          onChange={value => setSelectedActionBenefit(value as number)}
        />
      ) : null}
      {inputError && <span style={{ color: 'red' }}>¡Este campo solo acepta numeros!</span>}
    </InputContainer>
  )

  const inputContainerEdit = (
    <InputContainer>
      <RequiredText>(*)Datos Obligatorios</RequiredText>
      <FilterInput
        label="Descripcion"
        name="description"
        required
        value={selectedActionDescription}
        onChange={e => {
          setSelectedActionDescription(e.target.value)
        }}
      />
      {selectedActionType === 1 ? (
        <FilterInput
          label="Puntos Necesarios"
          name="pointsAmount"
          required
          value={selectedActionPoints.toString()}
          onChange={e => {
            const inputValue = e.target.value
            const parsedValue = Number(inputValue)
            if (!isNaN(parsedValue)) {
              setSelectedActionPoints(parsedValue)
              setInputError(false)
            } else {
              setInputError(true)
            }
          }}
        />
      ) : null}
      {inputError && <span style={{ color: 'red' }}>¡Este campo solo acepta numeros!</span>}
    </InputContainer>
  )

  useEffect(() => {
    setErrors(
      !selectedActionName?.trim() || !selectedActionDescription?.trim() || !selectedActionType,
    )
    if (selectedActionType === 1) {
      setErrors(!selectedActionPoints)
    } else if (selectedActionType === 2 || selectedActionType === 3) {
      setErrors(!selectedActionBenefit)
    }
  }, [
    selectedActionName,
    selectedActionDescription,
    selectedActionType,
    selectedActionPoints,
    selectedActionBenefit,
  ])

  useEffect(() => {
    if (selectedActionName) setErrors(false)
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.fetchQuery(QUERY_KEYS.ACTION)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionName, page, paginator])

  useEffect(() => {
    if (showEditActionModal) {
      const selectedAction = action?.data.find(
        (item: { id: number }) => item.id === selectedActionId,
      )
      if (selectedAction) {
        setSelectedActionName(selectedAction.title)
        setSelectedActionDescription(selectedAction.description)
        setSelectedActionPoints(selectedAction.pointsAmount)
        setSelectedActionEvent(selectedAction.event?.id)
        setSelectedActionType(selectedAction.actionType?.id)
        setSelectedActionBenefit(selectedAction.benefit?.title)
      }
    }
  }, [showEditActionModal])

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h1>Lista de acciones</h1>
      </Col>
      <Col span={24}>
        <Row justify="end" align="middle" gutter={[12, 0]}>
          <Col>
            <PrimaryButton
              label="Nueva Accion"
              icon={<PlusIcon />}
              onClick={() => setShowNewActionModal(true)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          dataSource={action?.data || []}
          columns={columns}
          pagination={false}
          rowKey="id"
          loading={actionIsLoading}
        />
        <Pagination onChange={e => handlePage(e)} current={page} total={action?.paginate.total} />
      </Col>
      <Modal
        title="Nueva Accion"
        open={showNewActionModal}
        onClose={() => {
          setShowNewActionModal(false)
          resetValues()
        }}
        onSave={() => {
          if (selectedActionEvent === 0) {
            if (selectedActionType === 2 || selectedActionType === 3) {
              createAction({
                actionTypeId: selectedActionType,
                title: selectedActionName,
                description: selectedActionDescription,
                benefitId: selectedActionBenefit,
              })
            } else {
              createAction({
                actionTypeId: selectedActionType,
                title: selectedActionName,
                description: selectedActionDescription,
                pointsAmount: selectedActionPoints,
              })
            }
          } else {
            if (selectedActionType === 2 || selectedActionType === 3) {
              createAction({
                eventId: selectedActionEvent,
                actionTypeId: selectedActionType,
                title: selectedActionName,
                description: selectedActionDescription,
                benefitId: selectedActionBenefit,
              })
            } else {
              createAction({
                eventId: selectedActionEvent,
                actionTypeId: selectedActionType,
                title: selectedActionName,
                description: selectedActionDescription,
                pointsAmount: selectedActionPoints,
              })
            }
          }
        }}
        disabledSave={errors || isCreateActionLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <Modal
        title="Editar Accion"
        open={showEditActionModal}
        onClose={() => {
          setShowEditActionModal(false)
        }}
        onSave={() => {
          if (!selectedActionId) return
          editAction({
            id: selectedActionId,
            description: selectedActionDescription,
            pointsAmount: selectedActionPoints,
          })
        }}
        disabledSave={errors || isEditActionLoading}
        showActionButtons
      >
        {inputContainerEdit}
      </Modal>
      <DeleteModal
        title="Confirmar Eliminación"
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => {
          deleteAction({ id: selectedActionId })
          setShowDeleteConfirmation(false)
        }}
        showActionButtons
      >
        ¿Estás seguro de que deseas eliminar esta accion?
      </DeleteModal>
    </Row>
  )
}

export { Action }
