export const PERMISSION_KEY = {
  MENU_SEARCH_EVENT_ATTENDANCE: 'MENU_SEARCH_EVENT_ATTENDANCE',
  MENU_SEARCH_USERS: 'MENU_SEARCH_USERS',
  MENU_SEARCH_LOCATION: 'MENU_SEARCH_LOCATION',
  SEARCH_LOCATION_NEW_LOCATION: 'SEARCH_LOCATION_NEW_LOCATION',
  SEARCH_ACTIVITY_NEW_ACTIVITY: 'SEARCH_ACTIVITY_NEW_ACTIVITY',
  SEARCH_EVENT_NEW_EVENT: 'SEARCH_EVENT_NEW_EVENT',
  MENU_SEARCH_ACTIVITY: 'MENU_SEARCH_ACTIVITY',
  MENU_SEARCH_EVENT: 'MENU_SEARCH_EVENT',
  MENU_CONFIG_DATA_APP: 'MENU_CONFIG_DATA_APP',
  SEARCH_EVENT_ATTENDANCE_EXPORT_EVENT_ATTENDANCE:
    'SEARCH_EVENT_ATTENDANCE_EXPORT_EVENT_ATTENDANCE',
  SEARCH_EVENT_ATTENDANCE_IMPORT_NEIGHBOR_EVENT: 'SEARCH_EVENT_ATTENDANCE_IMPORT_NEIGHBOR_EVENT',
  SEARCH_USERS_NEW_USER: 'SEARCH_USERS_NEW_USER',
}
