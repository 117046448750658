import { useQuery } from 'react-query'

import { UserApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetNeighborByCuil = (cuil?: string) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.NEIGHBOR_CUIL_SEARCH],
    () => {
      return UserApi.getNeighborByCuil(cuil!)
    },
    { enabled: !!cuil },
  )
  return { neighbor: data ?? [], neighborIsLoading: isLoading }
}

export { useGetNeighborByCuil }
