import { useEffect, useState } from 'react'
import { Row, Col, Table, Pagination, Tooltip } from 'antd'
import { useQueryClient } from 'react-query'
import { QrcodeOutlined } from '@ant-design/icons'

import { EditIcon, EraserIcon, PlusIcon, SearchIcon, TrashIcon } from '../../assets/icons'
import {
  ActionsContainer,
  FilterButton,
  FilterSelect,
  InputContainer,
  InputNum,
  Modal,
  PrimaryButton,
  RequiredText,
  SecundaryButton,
  StyledInput,
  StyledLabel,
  Checkbox,
} from '../../components'
import {
  useCreateEvent,
  useDeleteEvent,
  useGetActivityCombo,
  useGetEvents,
  useGetLocationCombo,
  useGetPeriodicityCombo,
} from '../../hooks'
import { Event, EventsFiltersProps } from '../../types'
import { QUERY_KEYS } from '../../constants'

const Events = () => {
  const [showFilters, setShowFilters] = useState(false)
  const [page, setPage] = useState(1)
  const defaultFilters: EventsFiltersProps = {
    location: undefined,
    activity: undefined,
  }
  const [filters, setFilters] = useState<EventsFiltersProps>(defaultFilters)
  const [errors, setErrors] = useState(true)

  const [showNewEventsModal, setShowNewEventsModal] = useState(false)

  const [selectedLocationId, setSelectedLocationId] = useState<number>()
  const [selectedActivityId, setSelectedActivityId] = useState<number>()
  const [selectedEventName, setSelectedEventName] = useState<string>('')
  const [maxReadEnabled, setMaxReadEnabled] = useState<number>(1)
  const [paginator, setPaginator] = useState<number | undefined>(1)
  const [maxResult, setMaxResult] = useState<number | null>(null)

  const [editing, setEditing] = useState(false)
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>(undefined)
  const [selectedPeriodicityId, setSelectedPeriodicityId] = useState<number>()
  const [selectedRequiredMedical, setSelectedRequiredMedical] = useState<number | undefined>(
    undefined,
  )

  const queryClient = useQueryClient()

  const booleanOptions = [
    { label: 'Sí', value: 1 },
    { label: 'No', value: 0 },
  ]
  const resetValues = () => {
    setSelectedActivityId(undefined)
    setSelectedLocationId(undefined)
    setMaxReadEnabled(1)
    setSelectedEventId(0)
    setSelectedEventName('')
    setSelectedPeriodicityId(undefined)
    setSelectedRequiredMedical(undefined)
    setErrors(true)
  }

  const { activityCombo } = useGetActivityCombo()
  const { locationCombo } = useGetLocationCombo()
  const { periodicityCombo } = useGetPeriodicityCombo()

  const { events, eventsIsLoading } = useGetEvents(page, filters, maxResult)
  const { createEvent, isCreateEventLoading } = useCreateEvent(() => {
    setShowNewEventsModal(false)
    resetValues()
  })
  const { deleteEvent } = useDeleteEvent()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
    },
    {
      title: () => (
        <Tooltip title="Cantidad máxima de lecturas" placement="left">
          <QrcodeOutlined />
        </Tooltip>
      ),
      dataIndex: 'max_read_enabled',
    },
    {
      title: 'Periodicidad',
      dataIndex: 'periodicity_name',
    },
    {
      title: 'Requiere apto médico',
      key: 'required_medical',
      render: ({ required_medical }: Event) => <>{required_medical ? 'Sí' : 'No'}</>,
    },
    {
      title: 'Acciones',
      key: 'action',
      render: ({ id }: Event) => (
        <ActionsContainer>
          <EditIcon onClick={() => setSelectedEventId(id)} />
          <TrashIcon onClick={() => deleteEvent({ id })} />
        </ActionsContainer>
      ),
    },
  ]

  const inputContainer = (
    <InputContainer>
      <RequiredText>(*)Datos Obligatorios</RequiredText>
      <Row gutter={[12, 24]}>
        {editing ? (
          <Col span={24}>
            <StyledInput
              placeholder=""
              value={selectedEventName}
              disabled
              maxLength={120}
              allowClear
            />
          </Col>
        ) : (
          <>
            <Col span={24}>
              <FilterSelect
                name="location"
                label="Locación"
                required
                options={locationCombo}
                value={selectedLocationId}
                onChange={value => setSelectedLocationId(value as number)}
              />
            </Col>
            <Col span={24}>
              <FilterSelect
                name="activity"
                label="Actividad"
                required
                options={activityCombo}
                value={selectedActivityId}
                onChange={value => setSelectedActivityId(value as number)}
              />
            </Col>
          </>
        )}

        <Col span={24}>
          <label className="input-label">
            <p>
              Cantidad máxima de lecturas de QR por participante <span>*</span>
            </p>
            <InputNum
              name="maxReadEnabled"
              required
              value={maxReadEnabled}
              onChange={value => setMaxReadEnabled(value as number)}
              keyboard
              min={1}
              precision={0}
              max={1000000}
            />
          </label>
        </Col>

        <Col span={24}>
          <FilterSelect
            name="periodicity"
            label="PERIODO DE LECTURA"
            options={periodicityCombo}
            value={selectedPeriodicityId}
            onChange={value => setSelectedPeriodicityId(value as number)}
          />
        </Col>

        <Col span={24}>
          <Checkbox
            name="required_medical"
            label="¿REQUIERE APTO MÉDICO?"
            options={booleanOptions}
            value={selectedRequiredMedical}
            onChange={({ target: { value } }) => {
              setSelectedRequiredMedical(value)
            }}
            required
          />
        </Col>
      </Row>
    </InputContainer>
  )

  useEffect(() => {
    if (selectedLocationId && selectedActivityId) setErrors(false)

    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.fetchQuery(QUERY_KEYS.EVENTS)
    }

    if (selectedRequiredMedical === undefined) setErrors(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationId, selectedActivityId, page, paginator, selectedRequiredMedical])

  useEffect(() => {
    if (!!selectedEventId) {
      setShowNewEventsModal(true)
      setEditing(true)
    }

    if (selectedEventId !== 0 && events) {
      // eslint-disable-next-line array-callback-return
      events.data.map(
        (x: {
          id: number | undefined
          max_read_enabled: number
          periodicity_id: number
          location_id: number
          activity_id: number
          name: any
          required_medical: number
          // eslint-disable-next-line array-callback-return
        }) => {
          setErrors(false)

          if (x.id === selectedEventId) {
            setSelectedEventName(x.name)
            setMaxReadEnabled(x.max_read_enabled)
            setSelectedPeriodicityId(x.periodicity_id)
            setSelectedRequiredMedical(x.required_medical ? 1 : 0)
            /*setSelectedActivityId(x.activity_id)
            setSelectedLocationId(x.location_id)*/
          }
        },
      )
    }

    //resetValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEventId])

  useEffect(() => {
    if (maxResult) {
      queryClient.fetchQuery(QUERY_KEYS.EVENTS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxResult])

  useEffect(() => {
    //if (selectedLocationId && selectedActivityId) setErrors(false)

    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.fetchQuery(QUERY_KEYS.EVENTS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationId, selectedActivityId, page, paginator, maxResult])

  const handleQuery = () => {
    setPage(1)
    queryClient.fetchQuery(QUERY_KEYS.EVENTS)
  }

  const handleCleanFilters = () => {
    setPaginator(undefined)
    setFilters(defaultFilters)
    setPage(1)
  }
  const handleFilterValue = (key: string, value: number | string | undefined) => {
    setPaginator(0)
    setPage(1)
    setFilters({
      ...filters,
      [key]: value,
    })
  }

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  const keyEnter = () => {
    handleQuery()
  }

  const body = document.querySelector('body') || null
  const modal = () => {
    setEditing(false)
    setShowNewEventsModal(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    body ? (body.style.overflowY = 'hidden') : null
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnClose = () => {
    setShowNewEventsModal(false)
    resetValues()
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    body ? (body.style.overflowY = 'auto') : null
  }

  const handlePageSize = (page: number, pageSize: number) => {
    setMaxResult(pageSize)
    setPage(1)
    queryClient.resetQueries(QUERY_KEYS.VISITS)
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h1>Lista de eventos</h1>
      </Col>
      <Col span={24}>
        <Row justify="end" align="middle" gutter={[12, 0]}>
          <Col>
            <PrimaryButton label="Nuevo Evento" icon={<PlusIcon />} onClick={modal} />
          </Col>
          <Col span={1}>
            <FilterButton onClick={() => setShowFilters(prev => !prev)} />
          </Col>
        </Row>
      </Col>
      {showFilters && (
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <StyledLabel></StyledLabel>
            </Col>
            <Col span={24}>
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <FilterSelect
                    options={locationCombo}
                    value={filters.location}
                    name="location"
                    label="Locación"
                    onChange={(value: unknown) => handleFilterValue('location', value as number)}
                    keyEnter={keyEnter}
                  />
                </Col>
                <Col span={12}>
                  <FilterSelect
                    options={activityCombo}
                    value={filters.activity}
                    name="activity"
                    label="Actividad"
                    onChange={(value: unknown) => handleFilterValue('activity', value as number)}
                    keyEnter={keyEnter}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[12, 0]}>
                <Col>
                  <SecundaryButton
                    label="Limpiar"
                    icon={<EraserIcon />}
                    onClick={handleCleanFilters}
                  />
                </Col>
                <Col>
                  <PrimaryButton label="Buscar" icon={<SearchIcon />} onClick={handleQuery} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      <Col span={24}>
        <Table
          dataSource={events?.data || []}
          columns={columns}
          pagination={false}
          rowKey="id"
          loading={eventsIsLoading}
        />
        <Pagination
          onChange={e => handlePage(e)}
          current={page}
          pageSize={maxResult || 10}
          total={events?.paginate.total}
          onShowSizeChange={(page: number, pageSize: number) => handlePageSize(page, pageSize)}
        />
      </Col>
      <Modal
        title={editing ? 'Editar Evento' : 'Nuevo Evento'}
        open={showNewEventsModal}
        onClose={() => {
          handleOnClose()
        }}
        onSave={() => {
          createEvent({
            event_id: selectedEventId,
            location_id: selectedLocationId,
            activity_id: selectedActivityId,
            max_read_enabled: maxReadEnabled,
            periodicity_id: selectedPeriodicityId,
            required_medical: selectedRequiredMedical,
          })
        }}
        disabledSave={errors || isCreateEventLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
    </Row>
  )
}

export { Events }
