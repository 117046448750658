import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { OthersApi } from '../../api'
import {
  FaBarIcon,
  GridHorizontalIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  YoutubeIcon,
  LinkedinIcon,
  NewspaperIcon,
  HeadsetIcon,
  FileSignatureIcon,
} from '../../assets/icons'
import Logo from '../../assets/images/3f-logo.png'
import { Profile } from '../../types'
import { logout } from '../../utils'
import './styles.css'

interface Props {
  setIsSideMenuOpen: () => void
}

const Header = ({ setIsSideMenuOpen }: Props) => {
  const [showMegaMenu, setShowMegaMenu] = useState(false)
  const [showMyInfo, setShowMyInfo] = useState(false)
  const [userProfile, setUserProfile] = useState<Profile | undefined>(undefined)

  useEffect(() => {
    OthersApi.getProfile().then(result => setUserProfile(result))
  }, [])

  return (
    <header className="header env_header">
      <div className="page">
        <div className="bt_menu" onClick={() => setIsSideMenuOpen()}>
          <FaBarIcon />
        </div>
        <Link to="/">
          <img src={Logo} className="branding" alt="Mi3F" />
        </Link>
        <div className="env_headerGlobal">
          <div className="env_headerApps">
            <div className="bt_megamenu" onClick={() => setShowMegaMenu(prev => !prev)}>
              <GridHorizontalIcon />
            </div>
          </div>
          <div className="env_headerUser" onClick={() => setShowMyInfo(prev => !prev)}>
            <div
              className="avatar"
              id="profileImg"
              data-element="{{ app.session.get('user').profileImage }}"
            >
              <span>
                {userProfile &&
                  userProfile.name[0].toUpperCase() + userProfile.lastName[0].toUpperCase()}
              </span>
            </div>
            <div className={`env_headerUserDD ${showMyInfo ? 'visible' : ''}`}>
              <ul>
                <li>
                  <p>
                    {userProfile?.name} {userProfile?.lastName}
                    <span>
                      {userProfile?.rolesArray?.includes('backoffice')
                        ? 'backoffice'
                        : userProfile?.rolesArray?.includes('backoffice_fidelizacion')
                        ? 'backoffice_fidelizacion'
                        : userProfile?.rolesArray?.includes('mobile')
                        ? 'mobile'
                        : 'importador'}
                    </span>
                  </p>
                </li>
                <li>
                  <a href="https://signup.repositorio-desa.tresdefebrero.gov.ar/mis_datos">
                    Mis datos
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={logout}>Cerrar sesión</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="env_headerApp" id="user-set-session-route">
          <h1>Registro de visitas</h1>
        </div>
        <div className={`env_headerMegamenu ${showMegaMenu ? 'visible' : ''}`}>
          <div className="row">
            <div className="col col6">
              <h3>Mis aplicaciones</h3>
              <ul>
                <li>
                  <a href="{{ app.request.server.get('URL_MI_ACTIVIDAD') }}">
                    <NewspaperIcon /> Mi Actividad
                  </a>
                </li>
                <li>
                  <a href="{{ app.request.server.get('URL_REPORTES_Y_CONSULTAS') }}">
                    <HeadsetIcon /> Mis Reportes y Consultas
                  </a>
                </li>
                <li>
                  <a href="{{ app.request.server.get('URL_MIS_TRAMITES') }}">
                    <FileSignatureIcon /> Mis Trámites
                  </a>
                </li>
                {/* <li>
                  <a
                    href="{{ app.request.server.get('URL_CLUB_3F') }}"
                    style={{ marginLeft: '45px' }}
                  >
                    Club 3F
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="col col3">
              <h3>Utilidades</h3>
              <ul>
                <li>
                  <a href="https://guia.tresdefebrero.gov.ar/">Guía de trámites</a>
                </li>
                <li>
                  <a href="https://portalvecino.tresdefebrero.gov.ar/consulta">Boleta digital</a>
                </li>
                <li>
                  <a href="https://miboleta.tresdefebrero.gov.ar/">Suscripción a boleta digital</a>
                </li>
              </ul>
            </div>
            <div className="col col3">
              <h3>Sitio institucional</h3>
              <ul>
                <li>
                  <a href="https://www.tresdefebrero.gov.ar/">Inicio</a>
                </li>
                <li>
                  <a href="https://www.tresdefebrero.gov.ar/noticias/">Noticias</a>
                </li>
                <li>
                  <a href="https://www.tresdefebrero.gov.ar/agenda/">Agenda</a>
                </li>
              </ul>
              <ul className="social">
                <li>
                  <a href="https://www.facebook.com/MunicipalidadTresdeFebrero">
                    <FacebookIcon />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/Municipalidad3f/">
                    <InstagramIcon />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/municipalidad3f">
                    <TwitterIcon />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCtC87pol4_f0FZPoQz1GFwA">
                    <YoutubeIcon />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/municipalidad3f/">
                    <LinkedinIcon />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export { Header }
