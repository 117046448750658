import { useQuery } from 'react-query'

import { CategoryApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { errorDataMessage } from '../../utils'

const useGetCategory = (page: number) => {
  const { data: category, isLoading: categoryIsLoading } = useQuery(
    [QUERY_KEYS.CATEGORY],
    () => CategoryApi.getCategory(page),
    {
      onError: errorDataMessage,
    },
  )

  return { category, categoryIsLoading }
}

export { useGetCategory }
