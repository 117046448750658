import { useQuery } from 'react-query'

import { VisitApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { OrderBy, VisitsFiltersProps } from '../../types'
import { errorDataMessage } from '../../utils'

const useGetVisits = (
  page: number,
  params: VisitsFiltersProps,
  maxResult: number | null,
  orderBy: OrderBy | undefined,
) => {
  const { data: visits, isLoading: visitsIsLoading } = useQuery(
    [QUERY_KEYS.VISITS, page, params, maxResult, orderBy],
    () =>
      VisitApi.getAllVisits(
        page,
        params,
        maxResult,
        orderBy?.order !== undefined ? orderBy.order : null,
        orderBy?.field !== undefined ? orderBy.field : null,
      ),
    {
      onError: () => {
        errorDataMessage()
      },
    },
  )

  return { visits, visitsIsLoading }
}

export { useGetVisits }
