import { useGenericMutation } from '../useGenericMutation'
import { BenefitsApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useEditBenefits = (onSuccess?: () => void) => {
  const {
    mutate: editBenefits,
    isSuccess: isEditBenefitsSuccess,
    isLoading: isEditBenefitsLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.COMMERCE,
    mutationFn: BenefitsApi.updateBenefits,
    errorMessage: 'No fue posible modificar el beneficio',
    successMessage: 'Beneficio modificado exitosamente',
    onSuccess,
  })

  return {
    editBenefits,
    isEditBenefitsSuccess,
    isEditBenefitsLoading,
  }
}

export { useEditBenefits }
