import { api } from './axios'
import { LocationsFiltersProps } from '../types'
import { errorDataMessage, setEndpoint } from '../utils'

interface NewLocationPayload {
  name: string
}

interface EditLocationPayload {
  id: number
  name: string
}

interface DeleteLocationPayload {
  id: number
}
interface findEventByLocationIdPayload {
  id: number
}

const getAllLocations = async (page: number, filters: LocationsFiltersProps) => {
  try {
    const response = await api.get(setEndpoint(`/api/location/read?page=${page}`, filters))
    return response.data || []
  } catch {
    errorDataMessage()
    return []
  }
}

const getLocationsCombo = async () => {
  const response = await api.get('/api/location/combo')
  return response.data
}

const createLocation = async ({ name }: NewLocationPayload) => {
  const response = await api.post('/api/location/create', { name })
  return response.data
}

const updateLocation = async ({ id, name }: EditLocationPayload) => {
  const response = await api.post(`/api/location/edit/${id}`, { name })
  return response.data
}

const deleteLocation = async ({ id }: DeleteLocationPayload) => {
  const response = await api.delete(`/api/location/delete/${id}`)
  return response.data
}

const findEventByLocationId = async ({ id }: findEventByLocationIdPayload) => {
  const response = await api.get(`/api/location/findByEvent/${id}`)
  return response.data.data
}

export const LocationApi = {
  getAllLocations,
  getLocationsCombo,
  createLocation,
  updateLocation,
  deleteLocation,
  findEventByLocationId,
}
