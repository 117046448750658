import { createGlobalStyle } from 'styled-components'

import { theme } from './theme'

const GlobalStyles = createGlobalStyle`

  .ant-pagination-item:hover,
  .ant-input:hover,
  .ant-input:focus,
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: ${theme.primaryColor};
    outline: 0;
  }

  ::selection {
    color: ${theme.primaryColor};
  }

  p, h1, h2, h3 {
    margin: 0;
  }
  ul {
    list-style: none;
  }
  h1 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    color: ${theme.secondaryColor};
  }


  body {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 100%;
    color: ${theme.primaryFont};
    background: ${theme.white};
  }

  #root {
    min-height: 100%;
    display: contents;
  }

  #root > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  footer {
    flex-shrink: 0;
  }

  :focus {
    box-shadow: 0 0 2px 0 ${theme.primaryColor};
  }

  button:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
    border-color: rgba(239, 239, 239, 0.3);
    cursor: not-allowed;
  }

  button:disabled:hover {
    border-color: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
    cursor: not-allowed;
  }

  // Override table styles
  .ant-table-thead > tr > th { border: none; text-align: left; }

  .ant-table-thead > tr > th {  color: ${theme.secondaryColor}; background-color: ${theme.lightPrimaryColor}; font-weight: 600; padding: 15px; text-transform: uppercase; font-size: 14px; white-space: nowrap; }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before { display: none}

  .ant-table-tbody > tr > td { color: ${theme.fontGray}; font-weight: 400; padding: 10px 5px; border-bottom: 1px solid ${theme.lightGray}; white-space: nowrap; font-size: 13px;}

  .ant-table table {
    border-spacing: 0;
  }

  .ant-table-wrapper {
    overflow-x: scroll;
  }

  .ant-pagination {
    margin: 15px 0;
    width: 100%;
    text-align: center;
  }

  .closeSelect .ant-select-clear {
    top: -10px !important;
    right: -1px !important;
  }

  .row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  }

  .col {
    font-size: 1rem;
    box-sizing: border-box;
    padding: 0 1rem;
    min-height: 1px;
    text-align: left;
    direction: ltr !important;
  }

  .col6 {
    grid-column: span 6;
  }

  .col3 {
    grid-column: span 3;
  }

  a,
  li {
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    text-decoration: none;
    color: inherit;
    background-color: transparent;
    color: ${theme.secondaryColor};
  }

  a:hover {
    color: ${theme.secondaryColor};
  }

  ol,
  ul {
    list-style: none;
  }

  // Override scrollbar
  ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    height: 8px;
    width: 8px;
  }
  ::-webkit-scrollbar-track-piece {
    height: 8px;
    width: 8px;
    background: ${theme.white};
    opacity: 0.2;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${theme.primaryColor};
  }
  .aside {
    transition: all 400ms cubic-bezier(.7,0,.3,1);
    white-space: nowrap;
    overflow: hidden;
    ul {
      background: ${theme.backgroundColor};
      border-radius: 0.25rem;

      li {
        + li {
          border-top: solid 1px #fafafa;
        }
      }
      .displayable-submenu {
        display: block
      }
      .menu-option, .displayer, .submenu-option {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: .875rem;
        line-height: 1rem;
        padding: 1rem;
        overflow: hidden;
        user-select: none;
      }
      .menu-option, .displayer {
        font-weight: 600;
      }
      .submenu-option {
        font-weight: 400;
        padding-left: 1.5rem;
      }
      .submenu {
        border-top: solid 1px #FAFAFA;
        height: 0;
        overflow-y: hidden;
        overflow-x: hidden;
        transition: all ease 300ms;
        background: #D2DFDB !important;
        opacity: 0;
      }
      .submenu.displayed {
        transform: none;
        opacity: 1;
      }
      .icon-container {
        display: flex;
        justify-content: flex;
        align-items: center;
        width: 1.5rem;
        height: 1.05rem;
        text-align: center;
        svg {
          height: 120%;
          margin-right: 0.35rem;
          path {
            color: #71C1B1;
            fill: #71C1B1;
          }
        }
      }
      .selected-menu-option {
        background: #FFFFFF80;
      }
    }
  }
  .card {
    background: #fff;
    padding: 2rem 2.125rem;
    border-radius: .25rem;
    border-bottom: solid .25rem #71C1B1;
    box-shadow: 0 .1rem .5rem 0 rgba(0,0,0,.1), 0 .2rem 1rem 0 rgba(0,0,0,.1);
  }
  .toggles-box {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
  }
  .input-label p {
    color: ${theme.darkGray};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    span {
      color: ${theme.requiredRed};
    }
  }
`
export { GlobalStyles }
