import { useQuery } from 'react-query'

import { EventApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetEventsCombo = () => {
  const { data } = useQuery([QUERY_KEYS.EVENTS_COMBO], () => EventApi.getEventsCombo())

  return { events: data ?? [] }
}

export { useGetEventsCombo }
