import { useGenericMutation } from '../useGenericMutation'
import { BenefitsApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useDeleteBenefits = (onSuccess?: () => void) => {
  const {
    mutate: deleteBenefits,
    isSuccess: isDeleteBenefitsSuccess,
    isLoading: isDeleteBenefitsLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.BENEFITS,
    mutationFn: BenefitsApi.deleteBenefits,
    errorMessage: 'No fue posible eliminar el beneficio',
    successMessage: 'Beneficio eliminado exitosamente',
    onSuccess,
  })

  return {
    deleteBenefits,
    isDeleteBenefitsSuccess,
    isDeleteBenefitsLoading,
  }
}

export { useDeleteBenefits }
