import { useQuery } from 'react-query'

import { PeriodicityApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetPeriodicityCombo = () => {
  const { data: periodicity } = useQuery([QUERY_KEYS.PERIODICITY_COMBO], () =>
    PeriodicityApi.getPeriodicityCombo(),
  )

  return {
    periodicityCombo: ((periodicity?.data ?? []) as { id: number; value: string }[]).map(
      ({ id, value }) => ({
        label: value,
        value: id,
      }),
    ),
  }
}

export { useGetPeriodicityCombo }
