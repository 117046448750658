import { useQuery } from 'react-query'

import { ActivityApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetActivityCombo = () => {
  const { data: activities } = useQuery([QUERY_KEYS.ACTIVITIES_COMBO], () =>
    ActivityApi.getActivityCombo(),
  )

  return {
    activityCombo: ((activities?.data ?? []) as { id: number; value: string }[]).map(
      ({ id, value }) => ({
        label: value,
        value: id,
      }),
    ),
  }
}

export { useGetActivityCombo }
