import { useGenericMutation } from '../useGenericMutation'
import { CommerceApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useCreateCommerce = (onSuccess?: () => void) => {
  const {
    mutate: createCommerce,
    isSuccess: isCreateCommerceSuccess,
    isLoading: isCreateCommerceLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.COMMERCE,
    mutationFn: CommerceApi.setCommerce,
    errorMessage: 'No fue posible crear el comercio',
    successMessage: 'Comercio creado exitosamente',
    onSuccess,
  })

  return {
    createCommerce,
    isCreateCommerceSuccess,
    isCreateCommerceLoading,
  }
}

export { useCreateCommerce }
