import { useQuery } from 'react-query'

import { UserApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { Combo } from '../../types'

const useGetUsersCombo = () => {
  const { data } = useQuery([QUERY_KEYS.USERS_COMBO], () => UserApi.getUsersCombo())

  return {
    usersCombo: ((data?.data ?? []) as Combo[]).map(({ id, value }) => ({
      label: value,
      value: id,
    })),
  }
}

export { useGetUsersCombo }
