import {
  Mi3FLogoIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  YoutubeIcon,
  LinkedinIcon,
} from '../../assets/icons'
import './styles.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="page">
        <Mi3FLogoIcon className="logo" />
        <ul className="social">
          <li>
            <a href="https://www.facebook.com/MunicipalidadTresdeFebrero">
              <FacebookIcon />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/Municipalidad3f/">
              <InstagramIcon />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/municipalidad3f">
              <TwitterIcon />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCtC87pol4_f0FZPoQz1GFwA">
              <YoutubeIcon />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/municipalidad3f/">
              <LinkedinIcon />
            </a>
          </li>
        </ul>
        <p>
          © Copyright - Municipalidad de Tres de Febrero{' '}
          <a href="/">Términos y condiciones de uso</a>
        </p>
      </div>
    </footer>
  )
}

export { Footer }
