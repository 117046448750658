import { useQuery } from 'react-query'

import { CommerceApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { errorDataMessage } from '../../utils'

const useGetCommerce = (page: number) => {
  const {
    data: commerce,
    isLoading: commerceIsLoading,
    isFetching: commerceFetching,
  } = useQuery([QUERY_KEYS.COMMERCE], () => CommerceApi.getCommerce(page), {
    onError: errorDataMessage,
  })
  return { commerce, commerceIsLoading, commerceFetching }
}

export { useGetCommerce }
