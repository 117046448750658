import { useGenericMutation } from '../useGenericMutation'
import { NeighborEventsApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useEditNeighborEvent = (onSuccess?: () => void) => {
  const {
    mutate: editNeighborEvent,
    isSuccess: isEditNeighborEventSuccess,
    isLoading: isEditNeighborEventLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.NEIGHBOR_EVENTS,
    mutationFn: NeighborEventsApi.updateNeigborEvent,
    errorMessage: 'No fue posible modificar el evento',
    successMessage: 'Evento modificado exitosamente',
    onSuccess,
  })

  return {
    editNeighborEvent,
    isEditNeighborEventSuccess: isEditNeighborEventSuccess,
    isEditNeighborEventLoading: isEditNeighborEventLoading,
  }
}

export { useEditNeighborEvent }
