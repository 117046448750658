import { notification } from 'antd'

import {
  ActivitiesFiltersProps,
  EventsFiltersProps,
  LocationsFiltersProps,
  NeighborEventsFiltersProps,
  UsersFiltersProps,
  VisitsFiltersProps,
} from '../types'

const requiredMark = () => <span style={{ marginLeft: '0.125rem' }}>*</span>

const setEndpoint = (
  endpoint: string,
  filters:
    | UsersFiltersProps
    | VisitsFiltersProps
    | EventsFiltersProps
    | LocationsFiltersProps
    | ActivitiesFiltersProps
    | NeighborEventsFiltersProps,
) => {
  let endpointAux = endpoint
  Object.entries(filters).forEach(([key, value]) => {
    if (value) endpointAux += `&filter[${key}]=${value}`
  })
  return endpointAux
}

const dateFormat = 'DD-MM-YYYY'

const errorDataMessage = () => {
  notification.close('dataError')
  notification.error({
    message: 'Hubo un error al solicitar la información requerida',
    key: 'dataError',
  })
}

const isSuccess = (status: string | number | boolean) => {
  if (typeof status === 'boolean') {
    return status
  }
  const statusValue = typeof status === 'string' ? parseInt(status) : status
  return statusValue >= 200 && statusValue < 300 ? true : false
}

export { requiredMark, setEndpoint, dateFormat, errorDataMessage, isSuccess }
