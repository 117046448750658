import { api } from './axios'

const getMedicalClearanceStatusCombo = async () => {
  const response = await api.get('/api/medical-clearance-status/combo')
  return response.data.data
}

export const MedicalClearanceStatusApi = {
  getMedicalClearanceStatusCombo,
}
