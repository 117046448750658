import { useEffect, useState } from 'react'
import { Row, Col, Table, Pagination, Checkbox, DatePicker, Select } from 'antd'
import { useQueryClient } from 'react-query'
import moment, { Moment } from 'moment'

import { EditIcon, PlusIcon, TrashIcon } from '../../assets/icons'
import {
  ActionsContainer,
  FilterInput,
  FilterSelect,
  InputContainer,
  Modal,
  PrimaryButton,
  RequiredText,
} from '../../components'
import {
  useDeleteBenefits,
  useEditBenefits,
  useGetBenefits,
  useCreateBenefits,
  useGetLevelsCombo,
  useGetCommerceCombo,
  useGetCategoryCombo,
} from '../../hooks'
import { QUERY_KEYS } from '../../constants'
import { DeleteModal } from '../../components/Modal/DeleteModal'

const Benefits = () => {
  const [page, setPage] = useState(1)

  const [errors, setErrors] = useState(true)
  const queryClient = useQueryClient()

  const [showNewBenefitsModal, setShowNewBenefitsModal] = useState(false)
  const [showEditBenefitsModal, setShowEditBenefitsModal] = useState(false)

  const [selectedBenefitsId, setSelectedBenefitsId] = useState(0)
  const [selectedBenefitsName, setSelectedBenefitsName] = useState('')
  const [selectedBenefitsDescription, setSelectedBenefitsDescription] = useState('')
  const [selectedBenefitsExpiresAt, setSelectedBenefitsExpiresAt] = useState<Moment | string>('')
  const [selectedBenefitsIsActive, setSelectedBenefitsIsActive] = useState(false)
  const [selectedBenefitsIsGroupType, setSelectedBenefitsIsGroupType] = useState(false)
  const [selectedBenefitsIsActionBenefit, setSelectedBenefitsIsActionBenefit] = useState(false)
  const [selectedBenefitMaxStock, setSelectedBenefitsMaxStock] = useState(0)
  const [selectedBenefitMaxUsesNeighbor, setSelectedBenefitsMaxUsesNeighbor] = useState(0)
  const [selectedLevelsId, setSelectedLevelsId] = useState<number>()
  const [selectedCommerceId, setSelectedCommerceId] = useState<number>()
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([])

  const [inputError, setInputError] = useState(false)
  const [paginator, setPaginator] = useState<number | undefined>(1)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const resetValues = () => {
    setSelectedBenefitsName('')
    setSelectedBenefitsId(0)
    setErrors(true)
    setSelectedBenefitsDescription('')
    setSelectedBenefitsExpiresAt('')
    setSelectedBenefitsIsActive(false)
    setSelectedBenefitsMaxStock(0)
    setSelectedBenefitsMaxUsesNeighbor(0)
    setSelectedBenefitsIsGroupType(false)
    setSelectedBenefitsIsActionBenefit(false)
    setSelectedCommerceId(0)
    setSelectedLevelsId(0)
    setSelectedCategoryIds([])
  }

  const { benefits, benefitsIsLoading } = useGetBenefits(page)
  const { createBenefits, isCreateBenefitsLoading } = useCreateBenefits(() => {
    setShowNewBenefitsModal(false)
    resetValues()
  })

  const { editBenefits, isEditBenefitsLoading } = useEditBenefits(() => {
    setShowEditBenefitsModal(false)
    resetValues()
  })
  const { deleteBenefits } = useDeleteBenefits()
  const { levelsCombo } = useGetLevelsCombo()
  const { commerceCombo } = useGetCommerceCombo()
  const { categoryCombo } = useGetCategoryCombo()

  const handleDateChange = (date: any, dateString: string) => {
    setSelectedBenefitsExpiresAt(moment(`${dateString}T00:00:00-03:00`))
  }

  const columns = [
    {
      title: 'Beneficios',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Descripcion',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Expira',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      render: (expiresAt: string) => {
        const date = new Date(expiresAt)
        const formattedDate = date.toLocaleDateString('es-ES')
        return <span>{formattedDate}</span>
      },
    },
    {
      title: 'Activo',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => <Checkbox checked={isActive} />,
    },
    {
      title: 'Categoria',
      dataIndex: 'categories',
      key: 'categories',
      render: (categories: any[]) => {
        if (categories && categories.length > 0) {
          return (
            <span>
              {categories.map(element => (
                <span key={element.id}> {element.description}</span>
              ))}
            </span>
          )
        }
        return null
      },
    },
    {
      title: 'Max Stock',
      dataIndex: 'maxStock',
      key: 'maxStock',
    },
    {
      title: 'Max Usos Vecino',
      dataIndex: 'maxUsesPerNeighbor',
      key: 'maxUsesPerNeighbor',
    },
    {
      title: 'Nivel',
      dataIndex: 'levels',
      key: 'levels',
      render: (levels: any[]) => {
        if (levels && levels.length > 0) {
          return <span>{levels[0].title}</span>
        }
        return null
      },
    },
    {
      title: 'Comercio',
      dataIndex: 'commerce',
      key: 'commerce',
      render: (commerce: any) => {
        if (commerce && commerce.name) {
          return <span>{commerce.name}</span>
        }
        return null
      },
    },
    {
      title: 'Acciones',
      key: 'action',
      render: ({
        id,
        title,
        description,
        expiresAt,
        isActive,
        category,
        maxStock,
        maxUsesPerNeighbor,
        isGroupType,
        unlockableByAction,
        levelId,
        commerceId,
      }: any) => (
        <ActionsContainer>
          <EditIcon
            onClick={() => {
              setSelectedBenefitsId(id)
              setSelectedBenefitsName(title)
              setSelectedBenefitsDescription(description)
              setShowEditBenefitsModal(true)
              setSelectedBenefitsExpiresAt(moment(expiresAt))
              setSelectedBenefitsIsActive(isActive)
              setSelectedCategoryIds(category)
              setSelectedBenefitsMaxStock(maxStock)
              setSelectedBenefitsMaxUsesNeighbor(maxUsesPerNeighbor)
              setSelectedBenefitsIsGroupType(isGroupType)
              setSelectedBenefitsIsActionBenefit(unlockableByAction)
              setSelectedCommerceId(commerceId)
              setSelectedLevelsId(levelId)
            }}
          />
          <TrashIcon
            onClick={() => {
              setSelectedBenefitsId(id)
              setShowDeleteConfirmation(true)
            }}
          />
        </ActionsContainer>
      ),
    },
  ]

  const inputContainer = (
    <InputContainer>
      <RequiredText style={{ marginBottom: '-12px' }}>(*)Datos Obligatorios</RequiredText>
      <FilterInput
        label="Beneficios"
        name="title"
        required
        value={selectedBenefitsName}
        onChange={e => {
          setSelectedBenefitsName(e.target.value)
        }}
      />
      <FilterInput
        label="Descripcion"
        name="description"
        required
        value={selectedBenefitsDescription}
        onChange={e => {
          setSelectedBenefitsDescription(e.target.value)
        }}
      />
      <p style={{ fontSize: '15px', fontWeight: '600', color: '#858585' }}>
        EXPIRA <span style={{ color: '#ff8585' }}>*</span>
      </p>
      <DatePicker name="expiresAt" format="YYYY-MM-DD" onChange={handleDateChange} />
      <Row>
        <div>
          Beneficio Activo
          <Checkbox
            checked={selectedBenefitsIsActive}
            onChange={e => {
              setSelectedBenefitsIsActive(e.target.checked)
            }}
          />
        </div>
        <div>
          Accion Beneficio
          <Checkbox
            checked={selectedBenefitsIsActionBenefit}
            onChange={e => {
              setSelectedBenefitsIsActionBenefit(e.target.checked)
            }}
          />
        </div>
        {selectedBenefitsIsActionBenefit ? (
          <span></span>
        ) : (
          <div>
            Pertenece al grupo
            <Checkbox
              checked={selectedBenefitsIsGroupType}
              onChange={e => {
                setSelectedBenefitsIsGroupType(e.target.checked)
              }}
            />
          </div>
        )}
      </Row>
      <p style={{ fontSize: '15px', fontWeight: '600', color: '#858585' }}>CATEGORIA</p>
      <Select
        mode="multiple"
        placeholder="Seleccionar"
        options={categoryCombo}
        value={selectedCategoryIds}
        onChange={values => setSelectedCategoryIds(values)}
        style={{ width: '100%' }}
      />
      <FilterInput
        label="Max Stock"
        name="maxStock"
        required
        value={selectedBenefitMaxStock.toString()}
        onChange={e => {
          const inputValue = e.target.value
          const parsedValue = Number(inputValue)
          if (!isNaN(parsedValue)) {
            setSelectedBenefitsMaxStock(parsedValue)
            setInputError(false)
          } else {
            setInputError(true)
          }
        }}
      />
      <FilterInput
        label="Max Usos Por Usuario"
        name="maxUsesPerNeighbor"
        required
        value={selectedBenefitMaxUsesNeighbor.toString()}
        onChange={e => {
          const inputValue = e.target.value
          const parsedValue = Number(inputValue)
          if (!isNaN(parsedValue)) {
            setSelectedBenefitsMaxUsesNeighbor(parsedValue)
            setInputError(false)
          } else {
            setInputError(true)
          }
        }}
      />
      <FilterSelect
        name="commerceId"
        label="Comercios"
        options={commerceCombo}
        value={selectedCommerceId}
        onChange={value => setSelectedCommerceId(value as number)}
      />
      {selectedBenefitsIsActionBenefit ? (
        <span></span>
      ) : (
        <FilterSelect
          name="levelsId"
          label="Nivel"
          options={levelsCombo}
          value={selectedLevelsId}
          onChange={value => setSelectedLevelsId(value as number)}
        />
      )}
      {inputError && <span style={{ color: 'red' }}>¡Este campo solo acepta numeros!</span>}
    </InputContainer>
  )

  const inputContainerEdit = (
    <InputContainer>
      <RequiredText>(*)Datos Obligatorios</RequiredText>
      <FilterInput
        label="Beneficios"
        name="title"
        required
        value={selectedBenefitsName}
        onChange={e => {
          setSelectedBenefitsName(e.target.value)
        }}
      />
      <FilterInput
        label="Descripcion"
        name="description"
        required
        value={selectedBenefitsDescription}
        onChange={e => {
          setSelectedBenefitsDescription(e.target.value)
        }}
      />
      Expira
      <DatePicker
        name="expiresAt"
        format="YYYY-MM-DD"
        value={selectedBenefitsExpiresAt ? moment(selectedBenefitsExpiresAt) : null}
        onChange={handleDateChange}
      />
      <Row>
        <div>
          Beneficio Activo
          <Checkbox
            checked={selectedBenefitsIsActive}
            onChange={e => {
              setSelectedBenefitsIsActive(e.target.checked)
            }}
          />
        </div>
      </Row>
      <FilterInput
        label="Max Stock"
        name="maxStock"
        required
        value={selectedBenefitMaxStock.toString()}
        onChange={e => {
          const inputValue = e.target.value
          const parsedValue = Number(inputValue)
          if (!isNaN(parsedValue)) {
            setSelectedBenefitsMaxStock(parsedValue)
            setInputError(false)
          } else {
            setInputError(true)
          }
        }}
      />
      <FilterInput
        label="Max Usos Por Usuario"
        name="maxUsesPerNeighbor"
        required
        value={selectedBenefitMaxUsesNeighbor.toString()}
        onChange={e => {
          const inputValue = e.target.value
          const parsedValue = Number(inputValue)
          if (!isNaN(parsedValue)) {
            setSelectedBenefitsMaxUsesNeighbor(parsedValue)
            setInputError(false)
          } else {
            setInputError(true)
          }
        }}
      />
      {inputError && <span style={{ color: 'red' }}>¡Este campo solo acepta numeros!</span>}
    </InputContainer>
  )

  useEffect(() => {
    setErrors(
      !selectedBenefitsName.trim() ||
        !selectedBenefitsDescription.trim() ||
        !selectedBenefitsExpiresAt ||
        !selectedBenefitMaxStock ||
        !selectedBenefitMaxUsesNeighbor,
    )
  }, [
    selectedBenefitsName,
    selectedBenefitsDescription,
    selectedBenefitsExpiresAt,
    selectedBenefitMaxStock,
    selectedBenefitMaxUsesNeighbor,
  ])

  useEffect(() => {
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.fetchQuery(QUERY_KEYS.BENEFITS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBenefitsName, page, paginator])

  useEffect(() => {
    if (showEditBenefitsModal) {
      const selectedBenefit = benefits?.data.find(
        (item: { id: number }) => item.id === selectedBenefitsId,
      )
      if (selectedBenefit) {
        setSelectedBenefitsName(selectedBenefit.title)
        setSelectedBenefitsDescription(selectedBenefit.description)
        setSelectedBenefitsExpiresAt(selectedBenefit.expiresAt)
        setSelectedBenefitsIsActive(selectedBenefit.isActive)
        setSelectedCategoryIds(selectedBenefit.categoryID)
        setSelectedBenefitsMaxStock(selectedBenefit.maxStock)
        setSelectedBenefitsMaxUsesNeighbor(selectedBenefit.maxUsesPerNeighbor)
        setSelectedBenefitsIsGroupType(selectedBenefit.isGroupType)
        setSelectedBenefitsIsActionBenefit(selectedBenefit.unlockableByAction)
        setSelectedCommerceId(selectedBenefit.commerceId)
        setSelectedLevelsId(selectedBenefit.levelId)
      }
    }
  }, [showEditBenefitsModal])

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h1>Lista de beneficios</h1>
      </Col>
      <Col span={24}>
        <Row justify="end" align="middle" gutter={[12, 0]}>
          <Col>
            <PrimaryButton
              label="Nuevo Beneficio"
              icon={<PlusIcon />}
              onClick={() => {
                setShowNewBenefitsModal(true)
                resetValues()
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          dataSource={benefits?.data || []}
          columns={columns}
          pagination={false}
          rowKey="id"
          loading={benefitsIsLoading}
        />
        <Pagination onChange={e => handlePage(e)} current={page} total={benefits?.paginate.total} />
      </Col>
      <Modal
        title="Nuevo Beneficio"
        open={showNewBenefitsModal}
        onClose={() => {
          setShowNewBenefitsModal(false)
          resetValues()
        }}
        onSave={() => {
          createBenefits({
            title: selectedBenefitsName,
            commerceId: selectedCommerceId,
            description: selectedBenefitsDescription,
            expiresAt: selectedBenefitsExpiresAt,
            isActive: selectedBenefitsIsActive,
            unlockableByAction: selectedBenefitsIsActionBenefit,
            actionId: 1,
            levelId: selectedLevelsId,
            isGroupType: selectedBenefitsIsGroupType,
            categoryIds: selectedCategoryIds,
            maxStock: selectedBenefitMaxStock,
            maxUsesPerNeighbor: selectedBenefitMaxUsesNeighbor,
          })
        }}
        disabledSave={errors || isCreateBenefitsLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <Modal
        title="Editar Beneficio"
        open={showEditBenefitsModal}
        onClose={() => {
          setShowEditBenefitsModal(false)
          resetValues()
        }}
        onSave={() => {
          if (!selectedBenefitsId) return
          editBenefits({
            id: selectedBenefitsId,
            title: selectedBenefitsName,
            expiresAt: selectedBenefitsExpiresAt,
            description: selectedBenefitsDescription,
            isActive: selectedBenefitsIsActive,
            category: selectedCategoryIds,
            maxStock: selectedBenefitMaxStock,
            maxUsesPerNeighbor: selectedBenefitMaxUsesNeighbor,
          })
        }}
        disabledSave={errors || isEditBenefitsLoading}
        showActionButtons
      >
        {inputContainerEdit}
      </Modal>
      <DeleteModal
        title="Confirmar Eliminación"
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => {
          deleteBenefits({ id: selectedBenefitsId })
          setShowDeleteConfirmation(false)
        }}
        showActionButtons
      >
        ¿Estás seguro de que deseas eliminar este beneficio?
      </DeleteModal>
    </Row>
  )
}

export { Benefits }
