import { useGenericMutation } from '../useGenericMutation'
import { CommerceApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useEditCommerce = (onSuccess?: () => void) => {
  const {
    mutate: editCommerce,
    isSuccess: isEditCommerceSuccess,
    isLoading: isEditCommerceLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.COMMERCE,
    mutationFn: CommerceApi.updateCommerce,
    errorMessage: 'No fue posible modificar el comercio',
    successMessage: 'Comercio modificado exitosamente',
    onSuccess,
  })

  return {
    editCommerce,
    isEditCommerceSuccess,
    isEditCommerceLoading,
  }
}

export { useEditCommerce }
