import { logout } from '../../utils'
import { Footer } from '../Footer'

const location = new URLSearchParams(window.location.search)
let typeError = ''
if (location.get('type') === 'LOCAL_CUIL_NOT_FOUND' || location.get('type') === 'ROLE_NOT_FOUND') {
  typeError = 'El usuario ingresado no tiene permisos.'
} else {
  typeError =
    location.get('type') === 'SERVER_CUIL_NOT_FOUND'
      ? 'El usuario ingresado no se encuentra registrado.'
      : 'Ocurrió un error inesperado. Por favor, intente nuevamente.'
}

const Page404 = () => {
  return (
    <div>
      <br />
      <br />
      <p style={{ textAlign: 'center' }}> Server error: {typeError} </p>
      <h2 style={{ textAlign: 'center' }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={logout}> Volver a Inicio</a>
      </h2>
      <br />
      <br />
      <Footer />
    </div>
  )
}

export { Page404 }
