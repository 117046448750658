import { useQuery } from 'react-query'

import { LocationApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetEventByLocationId = (id: number) => {
  const { data: eventCondition, isLoading } = useQuery(
    [QUERY_KEYS.LOCATION_ID_SEARCH, id],
    () => {
      return LocationApi.findEventByLocationId({ id })
    },
    { enabled: !!id },
  )
  return { eventCondition, eventConditionIsLoading: isLoading }
}

export { useGetEventByLocationId }
