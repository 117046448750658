import { useQuery } from 'react-query'

import { ActivityApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { ActivitiesFiltersProps } from '../../types'
import { errorDataMessage } from '../../utils'

const useGetActivities = (page: number, params: ActivitiesFiltersProps) => {
  const { data: activities, isLoading: activitiesIsLoading } = useQuery(
    [QUERY_KEYS.ACTIVITIES],
    () => ActivityApi.getAllActivities(page, params),
    {
      onError: errorDataMessage,
    },
  )

  return { activities, activitiesIsLoading }
}

export { useGetActivities }
