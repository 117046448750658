import { api } from './axios'

const importData = async (file: FormData, entity: string) => {
  const response = await api.post(`/api/${entity}/import`, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return response.data
}

const getProfile = async () => {
  const response = await api.get('/api/profile')
  return response.data.data
}

export const OthersApi = {
  importData,
  getProfile,
}
