import { PropsWithChildren } from 'react'

import { CrossIcon } from '../../../assets/icons'
import { SecundaryButton } from '../../SecundaryButton'
import './styles.css'

interface Props {
  title: string
  open: boolean
  onClose: () => void
  showActionButtons?: boolean
  onConfirm?: () => void
  disabledConfirm?: boolean
  maxWidth?: string
  width?: string
  message?: string
}

const SendQrModal = ({
  title,
  open,
  onClose,
  showActionButtons,
  onConfirm,
  disabledConfirm,
  children,
  maxWidth,
  width,
  message,
}: PropsWithChildren<Props>) => {
  return (
    <div id="modalWrapper" className={`${open ? 'visible' : ''}`}>
      <div className="modal-content-confirm" style={maxWidth && width ? { maxWidth, width } : {}}>
        <div onClick={() => onClose()} className="modal__close" id="modalClose">
          <CrossIcon />
        </div>
        <p className="modal-content__success-msg" id="msg">
          {title}
        </p>
        <p className="modal-content-p">{message}</p>
        <p className="modal-content-p">¿Confirmar el envío de QR?</p>
        {children}
        {showActionButtons && (
          <div className="button-container">
            <div className="buttons">
              <SecundaryButton
                label="Si"
                onClick={() => onConfirm && onConfirm()}
                disabled={disabledConfirm}
                style={{ width: 80, margin: 10 }}
              />
              <SecundaryButton
                label="No"
                onClick={() => onClose()}
                style={{ width: 80, margin: 10, backgroundColor: '#163C68', color: 'white' }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SendQrModal
