import { useQuery } from 'react-query'

import { OthersApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetProfile = () => {
  const { data } = useQuery([QUERY_KEYS.ROLES_COMBO], () => OthersApi.getProfile())

  return { profile: data ?? [] }
}

export { useGetProfile }
