import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { removeAuthHeader, setAuthHeader } from '../api'
import { LOCAL_STORAGE_KEYS, ROUTE_KEYS } from '../constants'
import { AuthResponse } from '../types'
import { logout } from './logout'

const setInterceptors = (api: AxiosInstance): AxiosInstance => {
  const getRefreshed = (defaultBool: boolean) => {
    const almostRefreshed = window.localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESHED)
    const refreshedAux =
      almostRefreshed && JSON.parse(almostRefreshed) ? JSON.parse(almostRefreshed) : defaultBool
    return refreshedAux
  }
  api.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response.request.responseURL.indexOf('refresh') < 0) {
        if (response.status === 200) {
          const refreshed = getRefreshed(true)
          if (!refreshed) {
            window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESHED, 'true')
          }
        }
      }
      return response
    },
    async (error: AxiosError) => {
      if (error.response?.status === 401 || error.response?.statusText === '401 Unauthorized') {
        try {
          const refreshToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)!
          removeAuthHeader()
          const { data } = await api.get<AuthResponse>(
            `${ROUTE_KEYS.REFRESH}?token=${refreshToken}`,
          )
          setAuthHeader(data.access_token)
          window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESHED, 'false')
          window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, data.access_token)
          window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refresh_token)
          const lastRequest = error.config
          setAuthHeader(data.access_token)
          return api.request(lastRequest)
        } catch (refreshError) {
          console.error('Error refreshing token', refreshError)
          if ((refreshError as AxiosError).response?.status === 404) {
            const refreshed = getRefreshed(false)
            if (refreshed) {
              logout()
            }
          }
          return Promise.reject(error)
        }
      }
      return Promise.reject(error)
    },
  )
  return api
}

export { setInterceptors }
