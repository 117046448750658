import { useGenericMutation } from '../useGenericMutation'
import { UserApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useDeleteUser = (onSuccess?: () => void) => {
  const {
    mutate: deleteUser,
    isSuccess: isDeleteUserSuccess,
    isLoading: isDeleteUserLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.USERS,
    mutationFn: UserApi.deleteUser,
    errorMessage: 'No fue posible eliminar el perfil',
    successMessage: 'Perfil eliminado exitosamente',
    onSuccess,
  })

  return {
    deleteUser,
    isDeleteUserSuccess,
    isDeleteUserLoading,
  }
}

export { useDeleteUser }
