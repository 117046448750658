import { useNavigate } from 'react-router-dom'

interface SideMenuProps {
  menuItems: {
    key: string
    icon: JSX.Element
    title: string
    options?: {
      subMenuKey: string
      subMenuTitle: string
      subMenuPath: string
    }[]
    path?: string
  }[]
}

const closeCurrentSubmenu = () => {
  const currentOpened = document.querySelector('.displayed') as HTMLElement
  if (currentOpened) {
    closeSubmenu(currentOpened)
  }
}
const closeSubmenu = (displayed: HTMLElement) => {
  displayed.classList.remove('displayed')
  displayed.removeAttribute('style')
}

const SideMenu = ({ menuItems }: SideMenuProps) => {
  const navigate = useNavigate()

  const handleOpenSubmenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement
    const submenu = target.nextElementSibling as HTMLElement
    if (submenu) {
      if (submenu.classList.contains('displayed')) {
        closeSubmenu(submenu)
      } else {
        closeCurrentSubmenu()
        submenu.classList.add('displayed')
        submenu.style.height = submenu.scrollHeight + 'px'
      }
    }
  }

  const highlightCurrent = (target: EventTarget) => {
    const previuslySelected = document.querySelectorAll('.selected-menu-option')
    if (previuslySelected) {
      previuslySelected.forEach(item => item.classList.toggle('selected-menu-option', false))
    }
    const selected = target as HTMLDivElement
    selected.classList.toggle('selected-menu-option', true)
  }

  const handleNavigateSubmenuCloser = (path: string, target: EventTarget) => {
    closeCurrentSubmenu()
    handleNavigate(path, target)
  }
  const handleNavigate = (path: string, target: EventTarget) => {
    highlightCurrent(target)
    navigate(path)
  }

  return (
    <ul>
      {menuItems.map(({ key, icon, path, title, options }) => {
        const isNotSubmenu = path && !options
        return (
          <li key={key} className={isNotSubmenu ? undefined : 'displayable-submenu'}>
            {isNotSubmenu ? (
              <div
                onClick={({ target }) => handleNavigateSubmenuCloser(path, target)}
                className="menu-option"
              >
                <div className="icon-container">{icon}</div>
                {title}
              </div>
            ) : (
              <div onClick={handleOpenSubmenu} className="displayer">
                <div className="icon-container">{icon}</div>
                {title}
              </div>
            )}
            {!isNotSubmenu && (
              <ul className="submenu">
                {options?.map(({ subMenuKey, subMenuPath, subMenuTitle }) => (
                  <li
                    className="submenu-option"
                    key={subMenuKey}
                    onClick={({ target }) => handleNavigate(subMenuPath, target)}
                  >
                    {subMenuTitle}
                  </li>
                ))}
              </ul>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export { SideMenu }
