import { useGenericMutation } from '../useGenericMutation'
import { NeighborEventsApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useSendQrNeighborEvents = (onSuccess?: () => void) => {
  const {
    mutate: sendQrNeighborEvents,
    isSuccess: isSendQrNeighborEventsSuccess,
    isLoading: isSendQrNeighborEventsLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.SEND_QR_NEIGHBOR_EVENTS,
    mutationFn: NeighborEventsApi.sendQr,
    errorMessage: 'No fue posible enviar los QR',
    successMessage: 'QR enviados exitosamente',
    onSuccess,
  })

  return {
    sendQrNeighborEvents,
    isSendQrNeighborEventsSuccess: isSendQrNeighborEventsSuccess,
    isSendQrNeighborEventsLoading: isSendQrNeighborEventsLoading,
  }
}

export { useSendQrNeighborEvents }
