const formatDNI = (value = '') => value.replace(/\d(?=(?:\d{3})+$)/g, '$&.')

const formatCUIL = (value: string) => {
  const lastIndex = value.length - 1
  return `${value.slice(0, 2)}-${formatDNI(value.slice(2, lastIndex))}-${value.slice(lastIndex)}`
}

export const StringUtils = {
  formatDNI,
  formatCUIL,
}
