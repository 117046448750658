import { useGenericMutation } from '../useGenericMutation'
import { CategoryApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useDeleteCategory = (onSuccess?: () => void) => {
  const {
    mutate: deleteCategory,
    isSuccess: isDeleteCategorySuccess,
    isLoading: isDeleteCategoryLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.CATEGORY,
    mutationFn: CategoryApi.deleteCategory,
    errorMessage: 'No fue posible eliminar la categoria',
    successMessage: 'Categoria eliminada exitosamente',
    onSuccess,
  })

  return {
    deleteCategory,
    isDeleteCategorySuccess,
    isDeleteCategoryLoading,
  }
}

export { useDeleteCategory }
