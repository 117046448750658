import { useEffect, useState } from 'react'
import { Row, Col, Table, Pagination } from 'antd'
import { useQueryClient } from 'react-query'

import { EditIcon, PlusIcon, TrashIcon } from '../../assets/icons'
import {
  ActionsContainer,
  FilterInput,
  InputContainer,
  Modal,
  PrimaryButton,
  RequiredText,
} from '../../components'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import { useDeleteLevel, useEditLevel, useGetLevels, useCreateLevel } from '../../hooks'
import { LevelsFiltersProps, LevelsInterface } from '../../types'
import { QUERY_KEYS } from '../../constants'

const Levels = () => {
  const [page, setPage] = useState(1)
  const defaultFilters: LevelsFiltersProps = {
    activityId: undefined,
  }
  const [filters] = useState<LevelsFiltersProps>(defaultFilters)
  const [errors, setErrors] = useState(true)
  const queryClient = useQueryClient()

  const [showNewLevelModal, setShowNewLevelModal] = useState(false)
  const [showEditLevelModal, setShowEditLevelModal] = useState(false)

  const [selectedLevelId, setSelectedLevelId] = useState(0)
  const [selectedLevelName, setSelectedLevelName] = useState('')
  const [selectedLevelPoints, setSelectedLevelPoints] = useState(0)
  const [paginator, setPaginator] = useState<number | undefined>(1)
  const [inputError, setInputError] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const resetValues = () => {
    setSelectedLevelName('')
    setSelectedLevelId(0)
    setErrors(true)
    setSelectedLevelPoints(0)
  }

  const { levels, levelsIsLoading } = useGetLevels(page, filters)
  const { createLevel, isCreateLevelLoading } = useCreateLevel(() => {
    setShowNewLevelModal(false)
    resetValues()
  })

  const { editLevel, isEditLevelLoading } = useEditLevel(() => {
    setShowEditLevelModal(false)
    resetValues()
  })
  const { deleteLevel } = useDeleteLevel()

  const columns = [
    {
      title: 'Niveles',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Puntos Necesarios',
      dataIndex: 'neededPoints',
      key: 'neededPoints',
    },
    {
      title: 'Acciones',
      key: 'action',
      render: ({ category, id, neededPoints }: LevelsInterface) => (
        <ActionsContainer>
          <EditIcon
            onClick={() => {
              setSelectedLevelId(id)
              setSelectedLevelName(category)
              setSelectedLevelPoints(neededPoints)
              setShowEditLevelModal(true)
            }}
          />
          <TrashIcon
            onClick={() => {
              setSelectedLevelId(id)
              setShowDeleteConfirmation(true)
            }}
          />
        </ActionsContainer>
      ),
    },
  ]

  const inputContainer = (
    <InputContainer>
      <RequiredText>(*)Datos Obligatorios</RequiredText>
      <FilterInput
        label="Nivel"
        name="category"
        required
        value={selectedLevelName}
        onChange={e => {
          setSelectedLevelName(e.target.value)
        }}
      />
      <FilterInput
        label="Puntos Necesarios"
        name="neededPoints"
        required
        value={selectedLevelPoints.toString()}
        onChange={e => {
          const inputValue = e.target.value
          const parsedValue = Number(inputValue)
          if (!isNaN(parsedValue)) {
            setSelectedLevelPoints(parsedValue)
            setInputError(false)
          } else {
            setInputError(true)
          }
        }}
      />
      {inputError && <span style={{ color: 'red' }}>¡Este campo solo acepta numeros!</span>}
    </InputContainer>
  )

  useEffect(() => {
    if (selectedLevelName) setErrors(false)
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.fetchQuery(QUERY_KEYS.LEVELS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevelName, page, paginator])

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h1>Lista de niveles</h1>
      </Col>
      <Col span={24}>
        <Row justify="end" align="middle" gutter={[12, 0]}>
          <Col>
            <PrimaryButton
              label="Nuevo Nivel"
              icon={<PlusIcon />}
              onClick={() => setShowNewLevelModal(true)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          dataSource={levels?.data || []}
          columns={columns}
          pagination={false}
          rowKey="id"
          loading={levelsIsLoading}
        />
        <Pagination onChange={e => handlePage(e)} current={page} total={levels?.paginate.total} />
      </Col>
      <Modal
        title="Nuevo Nivel"
        open={showNewLevelModal}
        onClose={() => {
          setShowNewLevelModal(false)
          resetValues()
        }}
        onSave={() => {
          createLevel({ category: selectedLevelName, neededPoints: selectedLevelPoints })
        }}
        disabledSave={errors || isCreateLevelLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <Modal
        title="Editar Nivel"
        open={showEditLevelModal}
        onClose={() => {
          setShowEditLevelModal(false)
          resetValues()
        }}
        onSave={() => {
          if (!selectedLevelId) return
          editLevel({
            id: selectedLevelId,
            category: selectedLevelName,
            neededPoints: selectedLevelPoints,
          })
        }}
        disabledSave={errors || isEditLevelLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <DeleteModal
        title="Confirmar Eliminación"
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => {
          deleteLevel({ id: selectedLevelId })
          setShowDeleteConfirmation(false)
        }}
        showActionButtons
      >
        ¿Estás seguro de que deseas eliminar este nivel?
      </DeleteModal>
    </Row>
  )
}

export { Levels }
