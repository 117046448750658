import { api } from './axios'
import { NeighborEventsFiltersProps } from '../types'
import { errorDataMessage, setEndpoint } from '../utils'

const apiNeighborEvent = '/api/neighbor-event'

interface EditNeighborEventPayload {
  id: number
  medicalClearanceStatusId: number
  medicalClearanceDueDate: string
}

const getAllNeighborEvents = async (
  page: number,
  filters: NeighborEventsFiltersProps,
  maxResult: number | null,
  order: string | null,
  orderBy: string | null,
) => {
  try {
    const response = await api.get(
      setEndpoint(
        `${apiNeighborEvent}/read?page=${page}&maxResult=${maxResult}&order=${order}&orderBy=${orderBy}`,
        filters,
      ),
    )

    return response.data || []
  } catch {
    errorDataMessage()
    return []
  }
}

const updateNeigborEvent = async ({
  id,
  medicalClearanceStatusId,
  medicalClearanceDueDate,
}: EditNeighborEventPayload) => {
  const response = await api.put(`${apiNeighborEvent}/${id}`, {
    medicalClearanceStatusId,
    medicalClearanceDueDate,
  })
  return response.data
}

const sendQr = async () => {
  const response = await api.post('/api/neighbor-event/send_qr')
  return response.data.data
}

export const NeighborEventsApi = {
  getAllNeighborEvents,
  updateNeigborEvent,
  sendQr,
}
