import { useQuery } from 'react-query'

import { LevelApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { LevelsFiltersProps } from '../../types'
import { errorDataMessage } from '../../utils'

const useGetLevels = (page: number, params: LevelsFiltersProps) => {
  const { data: levels, isLoading: levelsIsLoading } = useQuery(
    [QUERY_KEYS.LEVELS],
    () => LevelApi.getLevels(page, params),
    {
      onError: errorDataMessage,
    },
  )

  return { levels, levelsIsLoading }
}

export { useGetLevels }
