import { useGenericMutation } from '../useGenericMutation'
import { LocationApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useCreateLocation = (onSuccess?: () => void) => {
  const {
    mutate: createLocation,
    isSuccess: isCreateLocationSuccess,
    isLoading: isCreateLocationLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.LOCATIONS,
    mutationFn: LocationApi.createLocation,
    errorMessage: 'No fue posible guardar la locación',
    successMessage: 'Locación guardada exitosamente',
    onSuccess,
  })

  return {
    createLocation,
    isCreateLocationSuccess,
    isCreateLocationLoading,
  }
}

export { useCreateLocation }
