import { useEffect, useState } from 'react'
import { Row, Col, Table, Pagination } from 'antd'
import { useQueryClient } from 'react-query'

import { EditIcon, EraserIcon, PlusIcon, SearchIcon, TrashIcon } from '../../assets/icons'
import {
  ActionsContainer,
  FilterButton,
  FilterInput,
  FilterSelect,
  InputContainer,
  Modal,
  PrimaryButton,
  RequiredText,
  SecundaryButton,
  StyledLabel,
  Checkbox,
} from '../../components'
import {
  useCreateActivity,
  useDeleteActivity,
  useEditActivity,
  useGetActivities,
  useGetActivityCombo,
  useGetEventByActivityId,
} from '../../hooks'
import { ActivitiesFiltersProps, Activity } from '../../types'
import { QUERY_KEYS } from '../../constants'
import { ConfirmModal } from '../../components/Modal/ConfirmModal'

const booleanOptions = [
  { label: 'Sí', value: 1 },
  { label: 'No', value: 0 },
  { label: 'Todos', value: 2 },
]

const Activities = () => {
  const [showFilters, setShowFilters] = useState(false)
  const [page, setPage] = useState(1)
  const defaultFilters: ActivitiesFiltersProps = {
    activityId: undefined,
  }
  const [filters, setFilters] = useState<ActivitiesFiltersProps>(defaultFilters)
  const [errors, setErrors] = useState(true)
  const queryClient = useQueryClient()
  const { activityCombo } = useGetActivityCombo()

  const [showNewActivitiesModal, setShowNewActivitiesModal] = useState(false)
  const [showEditActivitiesModal, setShowEditActivitiesModal] = useState(false)

  const [selectedActivityId, setSelectedActivityId] = useState(0)
  const [selectedActivityName, setSelectedActivityName] = useState('')
  const [selectedActivityCheck, setSelectedActivityCheck] = useState(0)
  const [paginator, setPaginator] = useState<number | undefined>(1)
  const [activityId, setActivityId] = useState(0)
  const [showEditActionModal, setShowEditActionModal] = useState(false)

  const resetValues = () => {
    setSelectedActivityName('')
    setSelectedActivityId(0)
    setErrors(true)
    setSelectedActivityCheck(0)
  }

  const { activities, activitiesIsLoading } = useGetActivities(page, filters)
  const { createActivity, isCreateActivityLoading } = useCreateActivity(() => {
    setShowNewActivitiesModal(false)
    resetValues()
  })

  const { editActivity, isEditActivityLoading } = useEditActivity(() => {
    setShowEditActivitiesModal(false)
    resetValues()
  })
  const { deleteActivity } = useDeleteActivity()

  const { eventCondition, eventConditionIsLoading } = useGetEventByActivityId(activityId)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Actividad',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Admite no registrados',
      key: 'enable_unregistered',
      render: ({ enable_unregistered }: Activity) => (
        <>{enable_unregistered === 2 ? 'Todos' : enable_unregistered ? 'Sí' : 'No'}</>
      ),
    },
    {
      title: 'Acciones',
      key: 'action',
      render: ({ name, id, enable_unregistered }: Activity) => (
        <ActionsContainer>
          <EditIcon
            onClick={() => {
              setSelectedActivityId(id)
              setSelectedActivityName(name)
              setSelectedActivityCheck(enable_unregistered)
              setShowEditActivitiesModal(true)
            }}
          />
          <TrashIcon onClick={() => trash(id, name)} />
        </ActionsContainer>
      ),
    },
  ]

  const inputContainer = (
    <InputContainer>
      <RequiredText>(*)Datos Obligatorios</RequiredText>
      <FilterInput
        label="Actividad"
        name="activity"
        required
        value={selectedActivityName}
        onChange={e => {
          setSelectedActivityName(e.target.value)
        }}
      />
      <Checkbox
        name="enableUnregistered"
        label="¿Admite ingreso de vecinos no registrados a la actividad?"
        options={booleanOptions}
        value={selectedActivityCheck}
        onChange={({ target: { value } }) => {
          setSelectedActivityCheck(value)
        }}
        required
      />
    </InputContainer>
  )

  useEffect(() => {
    if (selectedActivityName) setErrors(false)
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.fetchQuery(QUERY_KEYS.ACTIVITIES)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivityName, page, paginator])

  const handleQuery = () => {
    setPage(1)
    queryClient.fetchQuery(QUERY_KEYS.ACTIVITIES)
  }

  const handleCleanFilters = () => {
    setPaginator(undefined)
    setFilters(defaultFilters)
  }
  const handleFilterValue = (key: string, value: number | string) => {
    setPaginator(0)
    setPage(1)
    setFilters({
      ...filters,
      [key]: value,
    })
  }

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  const keyEnter = () => {
    handleQuery()
  }

  const trash = (id: number, name: string) => {
    setShowEditActionModal(true)
    setActivityId(id)
    setSelectedActivityName(name)
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h1>Lista de actividades</h1>
      </Col>
      <Col span={24}>
        <Row justify="end" align="middle" gutter={[12, 0]}>
          <Col>
            <PrimaryButton
              label="Nueva Actividad"
              icon={<PlusIcon />}
              onClick={() => setShowNewActivitiesModal(true)}
            />
          </Col>
          <Col span={1}>
            <FilterButton onClick={() => setShowFilters(prev => !prev)} />
          </Col>
        </Row>
      </Col>
      {showFilters && (
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <StyledLabel></StyledLabel>
            </Col>
            <Col span={24}>
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <FilterSelect
                    options={activityCombo}
                    value={filters.activityId}
                    name="activityId"
                    label="Actividad"
                    onChange={(value: unknown) => handleFilterValue('activityId', value as number)}
                    keyEnter={keyEnter}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[12, 0]}>
                <Col>
                  <SecundaryButton
                    label="Limpiar"
                    icon={<EraserIcon />}
                    onClick={handleCleanFilters}
                  />
                </Col>
                <Col>
                  <PrimaryButton label="Buscar" icon={<SearchIcon />} onClick={handleQuery} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      <Col span={24}>
        <Table
          dataSource={activities?.data || []}
          columns={columns}
          pagination={false}
          rowKey="id"
          loading={activitiesIsLoading}
        />
        <Pagination
          onChange={e => handlePage(e)}
          current={page}
          total={activities?.paginate.total}
        />
      </Col>
      <Modal
        title="Nueva Actividad"
        open={showNewActivitiesModal}
        onClose={() => {
          setShowNewActivitiesModal(false)
          resetValues()
        }}
        onSave={() => {
          createActivity({ name: selectedActivityName, enable_unregistered: selectedActivityCheck })
        }}
        disabledSave={errors || isCreateActivityLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <Modal
        title="Editar Actividad"
        open={showEditActivitiesModal}
        onClose={() => {
          setShowEditActivitiesModal(false)
          resetValues()
        }}
        onSave={() => {
          if (!selectedActivityId) return
          editActivity({
            id: selectedActivityId,
            name: selectedActivityName,
            enable_unregistered: selectedActivityCheck,
          })
        }}
        disabledSave={errors || isEditActivityLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <ConfirmModal
        title="Confirme la acción"
        open={!eventConditionIsLoading && showEditActionModal}
        onClose={() => setShowEditActionModal(false)}
        onConfirm={() => {
          deleteActivity({ id: activityId })
          setShowEditActionModal(false)
        }}
        width={'100'}
        showActionButtons
        message={`${
          eventCondition === 'activity_found'
            ? `La actividad ${selectedActivityName}\n \n\n pertenece a eventos activos.`
            : `Actividad ${selectedActivityName}\n`
        }`}
      ></ConfirmModal>
    </Row>
  )
}

export { Activities }
