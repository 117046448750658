export const QUERY_KEYS = {
  LOCATIONS: 'locations',
  ACTIVITIES: 'activities',
  EVENTS: 'events',
  EVENTS_COMBO: 'events_combo',
  NEIGHBOR_EVENTS: 'neighbor_events',
  MEDICAL_CLEARANCE_STATUS_COMBO: 'medical_clearance_status_combo',
  USERS_COMBO: 'users_combo',
  VISITS: 'visits',
  USERS: 'users',
  USER: 'user',
  ROLES_COMBO: 'roles_combo',
  ACTIVITIES_COMBO: 'activities_combo',
  LOCATIONS_COMBO: 'locations_combo',
  NEIGHBOR_CUIL_SEARCH: 'neighbor_cuil_search',
  CFG_APP_DATA: 'cfg_app_data',
  EDIT_CFG_APP_DATA: 'edit_cfg_app_data',
  LEVELS: 'levels',
  LEVELS_COMBO: 'levels_combo',
  COMMERCE: 'commerce',
  COMMERCE_COMBO: 'commerce_combo',
  BENEFITS: 'benefits',
  PERIODICITY_COMBO: 'periodicity_combo',
  ACTION: 'action',
  ACTION_COMBO: 'action_combo',
  BENEFITS_COMBO: 'benefits_combo',
  CATEGORY: 'category',
  CATEGORY_COMBO: 'category_combo',
  REPORT_BENEFITS_COMBO: 'report_benefits_combo',
  LOCATION_ID_SEARCH: 'location_id_search',
  ACTIVITY_ID_SEARCH: 'activity_id_search',
  SEND_QR_NEIGHBOR_EVENTS: 'send_qr_neighbor_events',
}
