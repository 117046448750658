import { Col, Pagination, Row, Table } from 'antd'
import moment from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { CloseCircleFilled } from '@ant-design/icons'

import { VisitApi } from '../../api'
import { EraserIcon, ExportIcon, SearchIcon } from '../../assets/icons'
import {
  FilterButton,
  FilterInput,
  FilterSelect,
  PrimaryButton,
  SecundaryButton,
  StyledLabel,
  StyledRangePicker,
} from '../../components'
import ImportModal from '../../components/Modal/ImportModal'
import { QUERY_KEYS, PERMISSION_KEY } from '../../constants'
import {
  useGetActivityCombo,
  useGetLocationCombo,
  useGetUsersCombo,
  useGetVisits,
  useGetEventsCombo,
} from '../../hooks'
import { VisitsFiltersProps, Visit, DefaultVisitsFiltersProps, OrderBy } from '../../types'
import { dateFormat, StringUtils } from '../../utils'
import { useUserSession } from '../../hooks/useUserSession'
import { ColumnTitleRender } from '../../components/ColumnTitleRender'

const Visits = () => {
  const [showFilters, setShowFilters] = useState(false)
  const [page, setPage] = useState(1)
  const today = moment().endOf('d')
  const defaultPastDate = moment().subtract(7, 'd').startOf('d')
  const defaultAttendanceDate = [defaultPastDate, today] as RangeValue<moment.Moment>
  const defaultFilters: DefaultVisitsFiltersProps = {
    neighborCuil: undefined,
    neighborName: undefined,
    neighborLastName: undefined,
    eventLocation: undefined,
    eventActivity: undefined,
    eventEvent: undefined,
    user: undefined,
    attendance_dates: defaultAttendanceDate,
  }

  const [neighborCuil, setNeighborCuil] = useState<string | undefined>(undefined)
  const [neighborName, setNeighborName] = useState<string | undefined>(undefined)
  const [neighborLastName, setNeighborLastName] = useState<string | undefined>(undefined)
  const [eventLocation, setEventLocation] = useState<number | undefined>(undefined)
  const [eventActivity, setEventActivity] = useState<number | undefined>(undefined)
  const [eventEvent, setEventEvent] = useState<number | undefined>(undefined)
  const [user, setUser] = useState<string | undefined>(undefined)
  const [attendance_dates, setAttendance_dates] = useState(defaultAttendanceDate)
  const [maxResult, setMaxResult] = useState<number | null>(null)
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>(undefined)

  const [filters, setFilters] = useState<VisitsFiltersProps | DefaultVisitsFiltersProps>(
    defaultFilters,
  )

  const { locationCombo } = useGetLocationCombo()
  const { activityCombo } = useGetActivityCombo()
  const { usersCombo } = useGetUsersCombo()
  const { events } = useGetEventsCombo()
  const queryClient = useQueryClient()

  const { visits, visitsIsLoading } = useGetVisits(page, filters, maxResult, orderBy)
  const [importModalIsOpen, setImportModaIsOpen] = useState(false)
  const [paginator, setPaginator] = useState<number | undefined>(1)

  const { userSession } = useUserSession()

  const eventsCombo = events.map((e: { id: number; value: string }) => ({
    label: e.value,
    value: e.id,
  }))

  const columns = [
    {
      title: ColumnTitleRender({
        title: 'Fecha',
        key: 'date',
        sortable: true,
        orderBy,
        setOrderBy,
      }),
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => moment(date).format(dateFormat),
    },
    {
      title: 'CUIL Vecino',
      key: 'cuil',
      render: ({ cuil }: Visit) => <>{`${cuil ? StringUtils.formatCUIL(cuil) : '-'}`}</>,
    },
    {
      title: 'Vecino',
      dataIndex: 'neighbor',
      key: 'neighbor',
    },
    {
      title: 'E-mail Vecino',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Operador',
      dataIndex: 'operator',
      key: 'operator',
    },
    {
      title: 'Evento',
      dataIndex: 'event',
      key: 'operateventor',
    },
    {
      title: 'Registrado',
      key: 'isRegistered',
      render: ({ isRegistered }: Visit) => <>{isRegistered ? 'Sí' : 'No'}</>,
    },
    {
      title: 'Participa',
      key: 'hasAttended',
      render: ({ hasAttended }: Visit) => <>{hasAttended ? 'Sí' : 'No'}</>,
    },
  ]

  const handleQuery = () => {
    const valores = {
      neighborCuil: neighborCuil,
      neighborName: neighborName,
      neighborLastName: neighborLastName,
      eventLocation: eventLocation,
      eventActivity: eventActivity,
      eventEvent: eventEvent,
      user: user,
      attendance_dates: attendance_dates,
    }

    queryClient.resetQueries(QUERY_KEYS.VISITS)
    setPage(1)
    if (paginator === undefined) {
      setFilters(defaultFilters)
    } else {
      setFilters(valores)
    }
    //queryClient.cancelQueries(QUERY_KEYS.VISITS)
    //valores = defaultFilters
  }

  const handleCleanFilters = () => {
    setPaginator(undefined)
    setNeighborCuil(undefined)
    setNeighborName(undefined)
    setNeighborLastName(undefined)
    setEventLocation(undefined)
    setEventActivity(undefined)
    setEventEvent(undefined)
    setUser(undefined)
    setOrderBy(undefined)
    setAttendance_dates(defaultAttendanceDate)
    setPaginator(1)

    queryClient.cancelQueries(QUERY_KEYS.VISITS)
    setPage(1)
    setFilters(defaultFilters)
    queryClient.resetQueries(QUERY_KEYS.VISITS)
    //valores = defaultFilters
  }

  const handleExport = () => {
    VisitApi.exportVisits(filters, null, null).then(res => {
      const link = document.createElement('a')
      const url = window.URL.createObjectURL(res)
      link.href = url
      link.setAttribute(
        'download',
        `registro_visitas_${moment().format(`${dateFormat}[_]HH[-]mm[-]ss`)}`,
      )
      document.body.appendChild(link)

      link.click()
      link.parentNode?.removeChild(link)
    })
  }

  const onRangePickerChange = (value: RangeValue<moment.Moment>) => {
    setAttendance_dates(value)
  }

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  const keyEnter = () => {
    const valores = {
      neighborCuil: neighborCuil,
      neighborName: neighborName,
      neighborLastName: neighborLastName,
      eventLocation: eventLocation,
      eventActivity: eventActivity,
      eventEvent: eventEvent,
      user: user,
      attendance_dates: attendance_dates,
      orderBy: orderBy,
    }
    setPaginator(0)
    setPage(1)
    setFilters(valores)
  }

  const handlePageSize = (page: number, pageSize: number) => {
    setMaxResult(pageSize)
    setPage(1)
    queryClient.resetQueries(QUERY_KEYS.VISITS)
  }

  useEffect(() => {
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.resetQueries(QUERY_KEYS.VISITS)
      // queryClient.invalidateQueries(QUERY_KEYS.VISITS)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, paginator])

  useEffect(() => {
    if (maxResult) {
      queryClient.resetQueries(QUERY_KEYS.EVENTS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxResult])

  useEffect(() => {
    handleQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy])

  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <h1>Lista de registros de visitas</h1>
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Row>
              {userSession?.permissions?.includes(
                PERMISSION_KEY.SEARCH_EVENT_ATTENDANCE_EXPORT_EVENT_ATTENDANCE,
              ) && (
                <Col style={{ marginRight: '1rem' }}>
                  <SecundaryButton
                    label="Exportar XLS"
                    onClick={handleExport}
                    icon={<ExportIcon />}
                  />
                </Col>
              )}
            </Row>
            <Col span={1}>
              <FilterButton onClick={() => setShowFilters(prev => !prev)} />
            </Col>
          </Row>
        </Col>
        {showFilters && (
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <StyledLabel></StyledLabel>
              </Col>
              <Col span={24}>
                <Row gutter={[24, 0]}>
                  <Col span={8}>
                    <FilterInput
                      value={neighborCuil}
                      name="cuil"
                      placeholder="Solo números"
                      label="Cuil"
                      onChange={e => {
                        setNeighborCuil(e.target.value)
                      }}
                      keyEnter={keyEnter}
                      allowClear={{
                        clearIcon: <CloseCircleFilled onClick={() => setNeighborCuil(undefined)} />,
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <FilterInput
                      value={neighborName}
                      name="name"
                      label="Nombre"
                      onChange={e => {
                        setNeighborName(e.target.value)
                      }}
                      keyEnter={keyEnter}
                      allowClear={{
                        clearIcon: <CloseCircleFilled onClick={() => setNeighborName(undefined)} />,
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <FilterInput
                      value={neighborLastName}
                      name="lastName"
                      label="Apellido"
                      onChange={e => {
                        setNeighborLastName(e.target.value)
                      }}
                      keyEnter={keyEnter}
                      allowClear={{
                        clearIcon: (
                          <CloseCircleFilled onClick={() => setNeighborLastName(undefined)} />
                        ),
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[24, 0]}>
                  <Col span={8}>
                    <FilterSelect
                      options={eventsCombo}
                      value={eventEvent}
                      name="eventEvent"
                      label="Evento"
                      onChange={(value: unknown) => setEventEvent(value as number)}
                      keyEnter={keyEnter}
                    />
                  </Col>
                  <Col span={8}>
                    <FilterSelect
                      options={locationCombo}
                      value={eventLocation}
                      name="eventLocation"
                      label="Locación"
                      onChange={(value: unknown) => setEventLocation(value as number)}
                      keyEnter={keyEnter}
                    />
                  </Col>
                  <Col span={8}>
                    <FilterSelect
                      options={activityCombo}
                      value={eventActivity}
                      name="eventActivity"
                      label="Actividad"
                      onChange={(value: unknown) => setEventActivity(value as number)}
                      keyEnter={keyEnter}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[24, 0]}>
                  <Col span={8}>
                    <FilterSelect
                      options={usersCombo}
                      value={user}
                      name="user"
                      label="Operador"
                      onChange={(value: unknown) => setUser(value as string)}
                      keyEnter={keyEnter}
                    />
                  </Col>
                  <Col span={16}>
                    <StyledLabel>Fechas desde hasta</StyledLabel>
                    <StyledRangePicker
                      format={dateFormat}
                      onChange={onRangePickerChange}
                      value={attendance_dates}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end" gutter={[12, 0]}>
                  <Col>
                    <SecundaryButton
                      label="Limpiar"
                      icon={<EraserIcon />}
                      onClick={handleCleanFilters}
                    />
                  </Col>
                  <Col>
                    <PrimaryButton label="Buscar" icon={<SearchIcon />} onClick={handleQuery} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Table
            //sortDirections={['descend']}
            dataSource={visits?.data || []}
            columns={columns}
            pagination={false}
            rowKey="id"
            loading={visitsIsLoading}
          />
          <Pagination
            onChange={e => {
              handlePage(e)
            }}
            current={page}
            total={visits?.paginate?.total}
            pageSize={maxResult || 10}
            onShowSizeChange={(page: number, pageSize: number) => handlePageSize(page, pageSize)}
          />
        </Col>
      </Row>
      <ImportModal
        title="Importar archivo de asignación a eventos"
        open={importModalIsOpen}
        onClose={() => setImportModaIsOpen(false)}
        entity="neighbor-event"
        accept=".xls, .xlsx"
      />
    </>
  )
}

export { Visits }
