import { useQuery } from 'react-query'

import { EventApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetEventsComboForAction = () => {
  const { data: events } = useQuery([QUERY_KEYS.EVENTS_COMBO], () => EventApi.getEventsCombo())
  return {
    eventsCombo: ((events ?? []) as { id: number; value: string }[]).map(({ id, value }) => ({
      value: id,
      label: value,
    })),
  }
}

export { useGetEventsComboForAction }
