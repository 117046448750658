import { useQuery } from 'react-query'

import { UserApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetUser = (userId?: number) => {
  const { data: user, isLoading: userIsLoading } = useQuery(
    [QUERY_KEYS.USER],
    () => UserApi.getUser(userId!),
    { enabled: !!userId },
  )

  return { user, userIsLoading }
}

export { useGetUser }
