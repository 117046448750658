import { Col, Form, notification, Pagination, Row, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'

import { UserApi } from '../../api'
import { EditIcon, EraserIcon, PlusIcon, SearchIcon, TrashIcon } from '../../assets/icons'
import {
  ActionsContainer,
  FilterButton,
  FilterInput,
  FilterSelect,
  InputContainer,
  InputNum,
  Modal,
  PrimaryButton,
  RequiredText,
  SecundaryButton,
  StyledInput,
  StyledLabel,
  StyledSearch,
  StyledSelect,
} from '../../components'
import { QUERY_KEYS } from '../../constants'
import {
  useGetUsers,
  useGetRolesCombo,
  useGetEventsCombo,
  useGetNeighborByCuil,
  useGetLocationCombo,
  useGetActivityCombo,
  useGetUser,
  useDeleteUser,
} from '../../hooks'
import { AssignedEvents, Combo, User, UsersFiltersProps } from '../../types'
import { requiredMark, StringUtils } from '../../utils'

const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  color: #163c68;
  margin-top: 1rem;
  margin-left: 0.7rem;
`

const ConfirmContainer = styled.div`
  .textContainer {
    margin: 10% 5%;
    p {
      color: ${props => props.theme.secondaryColor};
    }

    h3 {
      font-weight: 600;
      color: ${props => props.theme.secondaryColor};
      margin-bottom: 3%;
    }

    .userInfo {
      margin: 10% 0;
    }
  }

  .buttonContainer {
    display: flex;
    gap: 20px;
    margin: 0 10%;
  }
`

const Users = () => {
  const [showFilters, setShowFilters] = useState(false)
  const [page, setPage] = useState(1)
  const defaultFilters: UsersFiltersProps = {
    cuil: undefined,
    name: undefined,
    lastName: undefined,
    eventLocation: undefined,
    eventActivity: undefined,
  }
  const [filters, setFilters] = useState<UsersFiltersProps>(defaultFilters)
  const [showNewAssignationModal, setShowNewAssignationModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [userToDelete, setUserToDelete] = useState<User>()
  const [form] = Form.useForm()
  const { roles } = useGetRolesCombo()
  const { events } = useGetEventsCombo()
  const { Option } = StyledSelect
  const queryClient = useQueryClient()
  const defaultInputs = {
    name: undefined,
    lastName: undefined,
    email: undefined,
    phoneNumber: undefined,
  }
  const [inputs, setInputs] = useState(defaultInputs)
  const [cuil, setCuil] = useState<string | undefined>(undefined)
  const { neighbor, neighborIsLoading } = useGetNeighborByCuil(cuil)
  const { locationCombo } = useGetLocationCombo()
  const { activityCombo } = useGetActivityCombo()

  const [paginator, setPaginator] = useState<number | undefined>(1)

  useEffect(() => {
    if (
      !editing &&
      (neighbor.name || neighbor.lastName || neighbor.email || neighbor.phoneNumber)
    ) {
      setInputs({
        name: neighbor.name || undefined,
        lastName: neighbor.last_name || undefined,
        email: neighbor.email || undefined,
        phoneNumber: neighbor.phone || undefined,
      })
      form.setFieldsValue({
        name: neighbor.name || undefined,
        lastName: neighbor.last_name || undefined,
        email: neighbor.email || undefined,
        phoneNumber: neighbor.phone || undefined,
      })
    }
    if (form.getFieldValue('roleIds')?.length === 0) {
      form.setFieldsValue({ assignedEvents: undefined })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [neighbor])

  useEffect(() => {
    if (!paginator) {
      handleFilterValue('paginator', 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginator])
  const onDeleteSuccess = () => {
    setShowConfirmModal(false)
    setUserToDelete(undefined)
  }

  const { users, usersIsLoading } = useGetUsers(page, filters)
  const { deleteUser } = useDeleteUser(onDeleteSuccess)

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined)

  const clearUser = {
    cuil: undefined,
    email: undefined,
    id: undefined,
    lastName: undefined,
    name: undefined,
    phoneNumber: undefined,
    roles: undefined,
    rolesNames: undefined,
    rolesArray: undefined,
    assignedEvents: undefined,
  }

  const [userAux, setUserAux] = useState<User>(clearUser)
  const { user, userIsLoading } = useGetUser(selectedUserId)

  useEffect(() => {
    if (user && !userIsLoading) {
      setUserAux(user)
    }
    if (editing) {
      let selectedRole = undefined

      if (user?.rolesArray.includes('backoffice')) {
        selectedRole = 1
      } else if (user?.rolesArray.includes('mobile') || user?.rolesArray.includes('importador')) {
        selectedRole = 2
      }
      setSelectedRole(selectedRole)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, showNewAssignationModal])
  useEffect(() => {
    form.resetFields()
    if (selectedUserId) {
      setEditing(true)

      let selectedRole = undefined

      if (
        userAux.rolesArray?.includes('backoffice') ||
        userAux.rolesArray?.includes('backoffice_fidelizacion')
      ) {
        selectedRole = 1
      }
      if (userAux.rolesArray?.includes('mobile') || user?.rolesArray.includes('importador')) {
        selectedRole = 2
      }
      setSelectedRole(selectedRole)

      setShowNewAssignationModal(true)
    } else {
      setEditing(false)
    }
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.fetchQuery(QUERY_KEYS.USERS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAux, selectedUserId, showNewAssignationModal, page])

  const columns = [
    {
      title: 'CUIL',
      key: 'cuil',
      render: ({ cuil }: User) => <>{`${cuil ? StringUtils.formatCUIL(cuil) : '-'}`}</>,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Apellido',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Rol',
      dataIndex: 'rolesNames',
      key: 'rolesNames',
    },
    {
      title: 'Acciones',
      key: 'action',
      render: (row: User) => (
        <ActionsContainer>
          <EditIcon
            onClick={() => {
              setSelectedUserId(row.id)
            }}
          />
          <TrashIcon
            onClick={() => {
              setUserToDelete(row)
              setShowConfirmModal(true)
            }}
          />
        </ActionsContainer>
      ),
    },
  ]

  const [editing, setEditing] = useState(false)
  const requiredField = 'Dato requerido'
  const stringField = 'Campo de texto'
  const emailField = 'Ingrese un email'
  const msgSaveSuccess = 'Perfil guardado correctamente.'
  const successMsgDuration = 5
  const msgSaveError = 'Se produjo un error al guardar el perfil.'

  const handleOnSave = () => {
    form
      .validateFields()
      .then(values => {
        const { name, lastName, phoneNumber, cuil, email, assignedEvents, roleIds } = values
        editing
          ? UserApi.updateUser(
              {
                name: user.name,
                cuil: user.cuil,
                last_name: user.lastName,
                email,
                phone_number: phoneNumber,
                role_ids: roleIds,
                assigned_events: assignedEvents ? assignedEvents.map((id: number) => ({ id })) : [],
              },
              selectedUserId!,
            )
              .then(() => {
                notification.success({
                  message: msgSaveSuccess,
                  duration: successMsgDuration,
                })
                handleOnClose()
              })
              .catch(() => {
                notification.error({
                  message: msgSaveError,
                  duration: 5,
                })
              })
          : UserApi.createUser({
              name,
              cuil,
              email,
              last_name: lastName,
              phone_number: phoneNumber,
              role_ids: roleIds,
              assigned_events: assignedEvents ? assignedEvents.map((id: number) => ({ id })) : [],
            })
              .then(() => {
                notification.success({
                  message: msgSaveSuccess,
                  duration: successMsgDuration,
                })
                handleOnClose()
              })
              .catch(e => {
                if (e.response.status === 302) {
                  notification.error({
                    message: `El usuario ${form.getFieldValue(
                      'cuil',
                    )} ya tiene un perfil asignado.`,
                    duration: 5,
                  })
                } else {
                  notification.error({
                    message: msgSaveError,
                    duration: 5,
                  })
                }
              })
      })
      .catch(() => {
        notification.error({ message: 'Verificar los datos ingresados.', duration: 5 })
      })
  }

  const handleOnClose = () => {
    setShowNewAssignationModal(false)
    reset()
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    body ? (body.style.overflowY = 'auto') : null
  }
  const reset = () => {
    queryClient.cancelQueries(QUERY_KEYS.NEIGHBOR_CUIL_SEARCH)
    queryClient.refetchQueries(QUERY_KEYS.USERS)
    setSelectedUserId(undefined)
    setUserAux(clearUser)
    setSelectedRole(undefined)
    setCuil(undefined)
    setInputs(defaultInputs)
    setEditing(false)
    form.resetFields()
  }

  const handleNeighborCuilSearch = async (value: string | undefined) => {
    setCuil(value)
    queryClient.cancelQueries(QUERY_KEYS.NEIGHBOR_CUIL_SEARCH)
    if (cuil !== value) {
      await queryClient.resetQueries(QUERY_KEYS.NEIGHBOR_CUIL_SEARCH)
    } else {
      await queryClient.fetchQuery(QUERY_KEYS.NEIGHBOR_CUIL_SEARCH)
    }
  }

  const onSearch = async (value: string) => {
    setCuil(undefined)
    handleNeighborCuilSearch(value)
  }

  const handleInputValue = (key: string, value: unknown) => {
    setInputs({
      ...inputs,
      [key]: value,
    })
  }

  const handleFilterValue = (key: string, value: number | string) => {
    setPaginator(0)
    setPage(1)
    setFilters({
      ...filters,
      [key]: value,
    })
  }

  const handleQuery = () => {
    setPage(1)
    handleFilterValue('paginator', 0)
    queryClient.fetchQuery(QUERY_KEYS.USERS)
  }

  const handleCleanFilters = () => {
    setPaginator(undefined)
    setFilters(defaultFilters)
    setPage(1)
  }

  const keyEnter = () => {
    handleQuery()
  }

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  const body = document.querySelector('body') || null
  const modal = () => {
    setShowNewAssignationModal(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    body ? (body.style.overflowY = 'hidden') : null
  }

  const [selectedRole, setSelectedRole] = useState<number | undefined>(undefined)
  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h1>Asignación de perfil</h1>
      </Col>
      <Col span={24}>
        <Row justify="end" align="middle" gutter={[12, 0]}>
          <Col>
            <PrimaryButton label="Nueva Asignación" icon={<PlusIcon />} onClick={modal} />
          </Col>
          <Col span={1}>
            <FilterButton onClick={() => setShowFilters(prev => !prev)} />
          </Col>
        </Row>
      </Col>
      {showFilters && (
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <StyledLabel></StyledLabel>
            </Col>
            <Col span={24}>
              <Row gutter={[24, 0]}>
                <Col span={8}>
                  <FilterInput
                    value={filters.cuil}
                    name="cuil"
                    placeholder="Solo números"
                    label="Cuil"
                    onChange={e => {
                      handleFilterValue('cuil', e.target.value)
                    }}
                    keyEnter={keyEnter}
                  />
                </Col>
                <Col span={8}>
                  <FilterInput
                    value={filters.name}
                    name="name"
                    label="Nombre"
                    onChange={e => {
                      handleFilterValue('name', e.target.value)
                    }}
                    keyEnter={keyEnter}
                  />
                </Col>
                <Col span={8}>
                  <FilterInput
                    value={filters.lastName}
                    name="lastName"
                    label="Apellido"
                    onChange={e => {
                      handleFilterValue('lastName', e.target.value)
                    }}
                    keyEnter={keyEnter}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <FilterSelect
                    options={locationCombo}
                    value={filters.eventLocation}
                    name="eventLocation"
                    label="Locación"
                    onChange={(value: unknown) =>
                      handleFilterValue('eventLocation', value as number)
                    }
                    keyEnter={keyEnter}
                  />
                </Col>
                <Col span={12}>
                  <FilterSelect
                    options={activityCombo}
                    value={filters.eventActivity}
                    name="eventActivity"
                    label="Actividad"
                    onChange={(value: unknown) =>
                      handleFilterValue('eventActivity', value as number)
                    }
                    keyEnter={keyEnter}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[12, 0]}>
                <Col>
                  <SecundaryButton
                    label="Limpiar"
                    onClick={handleCleanFilters}
                    icon={<EraserIcon />}
                  />
                </Col>
                <Col>
                  <PrimaryButton label="Buscar" onClick={handleQuery} icon={<SearchIcon />} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      <Col span={24}>
        <Table
          dataSource={users?.data || []}
          columns={columns}
          pagination={false}
          rowKey="cuil"
          loading={usersIsLoading}
        />
        <Pagination
          onChange={value => handlePage(value)}
          current={page}
          total={users?.paginate.total}
        />
      </Col>
      <Modal
        title="Datos del usuario"
        open={showNewAssignationModal}
        onClose={handleOnClose}
        onSave={handleOnSave}
        showActionButtons
      >
        <Form
          initialValues={
            selectedUserId
              ? {
                  ...userAux,
                  assignedEvents: userAux.assignedEvents
                    ? userAux.assignedEvents.map((item: AssignedEvents) => item.id)
                    : undefined,
                  roleIds: userAux.roles?.map(x => x.id),
                }
              : clearUser
          }
          form={form}
          layout="vertical"
          name="create_user"
        >
          <InputContainer>
            <RequiredText>(*)Datos Obligatorios</RequiredText>
            <Row gutter={[24, 12]}>
              <Col span={24}>
                <StyledLabel>Cuil{requiredMark()}</StyledLabel>
                <Form.Item
                  name="cuil"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          handleNeighborCuilSearch(undefined)
                          return Promise.resolve()
                        }
                        if (/^(20|23|24|27|30|33|34)\d{8}\d$/.test(value)) {
                          const lastDigit = parseInt(value[value.length - 1], 10)
                          const factors = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]
                          let result = 0
                          factors.forEach((item, index) => {
                            result += item * value[index]
                          })
                          if (result % 11 === 0 && lastDigit === 0) {
                            handleNeighborCuilSearch(value)
                            return Promise.resolve()
                          }
                          if (lastDigit === 11 - (result % 11)) {
                            handleNeighborCuilSearch(value)
                            return Promise.resolve()
                          }
                        }
                        // handleNeighborCuilSearch(undefined)
                        return Promise.reject(new Error('Ingrese un CUIL válido.'))
                      },
                    }),
                    {
                      required: true,
                      message: requiredField,
                    },
                  ]}
                >
                  {editing ? (
                    <StyledInput
                      placeholder="Solo números"
                      disabled={editing}
                      className="numeration"
                      maxLength={11}
                    />
                  ) : (
                    <StyledSearch
                      placeholder="Solo números"
                      disabled={editing}
                      className="numeration"
                      maxLength={11}
                      loading={neighborIsLoading}
                      onSearch={onSearch}
                      onChange={event => {
                        if (event.target.value.length === 11) {
                          handleNeighborCuilSearch(event.target.value)
                          //return Promise.resolve()
                        }
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <StyledLabel>Nombre{requiredMark()}</StyledLabel>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: requiredField,
                    },
                    {
                      type: 'string',
                      message: stringField,
                    },
                  ]}
                  name="name"
                >
                  <StyledInput
                    placeholder="Ingrese texto"
                    disabled={editing}
                    value={inputs.name}
                    onChange={value => handleInputValue('name', value)}
                    maxLength={60}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <StyledLabel>Apellido{requiredMark()}</StyledLabel>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: requiredField,
                    },
                    {
                      type: 'string',
                      message: stringField,
                    },
                  ]}
                  name="lastName"
                >
                  <StyledInput
                    placeholder="Ingrese texto"
                    disabled={editing}
                    value={inputs.lastName}
                    onChange={value => handleInputValue('lastName', value)}
                    maxLength={60}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <StyledLabel>Teléfono{requiredMark()}</StyledLabel>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: requiredField,
                    },
                  ]}
                  name="phoneNumber"
                >
                  <InputNum
                    placeholder="Solo números"
                    value={inputs.phoneNumber}
                    onChange={value => handleInputValue('phoneNumber', value)}
                    stringMode
                    className="numeration"
                    maxLength={20}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <StyledLabel>E-mail{requiredMark()}</StyledLabel>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: requiredField,
                    },
                    {
                      type: 'email',
                      message: emailField,
                    },
                  ]}
                  name="email"
                >
                  <StyledInput
                    placeholder="ejemplo@dominio.email"
                    value={inputs.email}
                    onChange={value => handleInputValue('email', value)}
                    maxLength={120}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Title>Datos de la asignación</Title>
              <Col span={24}>
                <StyledLabel>Perfil{requiredMark()}</StyledLabel>
                <Form.Item
                  name="roleIds"
                  rules={[
                    {
                      required: true,
                      message: requiredField,
                    },
                  ]}
                >
                  <StyledSelect
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    placeholder="Seleccionar"
                    mode="multiple"
                    onChange={value => {
                      const roleIds = value as number[]
                      const auxRoles = roles as Combo[]

                      const auxRolesNames = roleIds.map(
                        roleId => auxRoles.find(auxRole => auxRole.id === roleId)!.value,
                      )

                      if (auxRolesNames.some(i => ['mobile', 'importador'].includes(i))) {
                        setSelectedRole(2)
                      } else {
                        setSelectedRole(1)
                      }
                    }}
                  >
                    {roles?.map((role: Combo) => (
                      <Option key={role.id} value={role.id}>
                        {role.value}
                      </Option>
                    ))}
                  </StyledSelect>
                </Form.Item>
              </Col>
              {(selectedRole === 2 || (editing && selectedRole === 2)) && (
                <Col span={24}>
                  <StyledLabel>Evento{selectedRole === 2 && requiredMark()}</StyledLabel>
                  <Form.Item
                    name="assignedEvents"
                    rules={[
                      {
                        required: true,
                        message: requiredField,
                      },
                    ]}
                  >
                    <StyledSelect
                      getPopupContainer={(triggerNode: HTMLElement) =>
                        triggerNode.parentNode as HTMLElement
                      }
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder={selectedRole === 2 && 'Seleccionar'}
                      mode="multiple"
                      className="closeSelect"
                    >
                      {events?.map((event: Combo) => (
                        <Option key={event.id} value={event.id}>
                          {event.value}
                        </Option>
                      ))}
                    </StyledSelect>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </InputContainer>
        </Form>
      </Modal>
      {userToDelete && (
        <Modal title="Confirme la acción" open={showConfirmModal} onClose={onDeleteSuccess}>
          <ConfirmContainer>
            <div className="textContainer">
              <h3>ATENCIÓN</h3>
              <p>Al aceptar eliminará la asignación del usuario </p>
              <div className="userInfo">
                <p>{userToDelete?.cuil ? StringUtils.formatCUIL(userToDelete?.cuil) : '-'}</p>
                <p>{`${userToDelete?.name} ${userToDelete?.lastName}`}</p>
              </div>
            </div>
            <div className="buttonContainer">
              <SecundaryButton label="Aceptar" onClick={() => deleteUser(userToDelete?.id)} />
              <SecundaryButton label="Cancelar" onClick={() => onDeleteSuccess()} />
            </div>
          </ConfirmContainer>
        </Modal>
      )}
    </Row>
  )
}

export { Users }
