import { useQuery } from 'react-query'

import { NeighborEventsApi } from '../../api'
import { QUERY_KEYS } from '../../constants'
import { NeighborEventsFiltersProps, OrderBy } from '../../types'
import { errorDataMessage } from '../../utils'

const useGetNeighborEvents = (
  page: number,
  params: NeighborEventsFiltersProps,
  maxResult: number | null,
  orderBy: OrderBy | undefined,
) => {
  const { data: neighborEvents, isLoading: neighborEventsIsLoading } = useQuery(
    [QUERY_KEYS.NEIGHBOR_EVENTS, page, params, maxResult, orderBy],
    () => {
      return NeighborEventsApi.getAllNeighborEvents(
        page,
        params,
        maxResult,
        orderBy?.order !== undefined ? orderBy.order : null,
        orderBy?.field !== undefined ? orderBy.field : null,
      )
    },
    {
      onError: () => {
        errorDataMessage()
      },
    },
  )

  return { neighborEvents, neighborEventsIsLoading }
}

export { useGetNeighborEvents }
