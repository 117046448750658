import { api } from './axios'

interface NewActionPayload {
  eventId: number
  actionTypeId: number
  title: string
  description: string
  pointsAmount: number
  benefitId: number
}

interface EditActionPayload {
  id: number
  actionType: number
  description: string
  event: number
  pointsAmount: number
  title: string
  benefit: number
}

interface DeleteActionPayload {
  id: number
}

const apiAction = '/api/action'

const setAction = async ({
  eventId,
  actionTypeId,
  title,
  description,
  pointsAmount,
  benefitId,
}: NewActionPayload) => {
  const response = await api.post(apiAction, {
    eventId: eventId,
    actionTypeId: actionTypeId,
    title: title,
    description: description,
    pointsAmount: pointsAmount,
    benefitId: benefitId,
  })
  return response.data
}

const getAction = async (page: number) => {
  const response = await api.get(`${apiAction}?page=${page}`)
  return response.data
}

const updateAction = async ({ id, description, pointsAmount }: EditActionPayload) => {
  const response = await api.put(`${apiAction}/${id}`, {
    description,
    pointsAmount,
  })
  return response.data
}

const deleteAction = async ({ id }: DeleteActionPayload) => {
  const response = await api.delete(`${apiAction}/${id}`)
  return response.data
}

const getActionTypeCombo = async () => {
  const response = await api.get('/api/action-type/combo')
  return response.data.data
}

export const ActionApi = {
  setAction,
  getAction,
  updateAction,
  deleteAction,
  getActionTypeCombo,
}
