import { removeAuthHeader } from '../api'
import { ROUTE_KEYS } from '../constants'
import { Profile } from '../types'

const clearingLocalSession = () => {
  window.localStorage.clear()
  removeAuthHeader()
}
// Limpia localStorage, cabecera de autenticación y redirecciona al SSO
const loginRedirection = (user: Profile | undefined) => {
  clearingLocalSession()
  const actualLocation = window.location.origin
  window.location.replace(
    process.env.REACT_APP_API_URL +
      ROUTE_KEYS.LOGIN +
      `?type=${user?.rolesNames}&callback=` +
      actualLocation,
  )
}

// Redirecciona al logout
const logout = async () => {
  clearingLocalSession()
  window.location.replace(process.env.REACT_APP_API_URL + ROUTE_KEYS.LOGOUT)
}

export { logout, loginRedirection }
