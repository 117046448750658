import { useQuery } from 'react-query'

import { BenefitsApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetUnusedAvailableBenefitCombo = () => {
  const { data: benefits } = useQuery([QUERY_KEYS.BENEFITS_COMBO], () =>
    BenefitsApi.getUnusedAvailableBenefitCombo(),
  )
  return {
    benefitsCombo: ((benefits ?? []) as { id: number; title: string }[]).map(({ id, title }) => ({
      value: id,
      label: title,
    })),
  }
}

export { useGetUnusedAvailableBenefitCombo }
