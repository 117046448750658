import { useQuery } from 'react-query'

import { CategoryApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useGetCategoryCombo = () => {
  const { data: category } = useQuery([QUERY_KEYS.CATEGORY_COMBO], () =>
    CategoryApi.getCategoryCombo(),
  )

  return {
    categoryCombo: ((category?.data ?? []) as { id: number; value: string }[]).map(
      ({ id, value }) => ({
        label: value,
        value: id,
      }),
    ),
  }
}

export { useGetCategoryCombo }
