import { useEffect, useState } from 'react'
import { Row, Col, Table, Pagination } from 'antd'
import { useQueryClient } from 'react-query'

import { EditIcon, EraserIcon, PlusIcon, SearchIcon, TrashIcon } from '../../assets/icons'
import {
  ActionsContainer,
  FilterButton,
  FilterInput,
  FilterSelect,
  InputContainer,
  Modal,
  PrimaryButton,
  RequiredText,
  SecundaryButton,
  StyledLabel,
} from '../../components'
import {
  useCreateLocation,
  useDeleteLocation,
  useEditLocation,
  useGetLocationCombo,
  useGetLocations,
  useGetEventByLocationId,
} from '../../hooks'
import { Location, LocationsFiltersProps } from '../../types'
import { QUERY_KEYS } from '../../constants'
import { ConfirmModal } from '../../components/Modal/ConfirmModal'

const Locations = () => {
  const [showFilters, setShowFilters] = useState(false)
  const [page, setPage] = useState(1)
  const defaultFilters: LocationsFiltersProps = {
    locationId: undefined,
  }
  const [filters, setFilters] = useState<LocationsFiltersProps>(defaultFilters)
  const [errors, setErrors] = useState(true)
  const queryClient = useQueryClient()

  const [showNewLocationsModal, setShowNewLocationsModal] = useState(false)
  const [showEditLocationsModal, setShowEditLocationsModal] = useState(false)

  const [selectedLocationId, setSelectedLocationId] = useState(0)
  const [selectedLocationName, setSelectedLocationName] = useState('')
  const [paginator, setPaginator] = useState<number | undefined>(1)
  const [showEditActionModal, setShowEditActionModal] = useState(false)
  const [locationId, setLocationId] = useState(0)

  const resetValues = () => {
    setSelectedLocationName('')
    setSelectedLocationId(0)
    setErrors(true)
  }

  const { locations, locationsIsLoading } = useGetLocations(page, filters)
  const { locationCombo } = useGetLocationCombo()
  const { createLocation, isCreateLocationLoading } = useCreateLocation(() => {
    setShowNewLocationsModal(false)
    resetValues()
  })
  const { editLocation, isEditLocationLoading } = useEditLocation(() => {
    setShowEditLocationsModal(false)
    resetValues()
  })
  const { deleteLocation } = useDeleteLocation()
  const { eventCondition, eventConditionIsLoading } = useGetEventByLocationId(locationId)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Locación',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Acciones',
      key: 'action',
      render: ({ name, id }: Location) => (
        <ActionsContainer>
          <EditIcon
            onClick={() => {
              setLocationId(id)
              setSelectedLocationId(id)
              setSelectedLocationName(name)
              setShowEditLocationsModal(true)
            }}
          />
          <TrashIcon onClick={() => trash(id, name)} />
        </ActionsContainer>
      ),
    },
  ]

  const inputContainer = (
    <InputContainer>
      <RequiredText>(*)Datos Obligatorios</RequiredText>
      <FilterInput
        label="Locación"
        name="location"
        required
        value={selectedLocationName}
        onChange={e => {
          setSelectedLocationName(e.target.value)
        }}
      />
    </InputContainer>
  )

  useEffect(() => {
    if (selectedLocationName) setErrors(false)
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.fetchQuery(QUERY_KEYS.LOCATIONS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationName, page, paginator])

  const handleQuery = () => {
    setPage(1)
    queryClient.fetchQuery(QUERY_KEYS.LOCATIONS)
  }

  const handleCleanFilters = () => {
    setPaginator(undefined)
    setFilters(defaultFilters)
  }

  const handleFilterValue = (key: string, value: number | string) => {
    setPaginator(0)
    setPage(1)
    setFilters({
      ...filters,
      [key]: value,
    })
  }

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  const trash = (id: number, name: string) => {
    setShowEditActionModal(true)
    setLocationId(id)
    setSelectedLocationName(name)
  }

  const keyEnter = () => {
    handleQuery()
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h1>Lista de locaciones</h1>
      </Col>
      <Col span={24}>
        <Row justify="end" align="middle" gutter={[12, 0]}>
          <Col>
            <PrimaryButton
              label="Nueva Locación"
              icon={<PlusIcon />}
              onClick={() => setShowNewLocationsModal(true)}
            />
          </Col>
          <Col span={1}>
            <FilterButton onClick={() => setShowFilters(prev => !prev)} />
          </Col>
        </Row>
      </Col>
      {showFilters && (
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <StyledLabel></StyledLabel>
            </Col>
            <Col span={24}>
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <FilterSelect
                    options={locationCombo}
                    value={filters.locationId}
                    name="locationId"
                    label="Locación"
                    onChange={(value: unknown) => handleFilterValue('locationId', value as number)}
                    keyEnter={keyEnter}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[12, 0]}>
                <Col>
                  <SecundaryButton
                    label="Limpiar"
                    icon={<EraserIcon />}
                    onClick={handleCleanFilters}
                  />
                </Col>
                <Col>
                  <PrimaryButton label="Buscar" icon={<SearchIcon />} onClick={handleQuery} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      <Col span={24}>
        <Table
          dataSource={locations?.data || []}
          columns={columns}
          pagination={false}
          rowKey="id"
          loading={locationsIsLoading}
        />
        <Pagination
          onChange={e => handlePage(e)}
          current={page}
          total={locations?.paginate.total}
        />
      </Col>
      <Modal
        title="Nueva locación"
        open={showNewLocationsModal}
        onClose={() => {
          setShowNewLocationsModal(false)
          resetValues()
        }}
        onSave={() => {
          createLocation({ name: selectedLocationName })
        }}
        disabledSave={errors || isCreateLocationLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <Modal
        title="Editar locación"
        open={showEditLocationsModal}
        onClose={() => {
          setShowEditLocationsModal(false)
          resetValues()
        }}
        onSave={() => {
          if (!selectedLocationId) return
          editLocation({ id: selectedLocationId, name: selectedLocationName })
        }}
        disabledSave={errors || isEditLocationLoading}
        showActionButtons
      >
        {inputContainer}
      </Modal>
      <ConfirmModal
        title="Confirme la acción"
        open={!eventConditionIsLoading && showEditActionModal}
        onClose={() => setShowEditActionModal(false)}
        onConfirm={() => {
          deleteLocation({ id: locationId })
          setShowEditActionModal(false)
        }}
        width={'100'}
        showActionButtons
        message={`${
          eventCondition === 'location_found'
            ? `La locación ${selectedLocationName}\n \n\n pertenece a eventos activos.`
            : `Locación ${selectedLocationName}\n`
        }`}
      ></ConfirmModal>
    </Row>
  )
}

export { Locations }
