export { ReactComponent as EditIcon } from './edit.svg'
export { ReactComponent as FilterIcon } from './filter.svg'
export { ReactComponent as PlusIcon } from './plus.svg'
export { ReactComponent as TrashIcon } from './trash.svg'
export { ReactComponent as SearchIcon } from './search.svg'
export { ReactComponent as ExportIcon } from './export.svg'
export { ReactComponent as ImportIcon } from './import.svg'
export { ReactComponent as UsersIcon } from './users.svg'
export { ReactComponent as VisitsIcon } from './visits.svg'
export { ReactComponent as LocationsIcon } from './locations.svg'
export { ReactComponent as ActivitiesIcon } from './activities.svg'
export { ReactComponent as FaBarIcon } from './fa-bar.svg'
export { ReactComponent as GridHorizontalIcon } from './grid-horizontal.svg'
export { ReactComponent as CaretDownIcon } from './caret-down.svg'
export { ReactComponent as FacebookIcon } from './facebook-f.svg'
export { ReactComponent as InstagramIcon } from './instagram.svg'
export { ReactComponent as LinkedinIcon } from './linkedin-in.svg'
export { ReactComponent as TwitterIcon } from './twitter.svg'
export { ReactComponent as YoutubeIcon } from './youtube.svg'
export { ReactComponent as EraserIcon } from './eraser.svg'
export { ReactComponent as HeadsetIcon } from './headset.svg'
export { ReactComponent as NewspaperIcon } from './newspaper.svg'
export { ReactComponent as Mi3FLogoIcon } from './mi3f-logo.svg'
export { ReactComponent as CrossIcon } from './xmark.svg'
export { ReactComponent as EventsIcon } from './events.svg'
export { ReactComponent as SettingsIcon } from './settings.svg'
export { ReactComponent as FileSignatureIcon } from './file-signature-solid.svg'
export { ReactComponent as HouseIcon } from './house.svg'
export { ReactComponent as GiftIcon } from './gift.svg'
export { ReactComponent as UserSolidIcon } from './user-solid.svg'
