import { useGenericMutation } from '../useGenericMutation'
import { ActivityApi } from '../../api'
import { QUERY_KEYS } from '../../constants'

const useEditActivity = (onSuccess?: () => void) => {
  const {
    mutate: editActivity,
    isSuccess: isEditActivitySuccess,
    isLoading: isEditActivityLoading,
  } = useGenericMutation({
    queryKey: QUERY_KEYS.ACTIVITIES,
    mutationFn: ActivityApi.updateActivity,
    errorMessage: 'No fue posible modificar la actividad',
    successMessage: 'Actividad modificada exitosamente',
    onSuccess,
  })

  return {
    editActivity,
    isEditActivitySuccess,
    isEditActivityLoading,
  }
}

export { useEditActivity }
