import { Col, Pagination, Row, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import moment from 'moment'
import { DownloadOutlined } from '@ant-design/icons'

import ImportModal from '../../components/Modal/ImportModal'
import { QUERY_KEYS } from '../../constants'
import { useGetIdReportBenefits } from '../../hooks'
import { BenefitsApi } from '../../api'
import { dateFormat } from '../../utils'

const ReportBenefits = () => {
  const [page, setPage] = useState(1)
  const [maxResult, setMaxResult] = useState<number | null>(null)

  const queryClient = useQueryClient()
  const { report } = useGetIdReportBenefits()
  const [importModalIsOpen, setImportModaIsOpen] = useState(false)
  const [paginator, setPaginator] = useState<number | undefined>(1)

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <div style={{ textAlign: 'center' }}>Descargar Reporte</div>,
      key: 'download',
      render: (record: { id: number }) => (
        <span
          onClick={() => handleExport(record.id)}
          style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
        >
          <DownloadOutlined />
        </span>
      ),
    },
  ]

  const handleExport = async (id: number) => {
    BenefitsApi.getReportBenefits(id).then(res => {
      const link = document.createElement('a')
      const url = window.URL.createObjectURL(res)
      link.href = url
      link.setAttribute(
        'download',
        `BeneficiosConsumidos_${moment().format(`${dateFormat}[_]HH[-]mm[-]ss`)}`,
      )
      document.body.appendChild(link)

      link.click()
      link.parentNode?.removeChild(link)
    })
  }

  const handlePage = (pagina: number) => {
    setPaginator(1)
    setPage(pagina)
  }

  const handlePageSize = (page: number, pageSize: number) => {
    setMaxResult(pageSize)
    setPage(1)
    queryClient.resetQueries(QUERY_KEYS.REPORT_BENEFITS_COMBO)
  }

  useEffect(() => {
    if ((page && paginator === 1) || (page !== 1 && paginator === 0)) {
      queryClient.resetQueries(QUERY_KEYS.REPORT_BENEFITS_COMBO)
      // queryClient.invalidateQueries(QUERY_KEYS.VISITS)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, paginator])

  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <h1>Reporte Comercios Beneficios</h1>
        </Col>
        <Col span={24}>
          <Table
            //sortDirections={['descend']}
            dataSource={report || []}
            columns={columns}
            pagination={false}
            rowKey="id"
          />
          <Pagination
            onChange={e => {
              handlePage(e)
            }}
            current={page}
            total={report?.paginate?.total}
            pageSize={maxResult || 10}
            onShowSizeChange={(page: number, pageSize: number) => handlePageSize(page, pageSize)}
          />
        </Col>
      </Row>
      <ImportModal
        title="Importar archivo de asignación a eventos"
        open={importModalIsOpen}
        onClose={() => setImportModaIsOpen(false)}
        entity="report-benefits"
        accept=".xls, .xlsx"
      />
    </>
  )
}

export { ReportBenefits }
